import './PangBox.css';
import '../../App.css';
import { getProductImageUrl, GetProfileImgUrl } from '../../URL/AppUrl';
import { IsValidS, checkBatchimEnding } from '../../Util/Util';

const getUserProfileImg = (item) =>
{
  if (!IsValidS(item.uc) || item.uc === "n")
    return GetProfileImgUrl("defprfsm.png");

  return GetProfileImgUrl(item.uc);
}

const PangBoxWinner = (props) =>
{
  return (
    <div className='wd88p coltc mgtop20'>
      <div className='wd100p rowsc'>
        <label className=''>당첨을 축하드립니다!</label>

        <button className='pbivbtn2 font14 colorb3 wt400 btncur' onClick={props.handleMoreWinner}>더보기</button>
      </div>

      <div className='wd100p rowss mgtop10'>
        <div className='pbwinbox'>
          <img src={getProductImageUrl(props.info.ic)} alt='상품' className='pbwinboximg'/>
        </div>

        <div className='pbwindescbg coltl'>
          <div className='rowcc'>
            <div className='pbiconbox rowcc mgrt5'>
              <img src={getUserProfileImg(props.info)} alt='프로필' className='pbiconboximg'/>
            </div>

            <label className='font14 wt400 color31'>{props.info.un}</label>
          </div>

          <label className='font14 wt300 color31 mgtop5'>{`${props.info.itn}${checkBatchimEnding(props.info.itn) ? "을" : "를"} 획득하였습니다.`}</label>
        </div>
      </div>

      <div className='pbcmtbox rowlc mgtop10'>
        <label className='font14 wt400 colorff mgrt5'>소감 한마디</label>
        <label className='font14 wt300 color31'>{props.info.cmt}</label>
      </div>
    </div>
  );
}

export default PangBoxWinner;