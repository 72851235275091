import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import TimeUtil from '../Util/TimeUtil';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';
import { FromBase64, GetSerialDateToDate, IsValidS, IsValidV, ToBase64 } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

class InvenStore {
  boxList = null; //이용자 보유박스 리스트
  pdList = null; //이용자 보유상품 리스트
  hsList = null; //상품 히스토리, 대박당첨 히스토리, 목록출력용
  hsListForHome = null; //상품 히스토리, 대박당첨 히스토리

  //비옵저버 변수
  boxlistReady = false;
  pdListReady = false;
  historyReady = false;
  historyTime = null;
  refBoxIdx = 0;
  refPdIdx = -1;
  refHisIdx = 0;

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      boxList : observable,
      pdList : observable,
      hsList : observable,
      hsListForHome : observable,
      
      clearAll: action,

      parseHistoryResult: action,
      parseHistoryForHomeResult: action,
      parseLastHistoryCommentResult: action,
      parseCommentResult: action,
      parsePdListResult: action,
      parsePdInfoResult: action,
      parseBoxListResult: action,
      parseBoxInfoResult: action,
      parseBuyBoxResult: action,
      parseOpenBoxResult: action,
      parseReqPdBrkResult: action,
    });
  }

  catchHistoryTime = () =>
  {
    if (!this.historyTime)
      this.historyTime = new TimeUtil();

    this.historyTime.catch();
  }

  getPassedHistoryTime = () =>
  {
    if (!this.historyTime)
      this.catchHistoryTime();

    return this.historyTime.getPassedTime2();
  }

  clearAll = () =>
  {
    this.boxList = null; //이용자 보유박스 리스트
    this.pdList = null; //이용자 보유상품 리스트
    this.hsList = null; //상품 히스토리, 대박당첨 히스토리, 목록출력용
    this.hsListForHome = null; //상품 히스토리, 대박당첨 히스토리

    //비옵저버 변수
    this.boxlistReady = false;
    this.pdListReady = false;
    this.historyReady = false;
    this.historyTime = null;
    this.refBoxIdx = 0;
    this.refPdIdx = -1;
    this.refHisIdx = 0;
  }

  //보유박스 목록에 박스 추가하기
  addBox = (boxInfo) =>
  {
    if (!IsValidS(this.boxList))
    {
      this.boxList = [boxInfo];
      this.refBoxIdx = boxInfo.idx;
    }
    else
    {
      this.boxList = [boxInfo, ...this.boxList];
    }
  }

  clearBoxList = () =>
  {
    this.boxList = null;
    this.refBoxIdx = 0;
    this.boxlistReady = false; //서버에서 보유팡팡박스 목록을 한번이라도 조회했나?
  }

  clearPdList = () =>
  {
    this.pdList = null;
    this.refPdIdx = -1;
    this.pdListReady = false; //서버에서 보유항 상품 목록을 한번이라도 조회했나?
  }

  clearHistory = () =>
  {
    this.hsList = null;
    this.refHisIdx = 0;
  }

  //주문번호로 사용자 보유상품 정보 찾기
  findUserPdFromOidx = (oidx) =>
  {
    if (!IsValidS(this.pdList))
      return null;

    for (let i = 0;i < this.pdList.length; ++i)
    {
      if (!this.pdList[i].rct)
        continue;
      
      if (this.pdList[i].st === 1 && this.pdList[i].rct.idx === oidx)
        return this.pdList[i];
    }

    return null;
  }

  //보유상품 찾기
  findUserPdByIdx = (idx) =>
  {
    if (!IsValidS(this.pdList))
      return null;

    for (let i = 0;i < this.pdList.length; ++i)
    {
      if (this.pdList[i].idx === idx)
        return this.pdList[i];
    }

    return null;
  }

  //보유박스 찾기
  findUserBoxByIdx = (idx) =>
  {
    if (!IsValidS(this.boxList))
      return null;

    for (let i = 0;i < this.boxList.length; ++i)
    {
      if (this.boxList[i].idx === idx)
        return this.boxList[i];
    }

    return null;
  }
  //당첨히스토리 목록읽기-------------------------------------------------------------------------------------------------------------

  loadHistory = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("pos", this.refHisIdx.toString());

    if (LOG_ENABLE)
      console.log(`loadHistory : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/hist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseHistoryResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseHistoryResult(null, callback);
    });
  }

  parseHistoryResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseHistoryResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeHistory(item);

          if (this.refHisIdx === 0 || this.refHisIdx > item.idx)
            this.refHisIdx = item.idx;
        });

        if (IsValidS(this.hsList))
        {
          this.hsList = [...this.hsList, ...result.list];
        }
        else
          this.hsList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, readCount);
  }
  //당첨히스토리 목록읽기 홈페이지용-------------------------------------------------------------------------------------------------------------
  loadHistoryForHome = (callback) => {

    this.historyReady = false;

    const params = new URLSearchParams();
    params.append("rt", "1");

    if (LOG_ENABLE)
      console.log(`loadHistoryForHome : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/hist"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseHistoryForHomeResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseHistoryForHomeResult(null, callback);
    });
  }

  parseHistoryForHomeResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseHistoryForHomeResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.historyReady = true; 
      this.catchHistoryTime();

      if (IsValidS(result.list))
      {
        result.list.forEach(item => {
          this.decodeHistory(item);
        });

        this.hsListForHome = result.list;
      }

    }

    if (callback)
      callback(result.ret);
  }

  decodeHistory = (item) =>
  {
    if (IsValidS(item.cmt))
      item.cmt = FromBase64(item.cmt);

    if (IsValidS(item.un))
      item.un = FromBase64(item.un);

    if (IsValidS(item.itn))
      item.itn = FromBase64(item.itn);
  }
  //코멘트가 작성된 마지막 대박 당첨 기록조회-------------------------------------------------------------------------------------------------------------

  loadLastHistoryComment = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "2");

    if (LOG_ENABLE)
      console.log(`loadLastHistoryComment : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/hist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseLastHistoryCommentResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseLastHistoryCommentResult(null, callback);
    });
  }

  parseLastHistoryCommentResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseLastHistoryCommentResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidV(result.info))
      {
        this.decodeHistory(result.info);
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result.info);
  }
  //자랑하기-------------------------------------------------------------------------------------------------------------
  //상품 자랑하기
  //pidx : 보유상품 고유번호
  //cmt : 남기는 말
  historyComment = (pidx, cmt, callback) => {

    const params = new URLSearchParams();
    params.append("pidx", pidx.toString());
    params.append("cmt", ToBase64(cmt));

    axios({
      method:"POST",
      url: GetApiUrl("pang/cmt"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseCommentResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseCommentResult(null, callback);
    });
  }

  parseCommentResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseCommentResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      const cmt = FromBase64(result.cmt);

      if (IsValidS(this.hsList))
      {
        let found = false;

        for (let i = 0;i < this.hsList.length; ++i)
        {
          if (this.hsList[i].idx === result.cidx)
          {
            found = true;
            this.hsList[i].cmt = cmt;

            if (LOG_ENABLE)
              console.log(`히스토리찾음`);

            break;
          }
        }

        if (found)
          this.hsList = [...this.hsList];
        else if (LOG_ENABLE)
          console.log(`히스토리 못찾음`);
      }

      //보유상품을 찾아서 코멘트 남겼다고 표시하기
      if (IsValidS(this.pdList))
      {
        let found = false;

        for (let i = 0;i < this.pdList.length; ++i)
        {
          if (this.pdList[i].idx === result.pidx)
          {
            found = true;
            this.pdList[i].ctidx = result.cidx;

            if (LOG_ENABLE)
              console.log(`보유상품 찾음`);
            break;
          }
        }

        if (found)
          this.pdList = [...this.pdList];
        else if (LOG_ENABLE)
          console.log(`보유상품 못찾음`);
      }
    }
    
    if (callback)
      callback(result.ret, null, null);
  }
  //보유상품 목록읽기-------------------------------------------------------------------------------------------------------------

  loadPdList = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("ridx", this.refPdIdx.toString());

    if (LOG_ENABLE)
      console.log(`loadPdList : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/pd/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parsePdListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parsePdListResult(null, callback);
    });
  }

  parsePdListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parsePdListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      this.pdListReady = true;

      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeUserPd(item);

          if (this.refPdIdx === -1 || this.refPdIdx > item.idx)
            this.refPdIdx = item.idx;
        });

        if (IsValidS(this.pdList))
        {
          this.pdList = [...this.pdList, ...result.list];
        }
        else
          this.pdList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeUserPd = (item) =>
  {
    if (IsValidS(item.nm))
      item.nm = FromBase64(item.nm);

    if (IsValidV(item.rct))
    {
      if (IsValidS(item.rct.mm))
        item.rct.mm = FromBase64(item.rct.mm);

      //배송정보가 있다면
      if (IsValidS(item.rct.di))
      {
        if (item.rct.di === "n")
          item.rct.di = null;
        else
          item.rct.di = JSON.parse(FromBase64(item.rct.di));
      }
      else
      {
        if (LOG_ENABLE)
          console.log(`invalid deli info : ${item.rct.idx}`);

        item.rct.di = null;
      }

      //무통장입금은행정보파싱
      if (IsValidS(item.rct.bk))
      {
        if (item.rct.bk === "n")
          item.rct.bk = null;
        else
          item.rct.bk = JSON.parse(FromBase64(item.rct.bk));
      }
      else
        item.rct.bk = null;
    }

    //상품 남은 유효기간 계산하기
    item.ldt = this.calcBoxLimitDate(item.ld);
  }

  //이용자의 특정 보유상품 한개의 정보를 다시 읽기-------------------------------------------------------------------------------------------------------------

  //idx : 보유상품 고유번호
  loadPdInfo = (idx, callback) => {

    const params = new URLSearchParams();
    params.append("rt", "1");
    params.append("ridx", idx.toString());

    if (LOG_ENABLE)
      console.log(`loadPdInfo : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/pd/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parsePdInfoResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parsePdInfoResult(null, callback);
    });
  }

  parsePdInfoResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parsePdInfoResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidV(result.info))
      {
        this.decodeUserPd(result.info);

        const oldInfo = this.findUserPdByIdx(result.info.idx);

        if (oldInfo)
        {
          let newList = [];

          this.pdList.forEach(item => {
            if (item.idx !== result.info.idx)
              newList.push(item);
            else
              newList.push(result.info);
          });

          this.pdList = newList;
        }
        else
        {
          if (LOG_ENABLE)
            console.log(`prev pd not found.`);
        }
      }
      
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result.info);
  }
  //보유 팡팡박스 목록읽기-------------------------------------------------------------------------------------------------------------

  loadBoxList = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "1");
    params.append("pos", this.refBoxIdx.toString());

    if (LOG_ENABLE)
      console.log(`loadBoxList : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/bxlist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseBoxListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseBoxListResult(null, callback);
    });
  }

  parseBoxListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseBoxListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      this.boxlistReady = true;

      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeBox(item);

          if (this.refBoxIdx === 0 || this.refBoxIdx > item.idx)
            this.refBoxIdx = item.idx;
        });

        if (IsValidS(this.boxList))
        {
          this.boxList = [...this.boxList, ...result.list];
        }
        else
          this.boxList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeBox = (item) =>
  {
    //무통장 입금은행정보 파싱
    if (IsValidS(item.bk) && item.bk !== "n")
      item.bk = FromBase64(item.bk);

    //팡팡박스 남은 유효기간 계산하기
    item.ldt = this.calcBoxLimitDate(item.ld);
  }

  calcBoxLimitDate = (limitDate) =>
  {
    const date1 = GetSerialDateToDate(limitDate);
    const date2 = new Date();

    const diffDate = date1.getTime() - date2.getTime();

    return Math.floor(diffDate / (1000 * 60 * 60 * 24));
  }
  //특정 팡팡박스 정보읽기-------------------------------------------------------------------------------------------------------------

  loadBoxInfo = (bidx, callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("bidx", bidx.toString());

    if (LOG_ENABLE)
      console.log(`loadBoxInfo : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/bxlist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseBoxInfoResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseBoxInfoResult(null, callback);
    });
  }

  parseBoxInfoResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, null);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseBoxInfoResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidS(result.info))
      {
        this.decodeBox(result.info);
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result.info);
  }

  //팡팡박스 구매하기-------------------------------------------------------------------------------------------------------------
  /*
  rt : 일반(0), 골드(1), 신버전일반(2)
  cnt : 구매개수, 골드박스 구매시
  pt : 결제수단, 골드박스 구매시, 무통장(0), 카드(1)
  */
  buyPangBox = (rt, cnt, pt, callback) => {

    const params = new URLSearchParams();
    params.append("rt", rt.toString());

    if (IsValidV(cnt))
      params.append("cnt", cnt.toString());

    if (IsValidV(pt))
      params.append("pt", pt.toString());

    if (LOG_ENABLE)
      console.log(`buyPangBox : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/bxbuy/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseBuyBoxResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseBuyBoxResult(null, callback);
    });
  }

  parseBuyBoxResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, null);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseBuyBoxResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result);
  }

  //팡팡박스 오픈하기-------------------------------------------------------------------------------------------------------------

  //bidx : 보유박스 고유번호
  openPangBox = (bidx, callback) => {

    const params = new URLSearchParams();
    params.append("bidx", bidx.toString());

    if (LOG_ENABLE)
      console.log(`openPangBox : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/bxopen/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseOpenBoxResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseOpenBoxResult(null, callback);
    });
  }

  parseOpenBoxResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, null);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseOpenBoxResult : ${JSON.stringify(result)}`);

    if (result.ret === 0) //박스 오픈 성공시
    {
      const boxInfo = this.findUserBoxByIdx(result.bidx);

      if (boxInfo)
      {
        if (result.cnt < 1) //남은개수가 없으면, 해당 박스를 제거한다.
        {
          this.boxList = this.boxList.filter(item => item.idx !== result.bidx);

          if (LOG_ENABLE)
            console.log(`remove pang box : ${result.bidx}`);
        }
        else //남은 개수가 있으면, 개수만 다시 설정한다.
        {
          boxInfo.cnt = result.cnt;

          this.boxList = [...this.boxList];

          if (LOG_ENABLE)
            console.log(`update pang box count : ${result.bidx}/${result.cnt}`);
        }
      }

      if (IsValidS(result.wlist))
      {
        result.wlist.forEach(item => this.decodeBoxGetItem(item));
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result);
  }

  decodeBoxGetItem = (item) =>
  {
    if (IsValidS(item.nm))
      item.nm = FromBase64(item.nm);
  }
  //팡팡박스 오픈 체험하기-------------------------------------------------------------------------------------------------------------

  //팡팡박스 오픈 체험하기
  openPangBoxTest = (callback) => {

    axios({
      method:"POST",
      url: GetApiUrl("pang/bxopt/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      this.parseOpenBoxTestResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseOpenBoxTestResult(null, callback);
    });
  }

  parseOpenBoxTestResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, null);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseOpenBoxTestResult : ${JSON.stringify(result)}`);

    if (result.ret === 0) //박스 오픈 성공시
    {
      if (IsValidS(result.wlist))
      {
        result.wlist.forEach(item => this.decodeBoxGetItem(item));
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result);
  }
  //보유상품 팡으로 환급 신청-------------------------------------------------------------------------------------------------------------
  /*
  iidx : 보유상품고유번호
  */
  requestPdBreak = (iidx, callback) => {

    const params = new URLSearchParams();
    params.append("iidx", iidx.toString());

    if (LOG_ENABLE)
      console.log(`requestPdBreak : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/bkpd/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseReqPdBrkResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseReqPdBrkResult(null, callback);
    });
  }

  parseReqPdBrkResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, null);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseReqPdBrkResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      const info = this.findUserPdByIdx(result.idx); //해당 보유상품을 찾음

      if (info)
      {
        if (LOG_ENABLE)
          console.log(`parseReqPdBrkResult : pd info found !!!!!!!!!!`);

        this.pdList = this.pdList.filter(item => item.idx !== result.idx);
      }
      else if (LOG_ENABLE)
        console.log(`parseReqPdBrkResult : pd info not found ---------`);

      //이용자의 보유팡을 환급된 금액만큼 증가 시킴
      LoginStoreInstance.addUserPang(result.rp);
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
}

const InvenStoreInstance = new InvenStore();
const InvenStoreContext = React.createContext(InvenStoreInstance);

const UseInvenStore = () => React.useContext(InvenStoreContext);

export {UseInvenStore, InvenStoreInstance};
