import React from 'react';
import './Inventory.css';
import '../../App.css';
import { observer } from 'mobx-react';
import { InvenStoreInstance } from '../../Stores/InvenStore';
import { IsValidS } from '../../Util/Util';
import InvenBoxItem from './InvenBoxItem';

//인벤토리 박스보관함
class InvenBox extends React.Component
{
  state = {
    loading: false,

    noItem: false,
  };
  
  setLoading = (v) =>
  {
    if (this.props.setParentLoading)
      this.props.setParentLoading(v);

    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  componentDidMount() {
    if (!InvenStoreInstance.boxlistReady)
    {
      this.loadBoxList();
    }
  }

  componentWillUnmount = () =>
  {

  }

  loadBoxList = () =>
  {
    this.setLoading(true);
    InvenStoreInstance.loadBoxList(this.onLoadBox);
  }

  onLoadBox = (resultCode, readCount) =>
  {
    this.setLoading(false);
    
    if (resultCode !== 0)
    {
      this.props.setShowPopup(1, "보유박스 목록 조회 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.", "확인", '', null);
      return;
    }

    if (readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleMore = () =>
  {
    if (this.state.loading)
      return;

    this.loadBoxList();
  }
  
  render()
  {
    let boxList = null;

    if (IsValidS(InvenStoreInstance.boxList))
    {
      boxList = InvenStoreInstance.boxList.map(item => <InvenBoxItem key={item.idx} item={item} handleOpenBox={this.props.handleOpenBox}/>)
    }

    return (
      <div className='ivlist coltc mgbt100'>
        <div className='wd100p rowlc mgbt20'>
          <label className='font12 wt300 colorb3'>※ 박스 유효기간은 구매일로부터 5년 입니다.</label>
        </div>
        {boxList}

        {
          !this.state.noItem ? (
            <div className='ivmorebtn rowcc btncur' onClick={this.handleMore}>
              <label className='font14 color31 wt400 btncur'>더보기</label>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default observer(InvenBox);

