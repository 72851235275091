import './MainPage.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import { GetProfileImgUrl } from '../../URL/AppUrl';

const getUserProfileImg = (item) =>
{
  if (!IsValidS(item.uc) || item.uc === "n")
    return GetProfileImgUrl("defprfsm.png");

  return GetProfileImgUrl(item.uc);
}

const Winner2 = (props) =>
{
  return (
    <div className='fullheight rowcc mgrt50 textnowrap'>
      <img src={getUserProfileImg(props.item)} alt="프로필" className='winpfimg mgrt5'/>
      <label className='font14 wt400 color31'>{props.item.un}</label>
      <label className='font14 wt300 color31'>{`님이 ${props.item.itn}에 당첨 되셨습니다.`}</label>
    </div>
  );
}

export default Winner2;