import { observer } from "mobx-react";
import { GetImgUrl } from "../../URL/AppUrl";
import { UseLoginStore } from "../../Stores/LoginStore";

import './TopBar.css';
import '../../App.css';


const TopBar = observer((props) =>
{
  const loginStore = UseLoginStore();

  return (
    <div className="top_cont flexcolcc">
      <div className="top_bar_mb flexrowsc">
        
        <img src={GetImgUrl("logo2.png")} alt="로고" className='toplogo btncur' onClick={props.handleHome}/>

        <div className="flexrowcc">
          <img src={GetImgUrl("alarm.png")} alt="로고" className='alarm btncur menubtn' onClick={props.handleAlarm}/>
        </div>
      </div>
      {
        props.withLine ? (<div className="topbarline"></div>) : null
      }
    </div>
  );
});

export default TopBar;