import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import {APP_MODE, GetApiUrl, GetApiUrlBase, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { delCookie, getCookie, setCookie } from '../Util/CookieManager';
import { ConvertSqlDateToDate, FromBase64, IsValidS, IsValidV, ToBase64, replaceLine } from '../Util/Util';
import CryptoJS from 'crypto-js';
import { clearAllData } from './StoreManager';

//로그인 및 계정
class LoginStore {
  //observable변수
  init = false; //초기화 되었나?
  userId = ""; //사용자 계정
  userPw = "";
  loggedIn = false; //서버에로그인되었나?
  profile = null; //사용자의 프로필 정보

  //비 observable
  login = false; //로그인시도중인가?
  sid = null; //세션id
  token = null; //토큰

  userIdx = -1; //사용자 고유번호
  userType = -1; //로그인 유저의 타입
  errorCode = 0; //로그인에러코드
  sessionRefreshing = false;
  sessionError = false; //세션 오류가 생기는경우 true로 설정됨
  easyLoginType = 0; //카카오(1), 네이버(2), 이메일자동로그인(3), 애플로로그인(4)

  recmLimitDateInfoReady = false; //추천인 입력기간 제한 시간 정보 조회완료했나?
  recmInputEnabled = false; //추천인 코드 입력이 가능한 계정인가?
  recmInpLimitDate = null; //추천인 입력 제한 시간 정보, date

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      init: observable,
      userId: observable,
      userPw : observable,
      loggedIn: observable,
      profile: observable,

      setUserId: action,
      setUserPw: action,
      setLoggedIn: action,

      addUserPoint: action,
      subUserPoint: action,
      addUserPang: action,
      subUserPang: action,
      setUserPoint: action,

      tryLogin: action,
      logOut: action,
      setLoginResult: action,

      parseProfile: action,

      processLogOut: action,

      parseSessionRefreshResult: action,
      parseNicknameResult: action,
      parseEventResult: action,
      
      getUserId: computed,
      getUserPw: computed,
      getUserName: computed,
      getPhone: computed,
      getEmail: computed,
      getUserCash: computed,
      getUserPang: computed,
      isProfileReady: computed,
      getInviteCode: computed,
      getProfileImage: computed,
      getGender: computed,
      getAgeRange: computed,
    });
  }

  setUserId = (id) => {
    this.userId = id;
  };

  setUserPw = (pw) =>
  {
    this.userPw = pw;
  }

  setLoggedIn = (isLoggedIn) => {
    this.loggedIn = isLoggedIn;
  };

  logOut = () => {
    
    //observable변수
    this.loggedIn = false; //서버에로그인되었나?
    this.login = false; //로그인시도중인가?
    this.profile = null; //사용자의 프로필 정보
    this.sessionError = false;

    //비 observable
    this.errorCode = 0; //로그인에러코드
    this.token = null;
    this.sid = null;

    this.removeToken();
  }

  validateProfile(profileData) {
    
    if (profileData == null)
      return;

    if (IsValidS(profileData.nm))
      profileData.nm = FromBase64(profileData.nm);
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------
  //사용자 계정 얻기
  get getUserId() {
    return this.userId;
  }

  //사용자비번
  get getUserPw() {
    return this.userPw;
  }

  get getUserName()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.nm;
  }

  get getPhone()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.ph;
  }

  get getEmail()
  {
    if (!IsValidV(this.profile))
      return "";

    return this.profile.em;
  }

  //보유머니
  get getUserCash()
  {
    if (!IsValidV(this.profile))
      return 0;

    return this.profile.pt1;
  }

  //보유팡
  get getUserPang()
  {
    if (!IsValidV(this.profile))
      return 0;

    return this.profile.pt2;
  }

  get isProfileReady()
  {
    return IsValidV(this.profile);
  }

  //나의 초대코드
  get getInviteCode()
  {
    if (!IsValidV(this.profile))
      return '-';

    return this.profile.mru;
  }

  //프로필 이미지 얻기
  get getProfileImage()
  {
    if (!IsValidV(this.profile))
      return '';

    return this.profile.pi;
  }

  //성별관련 정보 얻기
  get getGender()
  {
    if (!IsValidV(this.profile))
      return 0;

    return this.profile.gen;
  }

  //나이대얻기, 10, 20, 30, 40, 50, 60
  get getAgeRange()
  {
    if (!IsValidV(this.profile))
      return 0;

    return this.profile.age;
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  addUserPoint = (p) =>
  {
    if (!IsValidV(this.profile))
      return;

    this.profile = {
      ...this.profile,
      pt1: this.profile.pt1 + p,
    };
  }

  subUserPoint = (p) =>
  {
    if (!IsValidV(this.profile))
      return;

    this.profile = {
      ...this.profile,
      pt1: this.profile.pt1 - p,
    };
  }

  addUserPang = (p) =>
  {
    if (!IsValidV(this.profile))
      return;

    this.profile = {
      ...this.profile,
      pt2: this.profile.pt2 + p,
    };
  }

  subUserPang = (p) =>
  {
    if (!IsValidV(this.profile))
      return;

    this.profile = {
      ...this.profile,
      pt2: this.profile.pt2 - p,
    };
  }

  //이용자의 머니와 팡 설정
  setUserPoint = (cash, pang) =>
  {
    if (!IsValidV(this.profile))
      return;

    this.profile = {
      ...this.profile,
      pt1: cash,
      pt2: pang
    };
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  //로그인 시도하기
  tryLogin = (easyLogin, callback) => {

    this.login = true;
    this.errorCode = 0;
    this.easyLoginType = easyLogin;

    if (LOG_ENABLE)
      console.log("try login : ");

    this.validateAccount();

    const params = new URLSearchParams();
   
    if (IsValidS(this.userId))
      params.append("id", this.userId);

    if (IsValidS(this.userPw))
      params.append("pw", ToBase64(this.userPw));
    
    params.append("rt", "0");
    params.append("el", easyLogin.toString());

    axios({
      method:"POST",
      url: GetApiUrl("user/login/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.setLoginResult(res.data, callback);
    }).catch(error=>{
        if (LOG_ENABLE)
          console.log(error);
        this.setLoginResult(null, callback);
    });
  }

  //서버로 부터 수신된 로그인 결과를 바탕으로 로그인 데이터 설정
  setLoginResult = (result, callback) => 
  {
    this.login = false;

    if (LOG_ENABLE)
      console.log(`result ${result ? JSON.stringify(result) : 'failed'}`);

    if (!result || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log(`request failed.`);

      this.errorCode = -1;

      if (callback)
      {
        callback(this.errorCode);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    this.errorCode = result.ret;

    switch(result.ret)
    {
      case 0:
        {
          if (LOG_ENABLE)
            console.log("login succeeded");

          this.loggedIn = true;

          this.validateProfile(result.pf);

          this.profile = result.pf; //프로필 정보를 저장한다.
          
          if (IsValidV(this.profile) && IsValidV(this.profile.idx))
          {
            this.userIdx = this.profile.idx;
            this.userType = this.profile.ut;

            if (LOG_ENABLE)
              console.log("user idx : " + this.userIdx);
          }

          this.sid = result.uidx;
          this.token = result.tk;

          if (LOG_ENABLE)
              console.log(`sid : ${this.sid} user token : ${this.token}`);

          this.saveToken();

          if (callback)
          {
            callback(0);
          }

          return;
        }
      default:
        if (callback)
        {
          callback(this.errorCode);
        }
        break;
    }
  }
  //---------------------------------------------------------------------------------------------------
  //세션 리프레시
  refreshSession = () => {
    //이미 주문이 진행중인경우
    if (this.sessionRefreshing || !this.loggedIn || !IsValidS(this.token) || !IsValidV(this.sid))
      return;

    this.sessionRefreshing = true;

    const params = new URLSearchParams();
    params.append("rt", "0"); //요청타입

    axios({
      method:"POST",
      url: GetApiUrl("user/refs"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("session refresh : " + JSON.stringify(res.data));

      this.parseSessionRefreshResult(res.data);
    }).catch(error=>{
      console.log(error);
      
      this.parseSessionRefreshResult(null);
    });
  }

  parseSessionRefreshResult = (result) =>
  {
    this.sessionRefreshing = false;

    if (!result)
      return;

    if (result.ret !== 0)
    {
      this.profile = null;
      this.loggedIn = false;
      this.sessionError = true;
    }
  }
  //---------------------------------------------------------------------------------------------------

  //프로필 정보 다시 읽기
  loadProfile = (callback) => {

    //토큰 정보가 없으면 로컬에서 읽기
    if (!IsValidS(this.token))
    {
      this.loadToken();

      if (!IsValidS(this.token) || !IsValidV(this.sid))
      {
        if (callback)
        {
          callback(-2);
          return;
        }
      }
    }

    axios({
      method:"POST",
      url: GetApiUrl("user/profile"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      if (LOG_ENABLE)
        console.log("loadProfile : " + JSON.stringify(res.data));

      this.parseProfile(res.data, callback);

    }).catch(error=>{
      console.log(error);
      
      this.parseProfile(null, callback);
    });
  }

  parseProfile = (result, callback) =>
  {

    if (LOG_ENABLE)
      console.log(`parseProfile : ${JSON.stringify(result)}`);

    if (!result)
    {
      if (callback)
        callback(-2);

      return;
    }
    else if (result.ret !== 0)
    {
      if (result.ret === 100)
      {
        this.sessionError = true;
      }

      if (callback)
        callback(result.ret);

      return;
    }

    this.loggedIn = true;
    this.profile = result.pf;

    if (IsValidV(this.profile))
    {
      this.validateProfile(this.profile);
      this.userIdx = this.profile.idx;
      this.userType = this.profile.ut;
    }
    else
    {
      this.profile = null;
    }

    if (callback)
      callback(0);
  }
  //----------------------------------------------------------------------------------------------------------------------------------------
  /*회원가입시도
  phone : 전화, 선택
  email : 이메일 선택
  nickname: 별명, 선택
  gender : 성별, 필수
  age : 나이대, 필수
  recommenderCode: 추천인코드, 선택
  */
  registerUser = (phone, email, nickname, gender, age, recommenderCode, callback) => {

    this.login = true;
    this.loggedIn = false;
    this.errorCode = 0;

    this.validateAccount();

    const params = new URLSearchParams();

    params.append("rt", "0"); //머니팡
    params.append("el", this.easyLoginType.toString());

    if (IsValidS(this.userId))
      params.append("id", this.userId);
    
    if (IsValidS(phone))
      params.append("ph", replaceLine(phone));
    
    if (IsValidS(email))
      params.append("em", email.trim());

    if (IsValidS(nickname) && nickname !== "n")
      params.append("nm", ToBase64(nickname.trim()));

    params.append("gn", gender.toString()); //성별
    params.append("ag", age.toString()); //나이대
    
    if (IsValidS(recommenderCode))
      params.append("rc", recommenderCode.trim());

    if (LOG_ENABLE)
      console.log(`registerUser : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("user/reg/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params.toString()
    }).then((res)=>{
        this.parseRegisterResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseRegisterResult(null, callback);
    });
  }

  //서버로 부터 수신된 로그인 결과를 바탕으로 로그인 데이터 설정
  parseRegisterResult = (result, callback) => 
  {
    this.login = false;

    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      this.errorCode = -1;

      if (callback)
      {
        callback(this.errorCode);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    this.errorCode = result.ret;

    switch(result.ret)
    {
      case 0:
        {
          if (LOG_ENABLE)
            console.log("register succeeded");

          this.loggedIn = true;
          this.validateProfile(result.pf);

          this.profile = result.pf; //프로필 정보를 저장한다.
          
          if (IsValidV(this.profile) && IsValidV(this.profile.idx))
          {
            this.userIdx = this.profile.idx;
            this.userType = this.profile.ut;

            if (LOG_ENABLE)
              console.log("user idx : " + this.userIdx);
          }

          this.sid = result.uidx;
          this.token = result.tk;

          if (LOG_ENABLE)
              console.log(`sid : ${this.sid} user token : ${this.token}`);

          this.saveToken();

          if (callback)
          {
            callback(0);
          }

          return;
        }
      default:

        if (callback)
        {
          callback(this.errorCode);
        }
        break;
    }
  }

  //-------------------------------------------------------------------------------------------------------
  //이메일 회원가입시도
  registerEmailUser = (phone, nickname, gender, age, autiInfo, authCode, callback) => {

    this.login = true;
    this.loggedIn = false;
    this.errorCode = 0;
    this.easyLoginType = 3;

    this.validateAccount();

    const params = new URLSearchParams();

    if (IsValidS(this.userId))
      params.append("id", this.userId);

    if (IsValidS(this.userPw))
      params.append("pw", ToBase64(this.userPw));
    
    if (IsValidS(phone))
      params.append("ph", replaceLine(phone));
   

    params.append("nm", ToBase64(nickname.trim()));
    params.append("gn", gender.toString()); //성별
    params.append("ag", age.toString()); //나이대

    params.append("ai", autiInfo); //인증 데이터
    params.append("ac", authCode); //인증 코드

    if (LOG_ENABLE)
      console.log(`registerEmailUser : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("user/ereg/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params.toString()
    }).then((res)=>{
        this.parseEmailRegisterResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseEmailRegisterResult(null, callback);
    });
  }

  //서버로 부터 수신된 로그인 결과를 바탕으로 로그인 데이터 설정
  parseEmailRegisterResult = (result, callback) => 
  {
    this.login = false;

    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      this.errorCode = -1;

      if (callback)
      {
        callback(this.errorCode);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    this.errorCode = result.ret;

    switch(result.ret)
    {
      case 0:
        {
          if (LOG_ENABLE)
            console.log("register succeeded");

          this.loggedIn = true;
          this.validateProfile(result.pf);

          this.profile = result.pf; //프로필 정보를 저장한다.
          
          if (IsValidV(this.profile) && IsValidV(this.profile.idx))
          {
            this.userIdx = this.profile.idx;
            this.userType = this.profile.ut;

            if (LOG_ENABLE)
              console.log("user idx : " + this.userIdx);
          }

          this.sid = result.uidx;
          this.token = result.tk;

          if (LOG_ENABLE)
              console.log(`sid : ${this.sid} user token : ${this.token}`);

          this.saveToken();

          if (callback)
          {
            callback(0);
          }

          return;
        }
      default:
 
        if (callback)
        {
          callback(this.errorCode);
        }
        break;
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //회원탈퇴신청
  //res : 사유
  requestUnregister = (res, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0");
    params.append("res", res.toString());

    axios({
      method:"POST",
      url: GetApiUrl("user/unregister/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseUnregisterResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseUnregisterResult(null, callback);
    });
  }

  parseUnregisterResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //ID 또는 비번 찾기
  //ft : 아이디찾기(0), 비번찾기(1)
  //phone : 전화번호
  //uid : 비번찾기시 ID
  findAccount = (ft, phone, uid, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0"); 
    params.append("at", "0");
    params.append("ft", ft.toString());
    params.append("av", replaceLine(phone));

    if (IsValidS(uid))
      params.append("id", uid.trim());
    
    if (LOG_ENABLE)
      console.log(`findAccount : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("user/find/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      data: params
    }).then((res)=>{
        this.parseFindResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseFindResult(null, callback);
    });
  }

  parseFindResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1, null, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseFindResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      this.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, result.ai, result.ac);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //ID 또는 비번 찾기 인증하기
  //ft : 아이디찾기(0), 비번찾기(1)
  //phone : 전화번호
  //ai : 인증데이터
  //ac : 인증코드
  findAccountAuth = (ft, phone, ai, ac, callback) => {

    const params = new URLSearchParams();

    params.append("rt", "0"); 
    params.append("at", "0");
    params.append("ft", ft.toString());
    params.append("av", replaceLine(phone));
    params.append("ai", ai);
    params.append("ac", ac);

    axios({
      method:"POST",
      url: GetApiUrl("user/auth/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      data: params
    }).then((res)=>{
        this.parseFindAuthResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseFindAuthResult(null, callback);
    });
  }

  parseFindAuthResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    if (result.ret === 100)
    {
      this.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, result);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  /*비밀번호 변경하기
  rt : 인증에의한변경(0), 앱내에서변경(1)
  ai : 인증에의한변경시 인증데이터
  npw : 신규비밀번호
  opw : 앱내변경시 이전비밀번호
  */
  changePw = (rt, ai, npw, opw, callback) => {

    const params = new URLSearchParams();

    params.append("rt", rt.toString()); 

    if (IsValidV(ai))
      params.append("ai", ai);

    params.append("npw", ToBase64(npw));

    if (IsValidS(opw))
      params.append("opw", ToBase64(opw));

    if (LOG_ENABLE)
      console.log(`changePw : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("user/chgpw/"),
      headers: {
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json',
      responseEncoding: 'utf8',
      data: params
    }).then((res)=>{
        this.parseChangePwResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseChangePwResult(null, callback);
    });
  }

  parseChangePwResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseChangePwResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      this.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //닉네임 변경
  updateUserNickname = (nickname, callback) => {

    const params = new URLSearchParams();

    params.append("nm", ToBase64(nickname));

    axios({
      method:"POST",
      url: GetApiUrl("user/nickname/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{
        this.parseNicknameResult(res.data, callback);
    }).catch(error=>{
        console.log(error);

        if (axios.isCancel(error))
          return;

        this.parseNicknameResult(null, callback);
    });
  }

  parseNicknameResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    //갱신성공
    if (result.ret === 0)
    {
      this.profile = {
        ...this.profile,
        nm: FromBase64(result.nm),
      };
    }
    else if (result.ret === 100)
    {
      this.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret);
    }
  }
  //사용자의 추천인 코드 입력 가능기간을 검사함-------------------------------------------------------------------------------------------------------

  checkUserRecmInputDate = (callback) => {

    axios({
      method:"POST",
      url: GetApiUrl("user/recmchk/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{
        this.parseCheckRecmDateResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        
        if (axios.isCancel(error))
          return;

        this.parseCheckRecmDateResult(null, callback);
    });
  }

  parseCheckRecmDateResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-2);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`parseCheckRecmDateResult : ${JSON.stringify(result)}`);

    this.recmLimitDateInfoReady = true; //추천인 입력기간 제한 시간 정보 조회완료했나?

    if (result.ret === 0 && IsValidS(result.rd))
    {
      this.recmInpLimitDate = ConvertSqlDateToDate(result.rd);

      if (this.recmInpLimitDate)
      {
        this.recmInputEnabled = this.recmInpLimitDate >= (new Date());
      }
      else
      {
        this.recmInputEnabled = false; //추천인 코드 입력이 가능한 계정인가?
      }
    }
    else
    {
      this.recmInputEnabled = false; //추천인 코드 입력이 가능한 계정인가?
      this.recmInpLimitDate = null;
    }

    if (LOG_ENABLE)
      console.log(`recm input limit date info = enabled=${this.recmInputEnabled}/date=${this.recmInpLimitDate}`);

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //이벤트 참여
  //eventCode : 추천인코드입력(0), 추천인코드없음(1)
  //eventValue1 : 추천인코드입력이벤트시추천인코드
  enterEvent = (eventCode, eventValue1, callback) => {

    const params = new URLSearchParams();

    params.append("rt", eventCode.toString());

    if (IsValidV(eventValue1))
      params.append("val1", ToBase64(eventValue1.toString()));

    if (LOG_ENABLE)
      console.log(`enterEvent : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("user/event/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params.toString(),
    }).then((res)=>{
        this.parseEventResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        if (axios.isCancel(error))
          return;

        this.parseEventResult(null, callback);
    });
  }

  parseEventResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(JSON.stringify(result));

    //갱신성공
    if (result.ret === 0)
    {
      switch(result.rt)
      {
        case 0: //추천인이벤트
          if (result.val1 > 0)
          {
            this.profile = {
              ...this.profile,
              pt1: this.profile.pt1 + result.val1,
            };
          }
          
          break;
        case 1: //신규가입이벤트
          if (result.val1 > 0)
          {
            this.profile = {
              ...this.profile,
              pt1: this.profile.pt1 + result.val1,
            };
          }
          break;
      }
    }
    else if (result.ret === 100)
    {
      this.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  validateAccount = () =>
  {
    if (IsValidS(this.userId))
      this.userId = this.userId.trim();

    if (IsValidS(this.userPw))
      this.userPw = this.userPw.trim();
  }

  removeToken = () =>
  {
    delCookie("cptki");
  }

  saveToken = () =>
  {
    let temp = {
      sid : this.sid,
      token : this.token
    };

    setCookie("cptki", CryptoJS.AES.encrypt(JSON.stringify(temp), "ajecdsoFEwfdeuh*&56$lzpidr&^1$#n").toString());

    if (LOG_ENABLE)
      console.log("save token");
  }

  loadToken = () =>
  {
    this.token = null;
    this.sid = null;

    let v = getCookie("cptki");

    if (!IsValidS(v))
    {
      if (LOG_ENABLE)
        console.log("session info invalid");

      return;
    }

    try
    {
      const bytes = CryptoJS.AES.decrypt(v, 'ajecdsoFEwfdeuh*&56$lzpidr&^1$#n');

      let t = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      this.sid = t.sid;
      this.token = t.token;

      if (LOG_ENABLE)
      {
        console.log("token found : id = " + this.sid + "/ tk = " + this.token);
      }
    }
    catch(e)
    {
      console.log(e.toString());
    }
  }

  processLogOut = () =>
  {
    this.removeToken();
    this.userId = null;
    this.loggedIn = false; //서버에로그인되었나?
    this.login = false; //로그인시도중인가?
    this.profile = null; //사용자의 프로필 정보

    //비 observable
    this.sid = null; //세션id
    this.token = null; //토큰

    this.userIdx = -1; //사용자 고유번호
    this.userType = -1; //로그인 유저의 타입
    this.errorCode = 0; //로그인에러코드
    this.sessionRefreshing = false;
    this.sessionError = false; //세션 오류가 생기는경우 true로 설정됨

    this.recmLimitDateInfoReady = false; //추천인 입력기간 제한 시간 정보 조회완료했나?
    this.recmInputEnabled = false; //추천인 코드 입력이 가능한 계정인가?
    this.recmInpLimitDate = null; //추천인 입력 제한 시간 정보, date

    clearAllData();
  }

  //axios config--------------------------------------------------------------------------
  isApiUrl = (url) =>
  {
    if (!IsValidS(url))
      return false;

    if (url.indexOf(GetApiUrlBase()) >= 0)
      return true;

    return false;
  }

  setHeaderToken = async (config) =>
  {
    if (IsValidS(this.token) && IsValidV(this.sid))
    {
      if (APP_MODE === 0)
        console.log(`request url : ${config.url}`);

      if (this.isApiUrl(config.url))
      {
        if (APP_MODE === 0)
          console.log(`set default header : ${config.url}, ${this.sid}, ${this.token}`);

        config.headers["tk"] = this.token;
        config.headers["uidx"] = this.sid.toString();
      }      
    }
    
    return config;
  }
}

const LoginStoreInstance = new LoginStore();
const LoginStoreContext = React.createContext(LoginStoreInstance);

const UseLoginStore = () => React.useContext(LoginStoreContext);

export {UseLoginStore, LoginStoreInstance};
