
import React from 'react';
import './PangLog.css';
import '../../App.css';
import { GetAdsTypeSimple, GetDataDateAndTime, GetFormattedNum } from '../../Util/Util';
import { GetImgUrl } from '../../URL/AppUrl';

//팡을 사용했는가 벌었는가?
const isPangUsed = (logType) =>
{
  switch(logType)
  {
    case 0: //머니를 팡으로 전환했음, 팡을 벌었다
    case 2: //상품을 팡으로 환급, 획득
    case 3: //운영자 지급
    case 5: //자랑하기
    case 6: //신규유저
      return false;
    case 1: //팡으로 팡팡박스 구매, 사용
    case 4: //운영자회수
      return true;

    default:
      return false;
  }
}

//tp : 머니를팡으로전환(0), 팡팡박스구매(1), 팡환급받음(2)
//팡로그 타입코드를 텍스트로변환
const getPangLogTypeText = (logType) =>
{
  switch(logType)
  {
    case 0: //머니를팡으로 전환후팡팡박스구매(0)
      return "머니를 팡으로 전환";
    case 1: //팡으로 팡팡박스구매(1)
      return "팡팡박스 구매";
    case 2: //상품을 팡으로 환급(2)
      return "팡 환급";
    case 3: //운영자지급
      return "운영자지급";
    case 4: //운영자회수
      return "운영자회수";
    case 5: //자랑하기
      return "자랑하기";
    case 6: //신규유저
      return "신규유저미션";
    default:
      return "";
  }
}

const PangLogItem = (props) =>
{
  const pangAdd = !isPangUsed(props.item.tp);

  return (
    <div className='wd100p colcc mgbt15'>
      <div className='wd100p rowsc'>
        <div className='rowcc'>
          <label className='font14 wt300 color31'>{getPangLogTypeText(props.item.tp)}</label>
        </div>
        
        <label className={pangAdd ? 'font14 wt400 colorff' : 'font14 wt400 color31'}>{pangAdd ? `+${GetFormattedNum(props.item.pnt)}` : `-${GetFormattedNum(props.item.pnt)}`}</label>
      </div>

      <div className='wd100p rowsc mgtop5'>
        <label className='font12 colorb3 wt400'>{GetDataDateAndTime(props.item.rd)}</label>
      </div>

      <div className='clline mgtop15'></div>
    </div>
  );
}

export default PangLogItem;