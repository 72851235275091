
import React from 'react';
import './CashLog.css';
import '../../App.css';
import { GetAdsTypeSimple, GetDataDateAndTime, GetFormattedNum } from '../../Util/Util';
import { GetImgUrl } from '../../URL/AppUrl';

const getPlatformIcon = (iconType) =>
  {
    switch(iconType)
    {
      case 0://인스타
        return GetImgUrl("clins@3x.png");
      case 1: //페이스북
        return GetImgUrl("clfb@3x.png");
      case 2: //유튜브
        return GetImgUrl("clyt@3x.png");
      case 3: //틱톡
        return GetImgUrl("cltt@4x.png");
      default:
        return GetImgUrl("cash3@3x.png");
    }
  }
  
  const getCashLogTitle = (item) =>
  {
    switch(item.tp)
    {
      case 0: //일반광고적립(0)
      case 1: //광고적립취소(1)
        return `${GetAdsTypeSimple(item.at)} ${item.uid}`;
      case 2: //머니사용
        return "머니사용";
      case 3: //머니환급
        return "머니환급";
      case 4: //추천인적립
        return "추천인적립";
      case 5: //신규회원가입
        return "신규회원가입";
      case 6: //머니 충전소적립
        return "머니충전소적립";
      case 7: //배송비포인트환불
        return "배송비환불";
      case 8: //운영자지급
        return "운영자지급";
      case 9: //운영자회수
        return "운영자회수";
      case 10: //자랑하기
        return "자랑하기";
      case 11: //신규유저미션
        return "신규유저미션";
      default:
        return "";
    }
  }
  
  const getPointAddType = (item) =>
  {
    switch(item.tp)
    {
      case 0: //일반광고적립(0)
        return "정상 적립";
      case 1: //광고적립취소(1)
        return "적립 취소";
      case 2: //머니사용
        return "정상 사용";
      case 3: //머니환급
        return "정상 사용";
      case 4: //추천인적립
      case 5: //추천인적립
      case 6: //머니 충전소적립
      case 7: //배송비포인트환불
      case 8: //운영자지급
      case 10: //자랑하기
      case 11: //신규유저
        return "정상 적립";
      case 9: //운영자회수
        return "정상 차감";
      default:
        return "";
    }
  }
  
  //포인트를 적립했는지(true) 사용했는지(false)
  const checkPointAdd = (item) =>
  {
    switch(item.tp)
    {
      case 0: //일반광고적립(0)
      case 4: //추천인적립
      case 5: //신규회원
      case 6: //겟머니
      case 7: //배송비포인트환불
      case 8: //운영자지급
      case 10: //자랑하기
      case 11: //신규유저
        return true;
  
      case 1: //광고적립취소(1)
      case 2: //머니사용
      case 3: //머니환급
      case 9: //운영자회수
      default:
        return false;
    }
  }

const CashLogItem = (props) =>
{
  const pointAdd = checkPointAdd(props.item);

  return (
    <div className='wd100p colcc mgbt15'>
      <div className='wd100p rowsc'>
        <div className='rowcc'>
          <img src={getPlatformIcon(props.item.pf)} alt="내역" className='img1515 mgrt5'/>
          <label className='font14 wt300 color31'>{getCashLogTitle(props.item)}</label>
        </div>
        
        <label className={pointAdd ? 'font14 wt400 colorff' : 'font14 wt400 color31'}>{pointAdd ? `+${GetFormattedNum(props.item.pnt)}M` : `-${GetFormattedNum(props.item.pnt)}M`}</label>
      </div>

      <div className='wd100p rowsc mgtop5'>
        <label className='font12 colorb3 wt400 mglt20'>{GetDataDateAndTime(props.item.rd)}</label>
        <label className='font12 colorb3 wt300'>{getPointAddType(props.item)}</label>
      </div>

      <div className='clline mgtop15'></div>
    </div>
  );
}

export default CashLogItem;