export default class TimeUtil
{
  lastTime = null;

  catch = () =>
  {
    this.lastTime = new Date();
  }

  getPassedTime = (now) => {
    return (now.getTime() - this.lastTime.getTime()) / 1000.0;
  }

  getPassedTime2 = () => {
    return ((new Date()).getTime() - this.lastTime.getTime()) / 1000.0;
  }
}