import React from 'react';
import {GetApiUrl, LOG_ENABLE} from '../URL/AppUrl';
import axios from 'axios';
import { FromBase64, GetDataDate2, IsValidS  } from '../Util/Util';
import { action, computed, makeObservable, observable } from 'mobx';

//공지관련
class NoticeStore {
  //observable변수
  noticeList = null; //서버에서 조회된 목록

  //비옵저버
  refIdx = 0; //마지막으로 읽은 공지중 고유번호가 가장 작은값

  constructor() {
    makeObservable(this, {
      noticeList : observable,
      
      getItemCount: computed,
      getLastNoticeOne: computed,
      clearAll: action,
      parseNoticeListResult: action,
    });
  }

  clearAll = () =>
  {
    this.noticeList = null; //서버에서 조회된 목록
    this.refIdx = 0; //마지막으로 읽은 공지중 고유번호가 가장 작은값
  }

  get getItemCount()
  {
    if (!IsValidS(this.noticeList))
      return 0;

    return this.noticeList.length;
  }

  //읽은 공지 중에서 마지막 한개를 얻음
  get getLastNoticeOne()
  {
    if (!IsValidS(this.noticeList))
      return null;

    return this.noticeList[0];
  }
  
  //공지 목록읽기-------------------------------------------------------------------------------------------------------------
  loadNoticeList = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("pos", this.refIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("notice/cplist"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseNoticeListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseNoticeListResult(null, callback);
    });
  }

  parseNoticeListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseNoticeListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        let newList = [];

        result.list.forEach(item => {
          this.decodeNotice(item);

          if (this.refIdx === 0 || this.refIdx > item.idx)
            this.refIdx = item.idx;

          newList.push({
            ...item,
            nt: this.checkNewItem(item)
          });

        });

        if (IsValidS(this.noticeList))
        {
          this.noticeList = [...this.noticeList, ...newList];
        }
        else
          this.noticeList = newList;
      }

    }

    if (callback)
      callback(result.ret, readCount);
  }

  //해당 공지가 7일이내 작성되었는지 확인하기
  checkNewItem = (item) =>
  {
    const date = GetDataDate2(item.rd);

    if (!date)
    {
      if (LOG_ENABLE)
        console.log('data convert failed');

      return false;
    }

    const date1 = new Date();

    if (LOG_ENABLE)
      console.log(`sql date : ${date.toString()}/${date1.toString()}`);
   
    let diffDate = Math.abs((date1.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    if (diffDate <= 7)
    {
      if (LOG_ENABLE)
        console.log(`new notice`);
      return true;
    }

    return false;
  }

  decodeNotice = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
}

const NoticeStoreInstance = new NoticeStore();
const NoticeStoreContext = React.createContext(NoticeStoreInstance);

const UseNoticeStore = () => React.useContext(NoticeStoreContext);

export {UseNoticeStore, NoticeStoreInstance};
