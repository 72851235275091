import React from 'react';
import '../Mission/Mission.css';
import '../../App.css';
import { LOG_ENABLE } from '../../URL/AppUrl';
import { PangStoreInstance } from '../../Stores/PangStore';
import { InvenStoreInstance } from '../../Stores/InvenStore';
import MsgPop from '../Common/MsgPop';
import { IsValidS, getRandomNumber } from '../../Util/Util';
import Loading from '../Loading/Loading';

//팡팡박스 오픈 연출창
class PangBoxOpenView extends React.Component
{
  state = {
    loading: false,

    showPop: false,
    popupTitle: '',
    popupMsg: '',
    okBtn: '',
  }

  loading = false;
  popupCallback = null;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  showMsgPopup = (title, msg, okbtn, callback) =>
  {
    this.popupCallback = callback;
    this.setState((prevState) => ({...prevState, showPop : true, popupTitle: title, popupMsg: msg, okBtn: okbtn}));
  }

  closeMsgPop = () =>
  {
    this.setState((prevState) => ({...prevState, showPop : false}));
  }

  componentDidMount()
  {
    window.addEventListener("message", this.handleChildMessage);
  }

  componentWillUnmount()
  {
    window.removeEventListener("message", this.handleChildMessage);
  }

  handleChildMessage = (e) =>
  {
    if (LOG_ENABLE)
      console.log(`handleChildMessage : ${e.data}`);

    try
    {
      const json = JSON.parse(e.data);

      if (json)
      {
        switch(json.id)
        {
          case 0: //박스 오픈요청
            this.handleBoxOpen();
            return;
          case 1: //창닫기 요청
            this.handleClose(false);
            return;
          case 2: //창닫기 요청, 상품 보관함으로
            this.handleClose(true);
            return;
        }
      }
    }
    catch(e)
    {
      if (LOG_ENABLE)
        console.log(`handleChildMessage failed :${e.toString()}`);
    }
  }

  //박스 오픈을 시도한다.
  handleBoxOpen = () =>
  {
    if (this.state.loading)
      return;

    this.setLoading(true);

    if (this.props.mode === 0) //정식 박스 오픈
    {
      InvenStoreInstance.openPangBox(this.props.itemIdx, this.onBoxOpenResult);  
    }
    else //팡팡박스 체험오픈
    {
      InvenStoreInstance.openPangBoxTest(this.onBoxOpenTestResult);
    }
  }

  //팡팡박스 정식 오픈 결과 수신
  onBoxOpenResult = (resultCode, result) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        break;
      case 1://잘못된 요청
        this.showMsgPopup("박스오픈 실패", "잘못된 요청입니다.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 2: //박스가 존재하지 않음
        this.showMsgPopup("박스오픈 실패", "팡팡박스가 존재하지 않습니다.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 3: //서버 점검이 예약되어 있음
        this.showMsgPopup("박스오픈 실패", "현재 서버점검 진행중이라 팡팡박스 오픈이 불가능합니다.\n나중에 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 4: //팡팡박스 시스템 점검중
        this.showMsgPopup("박스오픈 실패", "현재 팡팡박스 시스템 점검 진행중이라 팡팡박스 오픈이 불가능합니다.\n나중에 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 5: //상품 준비중
        this.showMsgPopup("박스오픈 실패", "현재 팡팡박스 상품을 준비 중입니다.\n잠시 후 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
        return;
      default:
        this.showMsgPopup("박스오픈 실패", `서버 오류로 인해 팡팡박스 오픈이 실패 했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, "확인", this.onBoxOpenFailedClosed);
        return;
    }

    this.boxItemUpdated = true;

    const items = {
      id: 0,
      needTutorial: this.props.needTutorial, //튜토리얼 필요하면 true
      list: result.wlist,
    };

    //결과를 iframe에 넘긴다.
    this.sendMsgToChild(JSON.stringify(items));
  }

  //팡팡박스 체험오픈 결과 수신
  onBoxOpenTestResult = (resultCode, result) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상
        break;
      case 1://잘못된 요청
        this.showMsgPopup("박스오픈 실패", "잘못된 요청입니다.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 3: //서버 점검이 예약되어 있음
        this.showMsgPopup("박스오픈 실패", "현재 서버점검 진행중이라 팡팡박스 오픈이 불가능합니다.\n나중에 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 4: //팡팡박스 시스템 점검중
        this.showMsgPopup("박스오픈 실패", "현재 팡팡박스 시스템 점검 진행중이라 팡팡박스 오픈이 불가능합니다.\n나중에 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
        return;
      case 5: //상품 준비중
        this.showMsgPopup("박스오픈 실패", "현재 팡팡박스 상품을 준비 중입니다.\n잠시 후 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
        return;
      default:
        this.showMsgPopup("박스오픈 실패", `서버 오류로 인해 팡팡박스 체험이 실패 했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, "확인", this.onBoxOpenFailedClosed);
        return;
    }

    if (!IsValidS(result.wlist))
    {
      this.showMsgPopup("박스오픈 실패", "현재 팡팡박스 상품을 준비 중입니다.\n잠시 후 이용해 주세요.", "확인", this.onBoxOpenFailedClosed);
      return;
    }

    const items = {
      id: 0,
      list: [...result.wlist],
    };

    //상품중에서 임의의 하나를 선택한다.
    const sel = getRandomNumber(result.wlist.length);
    items.list[sel].sel = 1;

    if (LOG_ENABLE)
      console.log(`test result :${JSON.stringify(items)}`);
    
    //결과를 웹쪽으로 넘긴다.
    this.sendMsgToChild(JSON.stringify(items));
  }

  //박스 오픈실패 팝업 닫힘의 처리
  onBoxOpenFailedClosed = () =>
  {
    this.closeMsgPop();
    this.handleClose(false);
  }

  //gotoPdBox : 상품보관함으로 가기
  handleClose = (gotoPdBox) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`InvenBoxOpen.handleClose : ${gotoPdBox}/${this.props.needTutorial}`);

    if (this.props.mode === 0) //정식 박스 오픈인경우
    {
      if (this.boxItemUpdated)
        this.props.handleUserItemUpdated(gotoPdBox, this.props.needTutorial);
      else
        this.props.handleClose(gotoPdBox, this.props.needTutorial);
    }
    else //박스 체험 오픈인경우
    {
      this.props.handleClose(gotoPdBox, this.props.needTutorial);
    }
  }

  sendMsgToChild = (msg) =>
  {
    if (LOG_ENABLE)
      console.log(`send msg to child`);

    document.getElementById('idchildview').contentWindow.postMessage(msg, '*');
  }

  render()
  {
    return (
      <div className='bingoviewbg rowcc'>
        {
          this.state.showPop ? (<MsgPop msg={this.state.popupMsg} okBtn={this.state.okBtn} handleConfirm={() => {
            if (this.popupCallback)
              this.popupCallback();
            else
              this.closeMsgPop();
          }}/>) : null
        }
        {
          this.state.loading ? (<Loading />) : null
        }
        <iframe className='bingoview' src={PangStoreInstance.openAniUrl} id="idchildview">

        </iframe>
      </div>
    );
  }
}

export default PangBoxOpenView;