import React from 'react';
import './Address.css';
import '../../App.css';
import { GotoAppStore, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { AddressStoreInstance } from '../../Stores/AddressStore';
import { IsValidS } from '../../Util/Util';
import AddressItem from './AddressItem';


//배송지 관리창
class Address extends React.Component
{
  state = {
    loading: false,
    
    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,
  };
  intervalId = -1;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      if (!AddressStoreInstance.addressReady)
      {
        this.setLoading(true);
        AddressStoreInstance.loadAddressList(this.onLoadAddress);
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    if (!AddressStoreInstance.addressReady)
    {
      this.setLoading(true);
      AddressStoreInstance.loadAddressList(this.onLoadAddress);
    }
  }

  onLoadAddress = (resultCode, readCount) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`배송지 목록 조회 중 오류가 발생했습니다.`);
      return;
    }
  }

  handleAddress = (mode, item) =>
  {
    if (this.state.loading)
      return;

    switch(mode)
    {
      case 0:
        this.setShowPopup(2, "배송지 수정은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        return;
      case 100:
        this.setShowPopup(2, "배송지 추가는 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        return;
      default:
        return;
    }
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }
  
  render()
  {
    let addressList = null;

    if (IsValidS(AddressStoreInstance.addressList))
    {
      addressList = AddressStoreInstance.addressList.map(item => {
        return <AddressItem key={item.idx} item={item} handleAddress={this.handleAddress}/>
      });
    }

    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(0, null, null)}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='wd100p max500 flexcoltc'>
          <TopBar2 withLine={true} title="배송지 관리" handleBack={this.handleBack} />

          <div className='mgtop60'></div>
        
          <div className='wd88p coltl mgbt100'>
            {addressList}
          </div>
        </div>

        <div className='addbtnbg rowcc'>
          <button className='addbtn2 font15 wt400 colorw btncur' onClick={() => this.handleAddress(100, null)}>+ 배송지 추가</button>
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(Address));

