import './PangBox.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import PangBoxItemPd from './PangBoxItemPd';

const PangBoxItem = (props) =>
{
  let items = null;

  if (IsValidS(props.item.items))
  {
    items = props.item.items.map(item => {
      return <PangBoxItemPd key={item.idx} item={item} handleClick={props.handleClick}/>
    });
  }

  return (
    <div className='wd100p rowss mgbt30'>
      {items}
    </div>
  );
}

export default PangBoxItem;