import { AddressStoreInstance } from "./AddressStore";
import { AdsStoreInstance } from "./AdsStore";
import { AlarmStoreInstance } from "./AlarmStore";
import { BingoGameStoreInstance } from "./BingoGameStore";
import { CashupStoreInstance } from "./CashupStore";
import { FaqStoreInstance } from "./FaqStore";
import { GuideStoreInstance } from "./GuideStore";
import { InvenStoreInstance } from "./InvenStore";
import { NoticeStoreInstance } from "./NoticeStore";
import { PangStoreInstance } from "./PangStore";
import { PointStoreInstance } from "./PointStore";
import { SNSQueryInstance } from "./SnsQuery";
import { SnsStoreInstance } from "./SnsStore";

const clearAllData = () =>
{
  AddressStoreInstance.clearAll();
  BingoGameStoreInstance.clearAll();
  PointStoreInstance.clearAll();
  AdsStoreInstance.clearAll();
  AlarmStoreInstance.clearAll();
  CashupStoreInstance.clearAll();
  FaqStoreInstance.clearAll();
  GuideStoreInstance.clearAll();
  InvenStoreInstance.clearAll();
  NoticeStoreInstance.clearAll();
  PangStoreInstance.clearAll();
  SNSQueryInstance.clearAll();
  SnsStoreInstance.clearAll();
}

export {clearAllData};