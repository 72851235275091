import './Mission.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';
import MissionSnsAds from './MissionSnsAds';
import MissionSnsAdsDummy from './MissionSnsAdsDummy';

const MissionSnsItem = (props) =>
{
  let ads = null;

  if (IsValidS(props.item.ads))
  {
    ads = props.item.ads.map((item) => {

      if (item.contType === 0)
        return <MissionSnsAds key={item.idx} item={item} handleSnsMission={props.handleSnsMission}/>
      else
        return <MissionSnsAdsDummy key={item.idx}/>
    });
  }

  return (
    <div className='mssnsadsitem rowsc'>
      {ads}
    </div>
  );
}

export default MissionSnsItem;