import React from 'react';
import './Inventory.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, GotoAppStore, GotoLogin, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetFormattedNum, IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import MoreItem from '../Notice/MoreItem';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import InvenBox from './InvenBox';
import InvenPd from './InvenPd';
import { InvenStoreInstance } from '../../Stores/InvenStore';
import { PangStoreInstance } from '../../Stores/PangStore';
import PangBoxBuy from '../PangBox/PangBoxBuy';
import PangBoxBuySuc from '../PangBox/PangBoxBuySuc';
import PangBoxBuyFail from '../PangBox/PangBoxBuyFail';
import PangBoxOpenView from '../PangBox/PangBoxOpenView';
import SelectMsgPop from '../Common/SelectMsgPop';
import ToastMessage from '../Common/ToastMessage';
import InvenCommentPop from './InvenCommentPop';
import InvenPdDetail from './InvenPdDetail';

//인벤토리 페이지
class Inventory extends React.Component
{
  state = {
    loading: false,

    noItem: false,

    showPop: 0,
    popTitle: '',
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    currentTab: 0, //박스보관함(0), 상품 보관함(1)

    showBoxBuy: false, //박스 구매팝업
    showBoxBuySuc: false, //박스 구매 성공 팝업
    showBoxBuyFailed: false, //박스 구매 실패 팝업
    showBoxOpen: false, //팡팡박스 오픈 연출창 보이기
    boxOpenMode: 0, //정식오픈(0), 체험오픈(1)

    showToastMsg: false,
    toastMessage: '토스트 메시지',

    showCommentPop: false, //자랑하기 코멘트 입력창
    showDetail: false, //보유상품 상세정보 보기
  };

  intervalId = -1;
  newBoxIdx = -1;
  needUserPdListUpdate = false; //이용자 보유상품 목록 갱신이 필요함
  editItem = null;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0}));
  }

  setShowSelectPopup = (title, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 3, popTitle: title, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  setCurrentTab = (v) =>
  {
    this.setState((prevState) => ({...prevState, currentTab : v}));
  }

  //박스 구매창
  setShowBoxBuy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxBuy : v}));
  }

  //구매 성공창
  setShowBoxBuySuc = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxBuySuc : v}));
  }

  //구매 실패창
  setShowBoxBuyFail = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxBuyFailed : v}));
  }

  //팡팡박스 오픈 연출창 보이기
  setShowBoxOpen = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxOpen : v}));
  }

  //팡팡박스 오픈모드 설정
  setBoxOpenMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, boxOpenMode : v}));
  }

  showToastMessage = (msg) =>
  {
    this.setState((prevState) => ({...prevState, showToastMsg : true, toastMessage: msg}));
    setTimeout(this.hideToastMessage, 2000);
  }

  hideToastMessage = () =>
  {
    this.setState((prevState) => ({...prevState, showToastMsg : false}));
  }

  //자랑하기 코멘트 창
  showCommentPop = (v) =>
  {
    this.setState((prevState) => ({...prevState, showCommentPop : v}));
  }

  //상품 상세보기
  showPdDetail = (v) =>
  {
    this.setState((prevState) => ({...prevState, showDetail : v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    GotoLogin();
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }

    if (this.needUserPdListUpdate && !this.state.loading)
    {
      this.refreshUserPdList();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      if (NaviStoreInstance.targetInvenTab !== -1)
      {
        this.setCurrentTab(NaviStoreInstance.targetInvenTab);
        NaviStoreInstance.targetInvenTab = -1;
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    if (NaviStoreInstance.targetInvenTab !== -1)
    {
      this.setCurrentTab(NaviStoreInstance.targetInvenTab);
      NaviStoreInstance.targetInvenTab = -1;
    }
  }

  handleTab = (tabIndex) =>
  {
    if (this.state.loading || tabIndex === this.state.currentTab)
      return;

    this.setCurrentTab(tabIndex);
  }

  getMaxBoxCount = () =>
  {
    return Math.floor(((LoginStoreInstance.getUserCash * 0.5) + LoginStoreInstance.getUserPang) / 1000);
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }

  //팡팡박스 구매하기
  handleBuyBoxPop = () =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuy(true);
  }

  //팡팡박스 오픈시도
  handleOpenBox = (item) =>
  {
    if (this.state.loading || !item)
      return;

    this.newBoxIdx = item.idx;

    if (!PangStoreInstance.openAniUrlReady) //팡팡박스 당첨 연출 페이지 주소가 준비되지 않았다면
    {
      this.setLoading(true);
      PangStoreInstance.loadOpenPageUrl(this.onOpenPageUrlLoaded);
    }
    else //팡팡박스 오픈 연출 페이지 열기
    {
      this.setBoxOpenMode(0);
      this.setShowBoxOpen(true);
    }
  }

  //상품 환급하기 관련 메시지
  getItemBreakeMsg = () =>
  {
    let cost = 0;

    switch(this.editItem.lv)
    {
      case 0: //하품
        cost = 500;
        break;
      case 1: //중품
        cost = 1000;
        break;
      case 2: //상품
        cost = 2000;
        break;
      case 3: //최상품
        cost = 5000;
        break;
      case 4: //유니크
        cost = 10000;
        break;
      default:
        break;
    }

    return `선택하신 상품이 ${GetFormattedNum(cost)}팡으로 환급되며 환급 후에는 철회가불가능합니다.\n환급하시겠습니까?`;
  }

  //보유상품 관련 요청 처리
  //mode : 
  handleEditItem = (mode, item) =>
  {
    if (this.state.loading)
      return;

    switch(mode)
    {
      case 0: //배송신청
        this.setShowPopup(2, "상품 배송 신청은 현재 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        return;
      case 1: //팡으로 환급신청
        this.editItem = item;
        this.setShowSelectPopup("잠깐만요!", this.getItemBreakeMsg(), "환급하기", "닫기", this.handleBreakItem);
        return;
      case 2: //배송상세보기
        this.editItem = item;
        this.showPdDetail(true);
        return;
      case 3: //당첨소감 작성
        this.editItem = item;
        this.showCommentPop(true);
        return;
      default:
        return;
    }
  }

  //상품 팡으로 환급하기 질문 팝업
  handleBreakItem = (yes) =>
  {
    this.closePopup();

    if (!yes || this.state.loading)
      return;

    this.setLoading(true);
    InvenStoreInstance.requestPdBreak(this.editItem.idx, this.onBreakPd);
  }

  //상품 환급하기 신청결과
  onBreakPd = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.showToastMessage("환급이 완료되었습니다.");
        return;
      case 1:
        this.setShowPopup(1, '잘못된 요청입니다.', "확인", "", null);
        return;
      case 2:
        this.setShowPopup(1, '상품이 존재하지 않습니다.', "확인", "", null);
        return;
      case 3:
        this.setShowPopup(1, '배송신청이 진행중인 상품은 환급이 불가능합니다.', "확인", "", null);
        return;
      default:
        this.setShowPopup(1, '환급 요청이 실패 했습니다.\n잠시 후 이용해 주세요.', "확인", "", null);
        return;
    }
  }

  //박스 구매창에서의 버튼 처리, 
  //mode : 닫기(0), 구매하기(1)
  handleBuyBox = (mode) =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuy(false);

    if (mode === 0)
    {
      return;
    }
    else //박스 구매하기
    {
      //돈이 부족하면
      if ((LoginStoreInstance.getUserCash + LoginStoreInstance.getUserPang) < 1000)
      {
        this.setShowBoxBuyFail(true);
        return;
      }
      
      this.setLoading(true);
      InvenStoreInstance.buyPangBox(2, null, null, this.onBuyBoxResult);
    }
  }

  //박스 구매 요청 결과
  onBuyBoxResult = (resultCode, result) =>
  {
    switch(resultCode)
    {
      case 0: //구매 성공
        break;
      case 1: //잘못된 요청
        this.setLoading(false);
        this.setShowPopup(1, "잘못된 요청입니다.", "확인", "", null);
        return;
      case 2: //포인트 부족
        this.setLoading(false);
        this.setShowBoxBuyFail(true);
        return;
      case 3: //결제 실패
        this.setLoading(false);
        this.setShowPopup(1, "주문 결제가 실패 하였습니다.", "확인", "", null);
        return;
      case 5: //박스 구매 불가능한 계정
        this.setLoading(false);
        this.setShowPopup(1, "이 계정은 현재 팡팡박스 구매가 불가능합니다.", "확인", "", null);
        return;
      case 4: //사용자 정보 조회실패
      case 6: //박스 구매실패
      default:
        this.setLoading(false);
        this.setShowPopup(1, `팡팡박스 구매중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, "확인", "", null);
        return;
    }

    //새로구매한 박스의 고유번호를 저장해둠
    this.newBoxIdx = result.bidx;

    if (result.rt === 0) //일반 박스 구매시
    {
      //이용자의 보유머니와 팡 정보를 갱신해 준다.
      LoginStoreInstance.setUserPoint(result.rc, result.rp);

      //팡팡박스 구매에 성공했다. 서버에서 팡팡박스 목록을 조회해야 한다.
      if (!InvenStoreInstance.boxlistReady)
      {//아직 팡팡박스 목록이 조회되지 않았으면, 전체 목록을 조회한다.
        InvenStoreInstance.clearBoxList();
        InvenStoreInstance.loadBoxList(this.onLoadBoxListForBuy);
      }
      else
      {//새로 구매한 박스의 정보만 조회한다.
        InvenStoreInstance.loadBoxInfo(result.bidx, this.onBoxInfoLoaded);
      }
    }
    else //골드박스구매, 아직구현안됨
    {
      this.setLoading(false);
      return;
    }
  }

  onBoxInfoLoaded = (resultCode, newBoxInfo) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `구매 정보 조회 중 오류가 발생했습니다.\n앱을 다시 실행해 주세요.\n오류코드 ${resultCode}`, "확인", '', null);
      return;
    }

    //새로구매한 박스를 목록에 추가해준다.
    InvenStoreInstance.addBox(newBoxInfo);

    this.setShowBoxBuySuc(true);
  }

  onLoadBoxListForBuy = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `구매 정보 조회 중 오류가 발생했습니다.\n앱을 다시 실행해 주세요.\n오류코드 ${resultCode}`, "확인", '', null);
      return;
    }

    this.setShowBoxBuySuc(true);
  }

  //박스 구매 성공팝업의 버튼 눌림처리
  handleBuyBoxSuc = (mode) =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuySuc(false);

    if (mode === 0) //닫기
    {

    }
    else if (mode === 1) //개봉하기
    {
      if (!PangStoreInstance.openAniUrlReady) //팡팡박스 당첨 연출 페이지 주소가 준비되지 않았다면
      {
        this.setLoading(true);
        PangStoreInstance.loadOpenPageUrl(this.onOpenPageUrlLoaded);
      }
      else //팡팡박스 오픈 연출 페이지 열기
      {
        this.setBoxOpenMode(0);
        this.setShowBoxOpen(true);
      }
    }
  }

  //팡팡박스 오픈 연출 페이지 주소를 획득함
  onOpenPageUrlLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `네트워크 오류로 인해 팡팡박스 오픈 요청에 실패 했습니다.\n잠시 후 이용해 주세요.`, "확인", '', null);
      return;
    }

    this.setBoxOpenMode(0);
    this.setShowBoxOpen(true);
  }

  //박스구매 실패 팝업 버튼처리
  handleBuyBoxFail= (mode) =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuyFail(false);

    if (mode === 0) //닫기
    {
      return;
    }
    else if (mode === 1) //확인, 미션창으로 이동하기
    {
      this.handleNavi(UI_MISSION);
    }
  }

  //박스오픈연출창 닫힘
  handleBoxOpenClosed = (gotoPdBox, needTutorial) =>
  {
    this.setShowBoxOpen(false);
    
    if (gotoPdBox)
    {
      this.setCurrentTab(1);
    }
  }

  //이용자의 박스 오픈으로 보유상품 목록이 갱신되었다
  handleUserItemUpdated = (gotoPdBox) =>
  {
    this.setShowBoxOpen(false);

    if (this.state.loading)
    {
      this.needUserPdListUpdate = true; //이용자 보유상품 목록 갱신이 필요한경우 true
      return;
    }

    this.refreshUserPdList();
    
    if (gotoPdBox)
    {
      this.setCurrentTab(1);
    }
  }

  //이용자 보유상품 목록을 클리어하고 재조회함
  refreshUserPdList = () =>
  {
    this.needUserPdListUpdate = false; //이용자 보유상품 목록 갱신이 필요한경우 true

    InvenStoreInstance.clearPdList();

    if (this.state.currentTab === 1)
    {
      this.setLoading(true);
      InvenStoreInstance.loadPdList(this.onUserPdListLoaded);
    }
  }

  onUserPdListLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `보유 상품 목록 조회 중 오류가 발생했습니다.\n오류코드 ${resultCode}`, "확인", "", null);
      return;
    }
  }

  //상품 자랑하기 코멘트 전송
  handleCmtSubmit = (comment) =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(comment))
    {
      this.showToastMessage("소감을 작성해 주세요.");
      this.showCommentPop(false);
      return;
    }

    if (comment.length > 30)
    {
      this.showToastMessage("소감은 최대 30자까지 작성 가능합니다.");
      return;
    }

    this.showCommentPop(false);
    this.setLoading(true);
    InvenStoreInstance.historyComment(this.editItem.idx, comment, this.onCommentResult);
  }

  onCommentResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.showToastMessage("100팡이 지급되었습니다.");
        return;
      case 1:
        this.setShowPopup(1, '잘못된 요청입니다.', "확인", "", null);
        return;
      case 2:
        this.setShowPopup(1, '상품정보를 찾을 수 없습니다.', "확인", "", null);
        return;
      default:
        this.setShowPopup(1, '자랑하기 내용 저장에 실패 했습니다.', "확인", "", null);
        return;
    }
  }

  render()
  {
    const maxBoxCount = this.getMaxBoxCount();
    
    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showBoxBuy ? (<PangBoxBuy handleBoxBuy={this.handleBuyBox}/>) : null
        }
        {
          this.state.showBoxBuySuc ? (<PangBoxBuySuc handleBoxBuySuc={this.handleBuyBoxSuc}/>) : null
        }
        {
          this.state.showBoxBuyFailed ? (<PangBoxBuyFail handleBuyBoxFail={this.handleBuyBoxFail}/>) : null
        }
        {
          this.state.showBoxOpen ? (
            <PangBoxOpenView itemIdx={this.newBoxIdx} handleClose={this.handleBoxOpenClosed} handleUserItemUpdated={this.handleUserItemUpdated}
              mode={this.state.boxOpenMode}
              needTutorial={false}
            />
          ) : null
        }
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.showPop === 3 ? (
            <SelectMsgPop title={this.state.popTitle} msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.showCommentPop ? (<InvenCommentPop handleClose={() => this.showCommentPop(false)} handleCmtSubmit={this.handleCmtSubmit}/>) : null
        }
        {
          this.state.showDetail ? (<InvenPdDetail item={this.editItem} handleClose={() => this.showPdDetail(false)}/>) : null
        }
        {
          this.state.showToastMsg ? (<ToastMessage msg={this.state.toastMessage}/>) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 withLine={false} title="보관함" handleBack={this.handleBack}/>

        <div className='ivtabarea rowce btncur'>
          <div className='inventab colec' onClick={() => this.handleTab(0)}>
            <label className={this.state.currentTab === 0 ? 'font14 wt400 colorff btncur' : 'font14 wt400 color31 btncur'}>박스 보관함</label>
            <div className={this.state.currentTab === 0 ? 'invenline2 mgtop10' : 'invenline1 mgtop10'}></div>
          </div>

          <div className='inventab colec' onClick={() => this.handleTab(1)}>
            <label className={this.state.currentTab === 1 ? 'font14 wt400 colorff btncur' : 'font14 wt400 color31 btncur'}>배송 신청</label>
            <div className={this.state.currentTab === 1 ? 'invenline2 mgtop10' : 'invenline1 mgtop10'}></div>
          </div>
        </div>
        
        {this.state.currentTab === 0 ? <InvenBox setParentLoading={this.setLoading} setShowPopup={this.setShowPopup} handleOpenBox={this.handleOpenBox}/> : null}
        {this.state.currentTab === 1 ? <InvenPd setParentLoading={this.setLoading} setShowPopup={this.setShowPopup} handleEditItem={this.handleEditItem}/> : null}

        <div className='invenboxbuybtn rowcc btncur' onClick={this.handleBuyBoxPop}>
          <img src={GetImgUrl("bb@3x.png")} alt="팡팡박스" className='img2020 mgrt5'/>
          <label className='font14 wt400 colorw btncur mgrt5'>{`${maxBoxCount}개의 팡팡박스 구매가능`}</label>
          <img src={GetImgUrl("arrowr3@3x.png")} alt="팡팡박스" className='img1717'/>
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(Inventory));

