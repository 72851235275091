import './PangBox.css';
import '../../App.css';
import { getProductImageUrl } from '../../URL/AppUrl';
import { GetFormattedNum } from '../../Util/Util';

const PangBoxItemPd = (props) =>
{
  return (
    <div className='pbitemboxbg coltl btncur' onClick={() => props.handleClick(props.item)}>
      <div className='pbitembox rowcc'>
        <img src={getProductImageUrl(props.item.img)} alt="상품" className='pbitemimg'/>
      </div>

      <label className='font15 wt400 color31 mgtop10 btncur'>{props.item.nm}</label>
      <label className='font15 wt300 color31 mgtop5 btncur'>{`정가 ${GetFormattedNum(props.item.prc)}원`}</label>
    </div>
  );
}

export default PangBoxItemPd;