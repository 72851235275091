import React from 'react';
import './MsgPop.css';
import '../../App.css';
import { IsValidS } from '../../Util/Util';

const getMsg = (msg) =>
{
  if (msg.indexOf("\n") >= 0)
  {
    let newList = msg.split("\n");

    if (!IsValidS(newList))
      return msg;

    let result = [];

    for (let i = 0;i < newList.length; ++i)
    {
      result.push((<label key={i} className='font14 color31 weight400 lineheight16 popmsg'>{newList[i]}</label>));
    }

    return result;
  }

  return msg;
}

//내용과 버튼이 한개 있는 메시지 박스
const MsgPop = (props) =>
{
  return (
    <div className='msgpopbg flexrowcc'>
      <div className='msgpop flexcolsc'>

        <div className='popmsgArea flexcolcc font14 color31 weight400 lineheight16'>
          {getMsg(props.msg)}
        </div>
        

        <div className='msgpopbtn flexrowcc btncur' onClick={props.handleConfirm}>
          <label className='font14 weight400 colorw btncur'>{props.okBtn}</label>
        </div>
      </div>
    </div>
  );
};

export default MsgPop;