import './Inventory.css';
import '../../App.css';
import { APP_MODE, GetImgUrl } from '../../URL/AppUrl';
import { ConvertDateInfo2 } from '../../Util/Util';

const getBoxTypeName = (item) =>
{
  switch(item.tp)
  {
    case 0: //팡팡박스
      if (APP_MODE === 0)
        return `팡팡박스(${item.idx})`;
      else
        return "팡팡박스";
    case 1: //골드박스
      return "골드박스";
    default:
      return "";
  }
}

const InvenBoxItem = (props) =>
{
  return (
    <div className='invenboxitem rowsc mgbt10'>
      <div className='invenboxdesc coltl'>
        <label className='font15 color31 wt400 mgtop10 mgbt5'>{getBoxTypeName(props.item)}</label>
        <label className='font14 wt400 colorb3 mgbt5'>{`${ConvertDateInfo2(props.item.ld, ".")} 23시59분까지`}</label>
        {
          props.item.ldt <= 7 ? (
            <label className='font12 colorff wt400'>{`${props.item.ldt}일 남음`}</label>
          ) : null
        }
      </div>

      <div className='invenbxopenic rowcc btncur' onClick={() => props.handleOpenBox(props.item)}>
        <div className='invenboxvline mgrt10'></div>

        <div className='colcc'>
          <img src={GetImgUrl("bxopenic.png")} alt="박스오픈" className='img1713'/>
          <label className='font10 colorff wt400 mgtop5 btncur'>개봉하기</label>
        </div>
      </div>
    </div>
  );
}

export default InvenBoxItem;