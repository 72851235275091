import React from 'react';
import './Notice.css';
import '../../App.css';

const MoreItem = (props) =>
{
  return (
    <div className="moreitem flexrowcc btncur" onClick={props.handleClick}>
      <label className="font14 weight400 color31 btncur">더보기</label>
    </div>
  );
}

export default MoreItem;