import React from 'react';
import './Unregister.css';
import '../../App.css';
import { GetImgUrl, } from '../../URL/AppUrl';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import UnregisterRes from './UnregisterRes';
import MsgPop2 from '../Common/MsgPop2';
import MsgPop from '../Common/MsgPop';
import Loading from '../Loading/Loading';

//회원탈퇴신청팝업
class Unregister extends React.Component
{
  state = {
    loading: false,
    res: 0, //사유를 선택해 주세여(0), 더이상(1), 포인트적립수단(2), 어려움(3), 기타(4)

    check1: false,
    check2: false,

    showRes: false,
    showConfirm: false,

    showMsg: false,
    popupMsg: '',
  };

  confirmMode = 0;

  checkValid = () =>
  {
    if (this.state.res === 0 || !this.state.check1 || !this.state.check2)
      return false;

    return true;
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setRes = (v) =>
  {
    this.setState((prevState) => ({...prevState, res : v}));
  }

  setCheck1 = (v) =>
  {
    this.setState((prevState) => ({...prevState, check1 : v}));
  }

  setCheck2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, check2 : v}));
  }

  setShowRes = (v) =>
  {
    this.setState((prevState) => ({...prevState, showRes : v}));
  }

  setShowConfirm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showConfirm : v}));
  }

  setShowMsg = (v, msg) =>
  {
    this.setState((prevState) => ({...prevState, showMsg : v, popupMsg: msg}));
  }

  componentDidMount() {

  }

  componentWillUnmount = () =>
  {
  }

  getReason = () =>
  {
    switch(this.state.res)
    {
      case 0:
        return "사유를 선택해 주세요.";
      case 1:
        return "더이상 앱을 사용하고 싶지 않습니다.";
      case 2:
        return "포인트 적립 수단이 적어 이용에 불편합니다.";
      case 3:
        return "사용하기 어렵습니다.";
      case 4:
        return "기타 이유";
      default:
        return "";
    }
  }

  handleReason = (reason) =>
  {
    this.setShowRes(false);
    this.setRes(reason);
  }

  //회원탈퇴버튼이 눌림
  handleUnregister = () =>
  {
    if (this.state.res === 0)
    {
      this.setShowMsg(true, "탈퇴 사유를 선택해 주세요.");
      return;
    }

    if (!this.state.check1 || !this.state.check2)
    {
      this.setShowMsg(true, "탈퇴 확인 사항을 모두 체크해 주세요.");
      return;
    }

    this.setShowConfirm(true);
  }

  handleUnregister2 = (yes) =>
  {
    this.setShowConfirm(false);

    if (!yes || this.state.loading)
      return;

    //서버에 탈퇴를 신청한다.
    this.setLoading(true);
    LoginStoreInstance.requestUnregister(this.state.res, this.onUnRegisterResult);
  }

  onUnRegisterResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.confirmMode = 100;
        this.setShowMsg(true, '회원 탈퇴 신청이 정상 접수 되었습니다.');
        return;
      case 1:
        this.confirmMode = 0;
        this.setShowMsg(true, '잘못된 요청입니다.');
        return;
      case 3:
        this.confirmMode = 0;
        this.setShowMsg(true, '이미 탈퇴 신청이 접수된 계정입니다.');
        return;
      default:
        this.confirmMode = 0;
        this.setShowMsg(true, '회원탈퇴 접수 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.');
        return;
    }
  }

  handleMsgConfirm = () =>
  {
    this.setShowMsg(false, null);

    if (this.confirmMode === 100)
    {
      this.props.handleUnregisterDone();
    }
  }

  handleClose = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  render()
  {
    return (
      <div className='unregbg flexcoltc'>
        {
          this.state.showRes ? (
            <UnregisterRes handleClose={() => this.setShowRes(false)} handleReason={this.handleReason}/>
          ) : null
        }
        {
          this.state.showConfirm ? (
            <MsgPop2 msg="회원탈퇴를 신청할까요?" okBtn="확인" cancelBtn="취소" handleConfirm={this.handleUnregister2} />
          ) : null
        }
        {
          this.state.showMsg ? (
            <MsgPop msg={this.state.popupMsg} okBtn="확인" handleConfirm={this.handleMsgConfirm} />
          ) : null
        }
        {
          this.state.loading ? (<Loading withbg={true}/>) : null
        }
        <div className='unregin flexcoltc'>
          <div className='unregtop flexrowsc'>

            <div className='unregCloseArea flexrowsc btncur' onClick={this.handleClose}>
              <img src={GetImgUrl("back.png")} alt="닫기" className='unregclose'/>
            </div>

            <label className=''>회원탈퇴</label>

            <div className='unregCloseArea'></div>
          </div>
        </div>
        <div className='unregline'></div>

        <div className='unregin2 flexcoltl margintop20'>
          <label className='font16 colorff weight400'>잠시만요</label>

          <label className='font12 color31 weight300 margintop10'>회원 탈퇴를 하고자 하는 사유를 알려주세요.</label>

          <div className='unregsel flexrowsc margintop5 btncur' onClick={() => this.setShowRes(true)}>
            <label className={this.state.res === 0 ? 'font14 colorb3 weight300 btncur' : 'font14 color31 weight300 btncur'}>{this.getReason()}</label>
            <img src={GetImgUrl("down.png")} alt="닫기" className='unregimg1'/>
          </div>

          <label className='font14 colorff weight400 margintop20'>확인해 주세요.</label>

          <label className='font13 color31 weight300 margintop10 lineheight18'>· 머니팡 회원 탈퇴를 하시게 되면 동일한 ID로 재가입할 수 없게 되며, 탈퇴 후 계정 복구는 불가능합니다.<br/><br/>· 탈퇴한 계정의 정보는 머니팡에서 안전하게 탈퇴 처리를 진행하기위하여 신청 후 7일 경과 시점에서 탈퇴 진행이 됩니다.<br/><br/>· 탈퇴한 계정의 정보는 모두 삭제되며, 관계 법령의 규정에 의해 일정 기간 보존이 필요한 경우에는 해당 기간만큼 보유 후 삭제됩니다.<br/><br/>· 회원 탈퇴시 삭제되는 정보는 아래와 같습니다.<br/><br/>-회원의 개인정보 : 이름, 나이대, 성별, 전화번호, 이메일주소, 실명 인증 정보, 배송지<br/><br/>-회원의 앱내 컨텐츠 : 앱 알림, 팡팡박스, 보유상품, 당첨이력, 주문목록, 머니, 팡<br/><br/>· 회원 탈퇴후에도 관계법령에 의해 일정 기간 보관이 필요한 정보는 아래와 같습니다.<br/><br/>-계약 또는 청약 철회 등에 관한기록 : 전자상거래 등에서의 소비자 보호에 관한법률, 5년<br/><br/>-대금결제 및 재화 등의 공급에 관한 기록 : 전자상거래 등에서의 소비자 보호에 관한법률, 5년<br/><br/>-소비자의 불만 또는 분쟁 처리에 관한 기록 : 전자상거래 등에서의 소비자 보호에 관한법률, 5년<br/><br/>-표시/광고에 관한 기록 : 전자상거래 등에서의 소비자 보호에 관한법률, 3년<br/><br/>-세법이 규정하는 모든 거래에 관한 장부 및 증비서류 : 국세기본법, 법인세법 5년<br/><br/>-전자금융 거래에 관한 기록 : 전자금융거래법, 5년<br/><br/>-웹사이트 방문에 관한 기록 : 통신비밀보호법, 3개월</label>

          <label className='font14 colorff weight400 margintop20'>탈퇴 확인</label>

          <label className='font13 color31 weight300 margintop10 lineheight18'>회원 탈퇴 시 회원이 보유한 <b>머니와 팡팡박스, 상품은 모두 삭제</b>되며, 복구되지 않습니다.</label>

          <div className='fullwidth flexrowrc margintop10'>
            <div className={this.state.check1 ? 'unregcheck2 flexrowcc btncur' : 'unregcheck1 flexrowcc btncur'} onClick={() => this.setCheck1(!this.state.check1)}>
              <label className={this.state.check1 ? 'font12 weight400 colorw btncur' : 'font12 weight400 colorb3 btncur'}>확인하였습니다.</label>
            </div>
          </div>

          <label className='font12 color31 weight300 margintop20 lineheight18'>환급신청 진행 도중 회원 탈퇴를 하게 될 경우 <b>환급이 원활히 되지않고 소멸</b>될 수 있습니다. 환급 완료 후 탈퇴를 권장 드립니다.</label>

          <div className='fullwidth flexrowrc margintop10 mgbt100'>
            <div className={this.state.check2 ? 'unregcheck2 flexrowcc btncur' : 'unregcheck1 flexrowcc btncur'} onClick={() => this.setCheck2(!this.state.check2)}>
              <label className={this.state.check2 ? 'font12 weight400 colorw btncur' : 'font12 weight400 colorb3 btncur'}>확인하였습니다.</label>
            </div>
          </div>
        </div>
        
        <div className={this.checkValid() ? 'unregokbtn2 flexrowcc btncur' : 'unregokbtn1 flexrowcc btncur'} onClick={this.handleUnregister}>
          <label className={this.checkValid() ? 'font14 weight400 colorw btncur' : 'font1font142 weight400 colorb3 btncur'}>회원탈퇴</label>
        </div>
      </div>
    );
  }
}

export default Unregister;

