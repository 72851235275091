import './Loading.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const Loading = (props) =>
{
  return (
    <div className={props.withbg ? 'loadingbg2 flexrowcc' : 'loadingbg flexrowcc'}>
      <img src={GetImgUrl("mploading.gif")} alt="로딩중" className='loadingimg'/>
    </div>
  );
}

export default Loading;