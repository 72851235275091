import './MainPage.css';
import '../../App.css';
import { getPbAdsImageUrl } from "../../URL/AppUrl";
import React from 'react';

class AdsItem extends React.Component
{
  render()
  {
    return (
      <div className="homeadsitembg flexrowcc btncur" onClick={() => this.props.handleAdsClick(this.props.item)} ref={this.props.elRef}>
        <img src={getPbAdsImageUrl(this.props.item.img)} alt="광고" className="homeadsitemimg"/>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <AdsItem elRef={ref} {...props} />);
