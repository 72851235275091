import React from 'react';
import './Login.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetSHA256, IsValidS, IsValidV, NumberFormatString, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import MsgPop from '../Common/MsgPop';

import TopBar2 from '../Top/TopBar2';
import TimeUtil from '../../Util/TimeUtil';

//비번찾기
class LoginFindPw extends React.Component
{
  state = {
    loading: false,
    showMsg: false,
    popupMsg: '',

    step: 0, //단계, 인증문자발송(0), 비밀번호변경(1), 찾기완료(2)

    phone: '',
    userId: '',
    ac: '',

    smsSent: false,
    timeInfo: '',

    error1: '',
    error2: '',

    newPw1: '',
    newPw2: '',
  };
  
  authInfo = null;
  authCode = null;
  intervalId = -1;
  timeCatch = null;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowMsg = (v, msg) =>
  {
    this.setState((prevState) => ({...prevState, showMsg : v, popupMsg: msg}));
  }

  setPhone = (v) =>
  {
    this.setState((prevState) => ({...prevState, phone : v}));
  }

  setUserId = (v) =>
  {
    this.setState((prevState) => ({...prevState, userId : v}));
  }

  setAuthCode = (v) =>
  {
    this.setState((prevState) => ({...prevState, ac : v}));
  }

  setStep = (v) =>
  {
    this.setState((prevState) => ({...prevState, step : v}));
  }

  setSmsSent = (v) =>
  {
    this.setState((prevState) => ({...prevState, smsSent : v}));
  }

  setTimeInfo = (v) =>
  {
    this.setState((prevState) => ({...prevState, timeInfo : v}));
  }

  setError1 = (v) =>
  {
    this.setState((prevState) => ({...prevState, error1 : v}));
  }

  setError2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, error2 : v}));
  }

  setNewPw1 = (v) =>
  {
    this.setState((prevState) => ({...prevState, newPw1 : v}));
  }

  setNewPw2 = (v) =>
  {
    this.setState((prevState) => ({...prevState, newPw2 : v}));
  }

  update = () =>
  {
    if (!this.state.smsSent || !this.timeCatch)
      return;

    let seconds = 180 - Math.floor(this.timeCatch.getPassedTime2());

    if (seconds < 0)
    {
      this.setTimeInfo(`시간초과`);
      return;
    }

    let min = Math.floor(seconds / 60);

    if (min > 0)
      seconds -= min * 60;

    this.setTimeInfo(`${NumberFormatString(min)}:${NumberFormatString(seconds)}`);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  handleBack = () =>
  {
    this.props.handleClose();
  }

  handleSendSms = () =>
  {
    if (this.state.loading)
      return;

    if (this.state.smsSent)
    {
      alert(`인증번호가 발송되었습니다.\n인증 번호를 확인 후 입력해 주세요.`);
      return;
    }

    if (!IsValidS(this.state.userId))
    {
      this.setError1('이메일을 입력해 주세요.');
      return;
    }

    if (!IsValidS(this.state.phone))
    {
      this.setError2('휴대폰 번호를 입력해 주세요.');
      return;
    }

    this.setLoading(true);
    LoginStoreInstance.findAccount(1, this.state.phone, this.state.userId, this.onFindStep1);
  }

  onFindStep1 = (resultCode, authInfo, authCode) =>
  {
    this.setLoading(false);

    if (LOG_ENABLE)
      console.log(`onFindStep1 :  ${resultCode}/${authInfo}/${authCode}`);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        this.setError2('잘못된 요청입니다.');
        return;
      case 2:
        this.setError2('해당 전화번호로 가입된 계정이 없습니다.');
        return;
      case 5:
        this.setError2('당일 인증 가능한 횟수를 초과 했습니다.\n내일 이용해 주세요.');
        return;
      default:
        this.setError2(`인증 시스템 오류가 있습니다.\n잠시후 이용에 주세요.\n오류코드 ${resultCode}`);
        return;
    }

    this.authInfo = authInfo;
    this.authCode = authCode;
    this.setSmsSent(true);

    this.timeCatch = new TimeUtil();
    this.timeCatch.catch();

    this.setTimeInfo(`03:00`);
  }

  handleAuth = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.userId))
    {
      this.setError1('이메일을 입력해 주세요.');
      return;
    }

    if (!this.state.smsSent)
    {
      this.setError2('휴대폰번호 입력후 인증번호를 발송해 주세요.');
      return;
    }

    if (!IsValidS(this.state.ac) || isNaN(this.state.ac))
    {
      this.setError2('인증 번호를 입력해 주세요.');
      return;
    }

    if (this.timeCatch.getPassedTime2() > 180 && this.state.smsSent)
    {
      this.setSmsSent(false);
      this.setError2(`인증 시간이 경과되었습니다.\n인증 번호를 다시 발송해 주세요.`);
      return;
    }

    const v = GetSHA256(this.state.ac);

    if (LOG_ENABLE)
      console.log(`hash : ${v}`);

    if (v !== this.authCode)
    {
      this.setError2('인증 번호가 일치하지 않습니다.');
      return;
    }

    this.setLoading(true);
    LoginStoreInstance.findAccountAuth(1, this.state.phone, this.authInfo, this.state.ac, this.onAuthResult);
  }

  //인증결과
  onAuthResult = (resultCode, result) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        this.setError2(`잘못된 요청입니다.`);
        return;
      case 2:
        this.setError2(`인증정보가 존재하지 않습니다.`);
        return;
      case 3:
        this.setError2('인증 번호가 일치하지 않습니다.');
        return;
      case 4:
        this.setError2('인증시간이 경과 되었습니다.');
        return;
      default:
        this.setError2(`인증에 실패 하였습니다.\n오류코드 ${resultCode}`);
        return;
    }

    //인증에 성공한경우
    this.authInfo = result.ai; //비밀번호 변경을 위한 인증정보를 저장하고
    this.setStep(1);
  }

  //비밀번호 변경하기
  handleChangePw = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.newPw1))
    {
      this.setError1('변경할 비밀 번호를 입력해 주세요.');
      return;
    }

    if (this.state.newPw1.length < 8 || this.state.newPw1.length > 16)
    {
      this.setError1('비밀번호는 8 ~ 16자 범위에서 입력해 주세요.');
      return;
    }

    if (!IsValidS(this.state.newPw2))
    {
      this.setError2('비밀번호를 한번더 입력해 주세요.');
      return;
    }

    if (this.state.newPw1 !== this.state.newPw2)
    {
      this.setError2('비밀번호가 일치하지 않습니다.');
      return;
    }

    this.setError1('');
    this.setError2('');

    this.setLoading(true);
    LoginStoreInstance.changePw(0, this.authInfo, this.state.newPw1, null, this.onChangePwResult);
  }

  onChangePwResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        break;
      case 1:
        this.setShowMsg(true, `잘못된 요청입니다.`);
        return;
      case 2:
        this.setShowMsg(true, `인증정보가 올바르지 않습니다.`);
        return;
      case 3:
        this.setError1('사용할 수 없는 비밀번호 입니다.');
        break;
      default:
        this.setShowMsg(true, `비밀번호 변경이 실패 했습니다.\n오류코드 ${resultCode}`);
        return;
    }

    this.setStep(2);
  }

  isStep1Valid = () =>
  {
    return this.state.smsSent && IsValidS(this.state.ac);
  }

  isStep2Valid = () =>
  {
    return IsValidS(this.state.newPw1) && IsValidS(this.state.newPw2);
  }

  renderStep1 = () =>
  {
    return (
      <div className='loginemail flexcoltc'>
        {
          this.state.showMsg ? (
            <MsgPop msg={this.state.popupMsg} okBtn="확인" handleConfirm={() => this.setShowMsg(false)} />
          ) : null
        }
        {
          this.state.loading ? (<Loading withbg={true}/>) : null
        }
        <TopBar2 title="" handleBack={this.handleBack} withLine={true}/>

        <div className='loginin2 flexcoltl'>

          <label className='font16 weight500 color31 lgfindst'>비밀번호가 기억나지 않나요?</label>
          <label className='font12 weight300 colorb3 margintop10 lineheight18'>비밀번호를 변경할 이메일을 입력 후<br/>휴대폰 인증을 진행해 주세요.</label>

          <label className='font12 weight500 color31 margintop20'>이메일</label>
          <input type='text' className='lgeinp font14 weight300 color31 margintop5' value={this.state.userId} onChange={e => this.setUserId(e.target.value)} maxLength={45} placeholder='이메일을 입력해 주세요.'/>

          {
            IsValidS(this.state.error1) ? (
              <label className='font12 weight300 colorffc8 margintop5'>{this.state.error1}</label>
            ) : null
          }

          <label className='font12 weight500 color31 margintop20'>휴대폰 번호</label>
          <div className='fullwidth flexrowsc margintop5'>
            <input type='text' className='lgeinp2 font14 weight300 color31' value={this.state.phone} onChange={e => this.setPhone(e.target.value)} maxLength={12} placeholder='"-" 없이 번호만 입력해 주세요.'/>
            
            <button className='lgebtn2 font12 weight400 colorff btncur' onClick={this.handleSendSms}>인증번호</button>
          </div>

          {
            this.state.smsSent ? (
              <div className='lgebox2 flexrowsc margintop10'>
                <input type='text' className='lgeinp3 font14 weight300 color31' value={this.state.ac} onChange={e => this.setAuthCode(e.target.value)} maxLength={12} placeholder='인증 번호를 입력해 주세요.'/>
                <label className='font12 colorff weight400'>{this.state.timeInfo}</label>
              </div>
            ) : null
          }
          

          {
            IsValidS(this.state.error2) ? (
              <label className='font12 weight300 colorffc8 margintop5'>{this.state.error2}</label>
            ) : null
          }
        </div>
        
        <button className={this.isStep1Valid() ? 'lgebtn3 font14 colorw weight400 btncur' : 'lgebtn4 font14 colorb3 weight400 btncur'} onClick={this.handleAuth}>다음</button>
      </div>
    );
  }

  renderStep2 = () =>
  {
    return (
      <div className='loginemail flexcoltc'>
        {
          this.state.showMsg ? (
            <MsgPop msg={this.state.popupMsg} okBtn="확인" handleConfirm={() => this.setShowMsg(false)} />
          ) : null
        }
        {
          this.state.loading ? (<Loading withbg={true}/>) : null
        }
        <TopBar2 title="" handleBack={this.handleBack} withLine={true}/>

        <div className='loginin2 flexcoltl'>

          <label className='font16 weight500 color31 lgfindst'>비밀번호를 재설정 합니다.</label>
          <label className='font12 weight300 colorb3 margintop10 lineheight18'>변경하실 비밀번호를 입력해 주세요.</label>

          <label className='font12 weight500 color31 margintop20'>변경할 비밀번호</label>
          <input type='text' className='lgeinp font14 weight300 color31 margintop5' value={this.state.newPw1} onChange={e => this.setNewPw1(e.target.value)} maxLength={16} placeholder='신규 비밀번호를 입력해 주세요.'/>

          {
            IsValidS(this.state.error1) ? (
              <label className='font12 weight300 colorffc8 margintop5'>{this.state.error1}</label>
            ) : null
          }
          
          <label className='font12 weight500 color31 margintop20'>비밀번호 확인</label>
          <input type='text' className='lgeinp font14 weight300 color31 margintop5' value={this.state.newPw2} onChange={e => this.setNewPw2(e.target.value)} maxLength={16} placeholder='비밀번호 확인을 위해 한번 더 입력해 주세요.'/>

          {
            IsValidS(this.state.error2) ? (
              <label className='font12 weight300 colorffc8 margintop5'>{this.state.error2}</label>
            ) : null
          }
        </div>
        
        <button className={this.isStep2Valid() ? 'lgebtn3 font14 colorw weight400 btncur' : 'lgebtn4 font14 colorb3 weight400 btncur'} onClick={this.handleChangePw}>변경하기</button>
      </div>
    );
  }

  renderStep3 = () =>
  {
    return (
      <div className='loginemail flexcolcc'>
        <img src={GetImgUrl('ddone.png')} alt="완료" className='lgedoneimg'/>
        
        <label className='font16 weight400 color31 margintop10'>비밀번호 재설정이 완료되었습니다.</label>

        <label className='font12 weight300 colorb3 margintop10'>비밀번호 재설정이 완료되었습니다.<br/>변경한 비밀번호로 로그인해 주세요.</label>


        <button className='lgebtn3 font14 colorw weight400 btncur' onClick={this.props.handleEmailLogin}>로그인</button>
      </div>
    );
  }

  render()
  {
    if (this.state.step === 0)
      return this.renderStep1();
    if (this.state.step === 1)
      return this.renderStep2();
    else
      return this.renderStep3();
  }
}

export default LoginFindPw;

