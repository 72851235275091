import '../PangBox/PangBox.css';
import './Inventory.css';
import '../../App.css';
import { useState } from 'react';
import { IsValidS } from '../../Util/Util';

//상품 자랑하기 코멘트 작성팝업
const InvenCommentPop = (props) =>
{
  const [cmt, setCmt] = useState('');

  return (
    <div className='pbbuybg colec'>
      <div className='pbbuypop colcc'>
        <div className='pbbuypopin rowlc mgtop20'>
          <input type='text' className='ivcmtinp font14 wt400 color31' value={cmt} onChange={e => setCmt(e.target.value)} placeholder='소감은 최대 30자까지 입력 가능합니다.'/>
        </div>

        <div className='pbbuypopin colcl mgtop5'>
          <label className='font12 wt300 color31'>· 작성한 소감은 홈 화면-당첨된 상품 하단에 노출됩니다.</label>
          <label className='font12 wt300 color31 mgtop5'>· 부적절한 언어를 사용 시 운영정책에 따라 제재될 수 있습니다.</label>
        </div>

        <button className={IsValidS(cmt) ? 'ivcmtbtn2 font14 btncur wt400 colorw mgtop20' : 'ivcmtbtn1 font14 btncur wt400 colorb3 mgtop20'} onClick={() => props.handleCmtSubmit(cmt)}>작성완료</button>
      </div>
    </div>
  );
}

export default InvenCommentPop;