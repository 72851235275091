import React from 'react';
import './Login.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, LOG_ENABLE, getPrivacyUrl, getTermUrl, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetAgeStartFromRange, GetAgeStartFromRangeForKakao, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import MsgPop from '../Common/MsgPop';
import { SNSQueryInstance } from '../../Stores/SnsQuery';
import LoginEmail from './LoginEmail';
import LoginFindId from './LoginFindId';
import LoginFindPw from './LoginFindPw';
import Slider from 'react-slick';
import { UI_HOME } from '../../Stores/NaviStore';


class LoginPage extends React.Component
{
  state = {
    loading: false,

    showTerm: false,
    showPrivacy: false,
    showMsg: false,
    popupMsg: '',

    showEmailLogin: false,
    showFindId: false,
    showFindPw: false,
  };
  
  loginType = 0; //카카오(1), 네이버(2), 이메일(3)
  userGender = null;
  userAge = null;
  userName = null;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  setShowMsg = (v, msg) =>
  {
    this.setState((prevState) => ({...prevState, showMsg : v, popupMsg: msg}));
  }

  showEmailLogin = (v) =>
  {
    this.setState((prevState) => ({...prevState, showEmailLogin: v}));
  }

  showFindId = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindId: v}));
  }

  showFindPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, showFindPw: v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    //console.log(`${this.props.navigate}`);
    this.showMobileMenu(false);
    handleAppNavi(this.props.navigate, naviIndex);
  }

  componentDidMount() {

    const url = new URL(window.location.href);

    const platform = url.searchParams.get("platform");
    const code = url.searchParams.get("code");

    if (LOG_ENABLE)
      console.log(`params : ${url.searchParams.toString()}`);

    //카카오 로그인 코드가 있다면
    if (IsValidS(platform) && IsValidS(code))
    {
      if (platform === "kakao")
      {
        if (LOG_ENABLE)
          console.log(`kakao code : ${code}`);

        this.setLoading(true);
        SNSQueryInstance.kakaoAuth(code, this.onKakaoAuth);
      }
      else if (platform === "naver")
      {
        const st = url.searchParams.get("state");

        if (!IsValidS(st))
        {
          if (LOG_ENABLE)
            console.log(`no state : ${st}`);

          return;
        }

        if (LOG_ENABLE)
          console.log(`naver code : ${code}`);

        this.setLoading(true);
        SNSQueryInstance.naverAuth(code, st, this.onNaverAuth);
      }
    }
  }

  componentWillUnmount = () =>
  {
  }

  onKakaoAuth = (resultCode, authData) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !authData || !authData.profile || !IsValidV(authData.profile.id))
    {
      this.setShowMsg(true, "카카오 로그인에 실패 했습니다.");
      return;
    }

    if (IsValidS(authData.profile.gender) && authData.profile.gender !== "null")
    {
      if (authData.profile.gender === "m" || authData.profile.gender === "M" || authData.profile.gender === "MALE" || authData.profile.gender === "male")
        this.userGender = 0;
      else if (authData.profile.gender === "f" || authData.profile.gender === "F" || authData.profile.gender === "FEMALE" || authData.profile.gender === "female")
        this.userGender = 1;
    }
    else
      this.userGender = 0;

    if (IsValidS(authData.profile.ageRange) && authData.profile.ageRange !== "null")
    {
      this.userAge = GetAgeStartFromRangeForKakao(authData.profile.ageRange);
    }
    else
      this.userAge = 0;

    this.userName = this.getNameOrNickname(authData.profile.name, authData.profile.nickname);

    this.loginType = 1;
    this.setLoading(true);
    LoginStoreInstance.setUserId(`kk${authData.profile.id}`);
    LoginStoreInstance.tryLogin(1, this.onLoginResult);
  }

  getNameOrNickname = (name, nickname) =>
  {
    if (IsValidS(nickname) && nickname !== "n" && nickname !== "null")
      return nickname;
    else if (IsValidS(name) && name !== "n" && name !== "null")
      return name;

    return "n";
  }

  onNaverAuth = (resultCode, authData) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !authData || !authData.profile || !IsValidS(authData.profile.resultcode) || authData.profile.resultcode !== "00" ||
      !IsValidV(authData.profile.response) || !IsValidS(authData.profile.response.id))
    {
      this.setShowMsg(true, "네이버 로그인에 실패 했습니다.");
      return;
    }

    if (IsValidS(authData.profile.response.gender) && authData.profile.response.gender !== "null")
    {
      if (authData.profile.response.gender === "m" || authData.profile.response.gender === "M" || 
        authData.profile.response.gender === "MALE" || authData.profile.response.gender === "male")
        this.userGender = 0;
      else if (authData.profile.response.gender === "f" || authData.profile.response.gender === "F" ||
        authData.profile.response.gender === "female" || authData.profile.response.gender === "FEMALE"
      )
        this.userGender = 1;
    }
    else
      this.userGender = 0;

    if (IsValidS(authData.profile.response.age) && authData.profile.response.age !== "null")
    {
      this.userAge = GetAgeStartFromRange(authData.profile.response.age);
    }
    else
      this.userAge = 0;

    this.userName = this.getNameOrNickname(authData.profile.response.name, authData.profile.response.nickname);

    this.loginType = 2;
    this.setLoading(true);
    LoginStoreInstance.setUserId(`nv${authData.profile.response.id}`);
    LoginStoreInstance.tryLogin(2, this.onLoginResult);
  }

  onLoginResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상 로그인
        break;
      case 1: //잘못된 요청
        this.setShowMsg(true, "잘못된 요청입니다.");
        return;
      case 2: //가입된 회원이 아님
        if (this.loginType === 3) //이메일 로그인 타입이면
        {
          this.setLoading(false);
          this.setShowMsg(true, `이메일 또는 비밀번호가 올바르지 않습니다.`);  
        }
        else //간편로그인타입이면, 자동가입진행
        {
          this.registerUser(); 
        }
        return;
      case 7: //차단된 계정
        this.setShowMsg(true, "회원님의 아이디는 로그인 제한 중으로 서비스를 이용할 수 없습니다. 자세한 사항은 mpang@bravo6.kr로 문의해주시길 바랍니다.");
        return;
      case 8: //허가가 필요함
        this.setShowMsg(true, "이계정은 허가가 필요합니다.\n아직 로그인 할 수 없습니다.");
        return;
      case 10: //탈퇴신청됨
        this.setShowMsg(true, "이 계정은 탈퇴가 진행중입니다.");
        return;
      case 11: //탈퇴됨
        if (this.loginType === 3)
        {
          this.setShowMsg(true, "이 계정은 탈퇴되어 더이상 이용할 수 없습니다.");
        }
        else
        {
          this.registerUser();
        }
        return;
      default:
        this.setShowMsg(true, "로그인 진행중 오류가 발생했습니다.\n잠시 후 이용해 주세요.");
        return;
    }

    handleAppNavi(this.props.navigate, UI_HOME);
  }

  //가입되어 있지 않은 이용자를 간편 가입시키기
  registerUser = () =>
  {
    if (LOG_ENABLE)
      console.log(`registerUser`);

    LoginStoreInstance.easyLoginType = this.loginType;
    LoginStoreInstance.registerUser(null, null, this.userName, this.userGender, this.userAge, null, this.onRegisterResult);
  }

  onRegisterResult = async (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0: //정상 로그인
        handleAppNavi(this.props.navigate, UI_HOME);
        return;
      case 1:
        this.setShowMsg(true, '잘못된 요청입니다.');
        return;
      default:
        this.setShowMsg(true, `회원 가입중 오류가 발생했습니다.\n오류코드 ${resultCode}`);
        return;
    }
  }

  handleTerm = () =>
  {
    //this.showTerm(true);
    window.open(getTermUrl(), "_blank");
  }

  handlePrivacy = () =>
  {
    //this.showPrivacy(true);
    window.open(getPrivacyUrl(), "_blank");
  }

  handleKakao = () =>
  {
    if (this.state.loading)
      return;

    this.loginType = 1;
    //handleAppNavi(this.props.navigate, 1);
    SNSQueryInstance.kakaoLogin();
  }

  handleNaver = () =>
  {
    if (this.state.loading)
      return;

    this.loginType = 2;
    //handleAppNavi(this.props.navigate, 1);
    SNSQueryInstance.naverLogin();
  }

  handleEmail = () =>
  {
    if (this.state.loading)
      return;

    this.showEmailLogin(true);
    this.showFindId(false);
    this.showFindPw(false);
  }

  doEmailLogin = () =>
  {
    if (LOG_ENABLE)
      console.log('doEmailLogin');

    if (this.state.loading)
      return;

    if (!IsValidS(LoginStoreInstance.userId))
    {
      this.setShowMsg(true, '이메일 주소를 입력해 주세요.');
      return;
    }

    if (!IsValidS(LoginStoreInstance.userPw))
    {
      this.setShowMsg(true, '비밀 번호를 입력해 주세요.');
      return;
    }

    this.loginType = 3;
    this.setLoading(true);
    LoginStoreInstance.tryLogin(0, this.onLoginResult);
  }

  handleFindId = () =>
  {
    if (this.state.loading)
      return;

    this.showEmailLogin(false);
    this.showFindId(true);
    this.showFindPw(false);
  }

  handleFindPw = () =>
  {
    this.showEmailLogin(false);
    this.showFindId(false);
    this.showFindPw(true);
  }

  render()
  {
    const sliderSetting = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      variableWidth: true,
      centerMode: true,
      centerPadding: "0px",
      vertical: false,
      adaptiveHeight: true,
      useTransform: true,
      className: 'invitebgin',
      /*appendDots: (dots) => (
        <div
          style={{
            width: '100%',
            maxWidth: '500px',
            height: '100%',
            position: 'absolute',
            bottom: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: "border-box"
          }}
        >
          <ul>  {dots} </ul>
        </div>
      ),
      dotsClass: 'dots_custom'*/
    };

    return (
      <div className='loginbg flexcoltc'>
        {
          this.state.showTerm ? (
            <Term handleClose={() => this.showTerm(false)}/>
          ) : null
        }

        {
          this.state.showPrivacy ? (
            <Privacy handleClose={() => this.showPrivacy(false)}/>
          ) : null
        }
        {
          this.state.showEmailLogin ? (
            <LoginEmail handleEmailLogin={this.doEmailLogin} handleClose={() => this.showEmailLogin(false)}
              handleFindId={this.handleFindId}
              handleFindPw={this.handleFindPw}
            />
          ) : null
        }

        {
          this.state.showFindId ? (
            <LoginFindId handleClose={() => this.showFindId(false)} 
              handleFindPw={this.handleFindPw}
              handleEmailLogin={this.handleEmail}
            />
          ) : null
        }

        {
          this.state.showFindPw ? (
            <LoginFindPw handleClose={() => this.showFindPw(false)}
              handleEmailLogin={this.handleEmail}
            />
          ) : null
        }

        {
          this.state.showMsg ? (
            <MsgPop msg={this.state.popupMsg} okBtn="확인" handleConfirm={() => this.setShowMsg(false)} />
          ) : null
        }
        {
          this.state.loading ? (<Loading withbg={true}/>) : null
        }
        <div className='loginin flexcoltl'>
          
          <div className='wd100p rowcc'>
            <div className='lgslbg flexrowcc'>
              <Slider {...sliderSetting} >
                <img src={GetImgUrl("lg1.png")} className='lgslimg' alt="로그인"/>  
                <img src={GetImgUrl("lg2.png")} className='lgslimg' alt="로그인"/>  
                <img src={GetImgUrl("lg3.png")} className='lgslimg' alt="로그인"/>  
              </Slider>
            </div>
          </div>
          
          <div className='fullwidth flexcolcc btnmg'>
            {/*<img src={GetImgUrl("lg4.gif")} className='lgslimg2' alt="가입"/>  */}
            <img src={GetImgUrl("kakao.png")} className='kakao btncur menubtn' alt="카카오" onClick={this.handleKakao}/>
            <img src={GetImgUrl("naver.png")} className='kakao margintop10 btncur menubtn' alt="네이버" onClick={this.handleNaver}/>
            <img src={GetImgUrl("emaillg.png")} className='kakao margintop10 btncur menubtn' alt="네이버" onClick={this.handleEmail}/>
          </div>

          <div className='fullwidth flexcolcc btnmg2'>
            <label className='font10 weight300 colorb3'>소셜 계정을 통해 로그인시 아래의 정책에 대해 동의한 것으로 간주됩니다.</label>
          </div>
          
          <div className='fullwidth flexrowcc margintop10'>
            <label className='font10 weight400 colorb3 marginright30 btncur' onClick={this.handleTerm}>이용약관</label>
            <label className='font10 weight400 colorb3 btncur' onClick={this.handlePrivacy}>개인정보 처리방침</label>
          </div>
        </div>
        
      </div>
    );
  }
}

export default withNavigation(LoginPage);

