import React from 'react';
import './Mission.css';
import '../../App.css';
import "@egjs/react-flicking/dist/flicking.css";

class MissionSnsTab extends React.Component
{
  render()
  {
    return (
      <div className={this.props.tabIndex===this.props.selectedTab ? 'msminitab1 rowcc btncur' : 'msminitab2 rowcc btncur'} onClick={() => this.props.handleTab(this.props.tabIndex)} ref={this.props.elRef}>
        <label className={this.props.tabIndex===this.props.selectedTab ? 'font14 wt400 colorff btncur' : 'font14 wt400 colorb3 btncur'}>{this.props.title}</label>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <MissionSnsTab elRef={ref} {...props} />);