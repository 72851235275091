
import React from 'react';
import './Alarm.css';
import '../../App.css';
import { GetDataDateAndTime } from '../../Util/Util';

const AlarmItem = (props) =>
{
  return (
    <div className='alitembg flexcolcl margintop10'>
      <label className='font14 color31 weight300 lineheight18'>{props.item.dc}</label>

      <div className='fullwidth flexrowrc margintop10'>
        <label className='font12 colorb3 weight400'>{GetDataDateAndTime(props.item.rd)}</label>
      </div>

      <div className='alline margintop10'></div>
    </div>
  );
}

export default AlarmItem;