import React from 'react';
import './Notice.css';
import '../../App.css';
import { LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import { observer } from 'mobx-react';
import NoticeItem from './NoticeItem';
import { NoticeStoreInstance } from '../../Stores/NoticeStore';
import TopBar2 from '../Top/TopBar2';
import MoreItem from './MoreItem';
import NoticeView from './NoticeView';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';

class Notice extends React.Component
{
  state = {
    loading: false,
    noItem: false,
    detailItem: null,
  };
  intervalId = -1;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  setDetailItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, detailItem : v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      this.loadNotice();
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    NoticeStoreInstance.clearAll();
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadNotice();
  }

  //공지 목록읽기
  loadNotice = () =>
  {
    this.setLoading(true);
    NoticeStoreInstance.loadNoticeList(this.onNoticeLoaded);
  }

  onNoticeLoaded = (resultCode, readCount) =>
  {
    this.setLoading(false);

    if (resultCode === 0 && readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleMore = () =>
  {
    if (!this.state.loading)
      this.loadNotice();
  }

  handleSelectNotice = (item) =>
  {
    if (this.state.loading)
      return;

    this.setDetailItem(item);
  }

  render()
  {
    let items = null;

    if (IsValidS(NoticeStoreInstance.noticeList))
    {
      items = NoticeStoreInstance.noticeList.map(item => {
        return <NoticeItem key={item.idx} item={item} handleSelect={this.handleSelectNotice}/>
      });
    }
    
    return (
      <div className='ntcbg flexcoltc'>
        {
          this.state.detailItem ? (
            <NoticeView item={this.state.detailItem} handleClose={() => this.setDetailItem(null)}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>) : null
        }

        <div className='ntcmain1 flexcoltl'>
          <TopBar2 withLine={true} title="공지" handleBack={this.handleBack}/>
        </div>

        <div className='ntcempty'></div>
        
        <div className='ntcmain1 flexcoltc'>
          {items}
        </div>
        {
          !this.state.noItem ? (<MoreItem handleClick={this.handleMore}/>) : (<div className='emptyTag'></div>)
        }
      </div>
    );
  }
}

export default observer(withNavigation(Notice));

