import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, cancelAxiosRequest } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

class AddressStore {
  addressList = null; //이용자의 배송지 목록

  //비옵저버 변수
  addressReady = false;

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      addressList : observable,
      
      getItemCount: computed,
      clearAll: action,

      parseAddressListResult: action,
      parseAddResult: action,
      parseDelResult: action,
    });
  }

  clearAll = () =>
  {
    this.addressList = null;
    this.addressReady = false;
  }

  get getItemCount()
  {
    if (!IsValidS(this.addressList))
      return 0;

    return this.addressList.length;
  }

  //기본 배송지 정보를 얻음
  getDefaultAddress = () =>
  {
    if (!IsValidS(this.addressList))
      return null;

    for (let i = 0;i < this.addressList.length; ++i)
    {
      if (this.addressList[i].df !== 0)
        return this.addressList[i];
    }

    return this.addressList[0];
  }

  //배송지 목록읽기-------------------------------------------------------------------------------------------------------------

  loadAddressList = (callback) => {

    this.addressReady = false;

    axios({
      method:"POST",
      url: GetApiUrl("addr/list/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      this.parseAddressListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseAddressListResult(null, callback);
    });
  }

  parseAddressListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseAddressListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      this.addressReady = true;

      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeAddress(item);
        });

        this.addressList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeAddress = (item) =>
  {
    if (IsValidS(item.un))
      item.un = FromBase64(item.un);

    if (IsValidS(item.ad1))
      item.ad1 = FromBase64(item.ad1);

    if (IsValidS(item.ad2))
      item.ad2 = FromBase64(item.ad2);
  }
  //배송지 추가-------------------------------------------------------------------------------------------------------------

  //배송지 추가 또는 수정
  //rt : 추가(0), 수정(1)
  //aidx : 배송지 고유번호
  addDeliAddress = (rt, aidx, un, pc, ad1, ad2, ph, callback) => {
    
    const params = new URLSearchParams();

    params.append("rt", rt.toString());

    if (IsValidV(aidx))
      params.append("aidx", aidx.toString());

    params.append("un", ToBase64(un));
    params.append("pc", pc);
    params.append("ad1", ToBase64(ad1));
    params.append("ad2", ToBase64(ad2));
    params.append("ph", ph);

    if (rt === 0)
      params.append("df", IsValidS(this.addressList) ? "0" : "1");

    axios({
      method:"POST",
      url: GetApiUrl("addr/add/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseAddResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseAddResult(null, callback);
    });
  }

  parseAddResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseAddResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidV(result.info))
      {
        
        this.decodeAddress(result.info);

        if (result.rt === 0) //배송지 추가인경우
        {
          if (IsValidS(this.addressList))
          {
            this.addressList = [...this.addressList, result.info];
          }
          else
            this.addressList = [result.info];
        }
        else //배송지 수정인경우
        {
          if (IsValidS(this.addressList))
          {
            let newList = [];

            for (let i = 0;i < this.addressList.length; ++i)
            {
              if (this.addressList[i].idx !== result.info.idx)
                newList.push(this.addressList[i]);
              else
                newList.push(result.info);
            }
            this.addressList = newList;
          }
        }
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
  //배송지 삭제-------------------------------------------------------------------------------------------------------------

  delDeliAddress = (idx, callback) => {

    const params = new URLSearchParams();
    params.append("idx", idx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("addr/del/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseDelResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseDelResult(null, callback);
    });
  }

  parseDelResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseDelResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidS(this.addressList))
      {
        if (this.addressList.length === 1)
          this.addressList = null;
        else
        {
          this.addressList = this.addressList.filter(item => item.idx !== result.aidx);
        }
      }
    }
    else if (result.ret === 100)
    {
      if (this.delDeliAddressCallback)
        LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
}

const AddressStoreInstance = new AddressStore();
const AddressStoreContext = React.createContext(AddressStoreInstance);

const UseAddressStore = () => React.useContext(AddressStoreContext);

export {UseAddressStore, AddressStoreInstance};
