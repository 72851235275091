
import React from 'react';
import './Notice.css';
import '../../App.css';
import { GetDataDateAndTime } from '../../Util/Util';
import { GetImgUrl } from '../../URL/AppUrl';

const NoticeItem = (props) =>
{
  return (
    <div className='ntitembg flexcolcl margintop10 btncur' onClick={() => props.handleSelect(props.item)}>
      <div className='flexrowcc'>
        <label className='font14 color31 weight300 lineheight18 btncur'>{props.item.tt}</label>

        {
          props.item.nt ? (
            <img src={GetImgUrl("ntcnew.png")} alt="new" className='ntcnew marginleft10'/>
          ) : null
        }
      </div>

      <div className='fullwidth flexrowrc margintop10'>
        <label className='font12 colorb3 weight400 btncur'>{GetDataDateAndTime(props.item.rd)}</label>
      </div>

      <div className='ntline margintop10'></div>
    </div>
  );
}

export default NoticeItem;