import React, { createRef } from 'react';
import Slider from "react-slick";
import './MainPage.css';
import '../../App.css';
import { GetImgUrl, GotoAppStore, GotoLogin, LOG_ENABLE, getPrivacyUrl, getTermUrl, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, IsValidV, getPlatformCodeByAdsType, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';
import FooterBar from '../Footer/Footer';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import Profile from './Profile';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import BottomBar from '../Bottom/BottomBar';
import { InvenStoreInstance } from '../../Stores/InvenStore';
import { AdsStoreInstance } from '../../Stores/AdsStore';

import AdsItem from './AdsItem';
import { observer } from 'mobx-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PangStoreInstance } from '../../Stores/PangStore';
import PdItem from './PdItem';
import { CashupStoreInstance } from '../../Stores/CashupStore';
import MisItem from './MisItem';
import Winner2 from './Winner2';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { NaviStoreInstance, UI_ALARM, UI_CASHLOG, UI_HOME, UI_MISSION, UI_MYPANG, UI_NOTICE, UI_PANGBOX, UI_PANGLOG } from '../../Stores/NaviStore';
import PdItemMore from './PdItemMore';
import MpMission from './MpMission';

class MainPage extends React.Component
{
  state = {
    loading: false,

    noItem: false,

    showTerm: false,
    showPrivacy: false,
    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,
  };
  intervalId = -1;
  intervalIdForAds = -1;
  
  loading = false;
  needHistory = true; //당첨히스토리 읽기가 필요한가?
  needHomePd = true; //홈페이지 경품목록이 필요한가?
  needMission = true; //미션 목록이 필요한가?
  adsRef = createRef();
  aniChanging = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    GotoLogin();
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }

    if (!this.loading)
    {
      if (this.needHistory)
      {
        this.loadHistory();
      }
      else if (this.needMission)
      {
        this.loadHomeMissions();
      }
      else if (this.needHomePd)
      {
        this.loadWinPdList();
      }
    }
  }

  updateAds = async () =>
  {
    if (IsValidS(AdsStoreInstance.homeAdsList))
    {
      try
      {
        if (this.aniChanging)
          return;

        if (this.adsRef.current)
        {
          this.aniChanging = true;
          await this.adsRef.current.next();
          this.aniChanging = false;
        }
      }
      catch(e)
      {

      }
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 20);
    this.intervalIdForAds = setInterval(this.updateAds, 1000 * 3);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      if (!AdsStoreInstance.homeAdsReady)
      {
        this.setLoading(true);
        AdsStoreInstance.loadHomepageAds(this.onLoadHomeAds);
      }
      else
      {
        this.loadHistory();
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    if (this.intervalIdForAds !== -1)
    {
      clearInterval(this.intervalIdForAds);
      this.intervalIdForAds = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    if (!AdsStoreInstance.homeAdsReady)
    {
      this.setLoading(true);
      AdsStoreInstance.loadHomepageAds(this.onLoadHomeAds);
    }
    else
    {
      this.loadHistory();
    }
  }

  onLoadHomeAds = (resultCode) =>
  {
    this.setLoading(false);
  }

  loadHistory = () =>
  {
    this.needHistory = false;
    this.setLoading(true);
    InvenStoreInstance.loadHistoryForHome(this.onHistoryLoaded);
  }

  onHistoryLoaded = (resultCode) =>
  {
    this.setLoading(false);
  }

  //당첨 가능 상품 목록조회
  loadWinPdList = () =>
  {
    this.needHomePd = false;
    this.setLoading(true);
    PangStoreInstance.loadWinPdListForHome(this.onWinPdLoaded);
  }

  onWinPdLoaded = (resultCode) =>
  {
    if (LOG_ENABLE)
      console.log(`winpd list loaded : ${resultCode}`);

    this.setLoading(false);
  }

  loadHomeMissions = () =>
  {
    this.needMission = false;
    this.setLoading(true);
    CashupStoreInstance.loadAdsForMission(this.onMissionLoaded);
  }

  onMissionLoaded = (resultCode) =>
  {
    this.setLoading(false);
  }

  handleTerm = () =>
  {
    //this.showTerm(true);
    window.open(getTermUrl(), "_blank");
  }

  handlePrivacy = () =>
  {
    //this.showPrivacy(true);
    window.open(getPrivacyUrl(), "_blank");
  }

  
  handleAlarm = () =>
  {
    //this.setShowPopup(2, "알림은 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_HOME);
    this.handleNavi(UI_ALARM);
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }

  handleCenterMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 4: //친구초대
        //this.setShowPopup(2, "친구초대는 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        NaviStoreInstance.targetTab = 6;
        NaviStoreInstance.setUiFrom(UI_HOME);
        this.handleNavi(UI_MISSION);
        return;
      case 6: //적립소
        //this.setShowPopup(2, "적립소는 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        NaviStoreInstance.targetTab = 5;
        NaviStoreInstance.setUiFrom(UI_HOME);
        this.handleNavi(UI_MISSION);
        return;
      default:
        return;
    }
  }

  handleBottomMenu = (menuIndex) =>
  {
    switch(menuIndex)
    {
      case 0:
        return;
      case 2:
        //this.setShowPopup(2, "머니쇼핑은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        this.handleNavi(UI_PANGBOX);
        return;
      case 3:
        this.handleNavi(UI_MYPANG);
        return;
      case 1:
      case 5:
        //this.setShowPopup(2, "미션 적립은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        this.handleNavi(UI_MISSION);
        return;
      default:
        return;
    }
  }

  //상단 캐로셀 광고 영역 눌림
  handleAdsClick = (item) =>
  {
    if (LOG_ENABLE)
      console.log(`handleAdsClick : ${JSON.stringify(item)}`);

    if (this.state.loading)
      return;

    switch(item.at)
    {
      case 0: //액션없음
        return;
      case 1: //친구초대 페이지
        NaviStoreInstance.setUiFrom(UI_HOME);
        NaviStoreInstance.targetTab = 6;
        this.handleNavi(UI_MISSION);
        return;
      case 2: //머니쇼핑
        return;
      case 3: //미션
        NaviStoreInstance.setUiFrom(UI_HOME);
        NaviStoreInstance.targetTab = -1;
        this.handleNavi(UI_MISSION);
        return;
      case 4: //사이트
        window.open(item.lk, "_blank");
        return;
      case 5: //공지
        NaviStoreInstance.setUiFrom(UI_HOME);
        this.handleNavi(UI_NOTICE);
        return;
      case 6: //팡팡박스 당첨내역
        return;
      case 7: //빙고팡팡
        NaviStoreInstance.setUiFrom(UI_HOME);
        NaviStoreInstance.targetTab = 0;
        this.handleNavi(UI_MISSION);
        return;
    }
  }

  //mode : 머니팡자체미션(0), 광고미션(1)
  handleMissionClick = (mode, item) =>
  {
    //this.setShowPopup(2, "미션 참여는 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    if (this.state.loading)
      return;
    
    if (mode === 0)
    {
      this.setShowPopup(2, "미션 참여는 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    }
    else
    {
      switch(getPlatformCodeByAdsType(item.adt))
      {
        case 0: //인스타
          NaviStoreInstance.targetTab = 1;
          break;
        case 1: //페북
          NaviStoreInstance.targetTab = 2;
          break;
        case 2: //유튜브
          NaviStoreInstance.targetTab = 3;
          break;
        case 3: //틱톡
          NaviStoreInstance.targetTab = 4;
          break;
        default:
          return;
      }
  
      NaviStoreInstance.setUiFrom(UI_HOME);
      this.handleNavi(UI_MISSION);
    }
  }

  //인스타, 페이스북, 유튜브, 틱톡 관련 미션
  handleAdsMissionClick = (mode, adt) =>
  {
    //this.setShowPopup(2, "미션 참여는 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    if (this.state.loading)
      return;
    
    switch(getPlatformCodeByAdsType(adt))
    {
      case 0: //인스타
        NaviStoreInstance.targetTab = 1;
        break;
      case 1: //페북
        NaviStoreInstance.targetTab = 2;
        break;
      case 2: //유튜브
        NaviStoreInstance.targetTab = 3;
        break;
      case 3: //틱톡
        NaviStoreInstance.targetTab = 4;
        break;
      default:
        return;
    }

    NaviStoreInstance.setUiFrom(UI_HOME);
    this.handleNavi(UI_MISSION);
  }

  handleCashLog = () =>
  {
    if (this.state.loading)
    {
      if (LOG_ENABLE)
        console.log(`loading...`);

      return;
    }

    NaviStoreInstance.setUiFrom(UI_HOME);
    this.handleNavi(UI_CASHLOG);
  }

  handlePangLog = () =>
  {
    if (this.state.loading)
    {
      if (LOG_ENABLE)
        console.log(`loading...`);

      return;
    }

    NaviStoreInstance.setUiFrom(UI_HOME);
    this.handleNavi(UI_PANGLOG);
  }

  handleBingo = () =>
  {
    if (this.state.loading)
      return;
    
    NaviStoreInstance.targetTab = 0;
    NaviStoreInstance.setUiFrom(UI_HOME);
    this.handleNavi(UI_MISSION);
  }

  render()
  {
    let homeMissions = null;
    let winPds = null;
    let winners = null;
    let ads = null;

    if (IsValidS(PangStoreInstance.homeItems))
    {
      winPds = PangStoreInstance.homeItems.map(item => {
        return <PdItem key={item.idx} item={item} />
      });

      winPds.push(<PdItemMore key={-1} handleClick={() => this.handleBottomMenu(2)} />);
    }

    if (IsValidS(InvenStoreInstance.hsListForHome))
    {
      winners = InvenStoreInstance.hsListForHome.map(item => {
        return <Winner2 key={item.idx} item={item} />
      });
    }

    if (IsValidS(AdsStoreInstance.homeAdsList))
    {
      ads = AdsStoreInstance.homeAdsList.map(item => {
        return <AdsItem key={item.idx} item={item} handleAdsClick={this.handleAdsClick} />
      });
    }

    /*
    if (IsValidS(CashupStoreInstance.homeMissions))
    {
      homeMissions = CashupStoreInstance.homeMissions.map(item => {
        return <MisItem key={item.aidx} item={item} handleMissionClick={this.handleMissionClick} />
      });
    }*/

    const sliderSetting2 = {
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      variableWidth: true,
      centerMode: false,
      centerPadding: "0px",
      vertical: false,
      adaptiveHeight: true,
      useTransform: true,
      className: 'mnwinpdareain',
    };

    return (
      <div className='mainbgcont flexcoltc'>
        <div className='mainbg flexcoltc'>
          {
            this.state.showTerm ? (
              <Term handleClose={() => this.showTerm(false)}/>
            ) : null
          }

          {
            this.state.showPrivacy ? (
              <Privacy handleClose={() => this.showPrivacy(false)}/>
            ) : null
          }
          {
            this.state.showPop === 1 ? (
              <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
            ) : null
          }

          {
            this.state.showPop === 2 ? (
              <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
            ) : null
          }
          {
            this.state.loading ? (<Loading />) : null
          }
          <div className='main1 flexcoltl'>
            <TopBar withLine={true} handleAlarm={this.handleAlarm} handleHome={() => this.handleBottomMenu(0)}/>
          </div>

          <div className='main2 flexcoltl'>
            <Profile handleCashLog={this.handleCashLog} handlePangLog={this.handlePangLog}/>
          </div>

          <div className='invitebg flexrowcc mgtop10'>
            <Flicking
              viewportTag="div"
              cameraTag="div"
              cameraClass=""
              renderOnSameKey={false}
              align="center"
              onMove={(e) => {}}
              onWillChange={(e) => {}}
              horizontal={true}
              circular={true}
              interruptable={true}
              preventDefaultOnDrag={true}
              ref={this.adsRef}
            >
              {ads}
            </Flicking>
          </div>
          
          {/*빙고영역*/}
          <div className='wd88p coltl mgtop30'>
            <label className='font18 weight400 color31'>빙고를 돌려서 행운을 만나보세요</label>
          </div>

          {/*빙고보드광고판*/}
          <div className='mnbingobd rowcc mgtop5 btncur' onClick={this.handleBingo}>
            <img src={GetImgUrl("homebingo.jpg")} alt="미션" className='mnbingoimg'/>
          </div>

          {/*당첨자명단 */}
          {
            IsValidS(winners) ? (
              <div className='mnwinnerbg rowsc flexnowrap mgtop10'>
                <div className='rowcc wnslide wnoriginal'>
                  {winners}
                </div>
                
                <div className='rowcc wnslide wnclone'>
                  {winners}
                </div>
              </div>
            ) : null
          }

          {/*미션영역*/}
          <div className='wd88p coltl mgtop40'>
            <label className='font18 weight400 color31'>미션을 수행하고 머니를 적립하세요</label>
          </div>

          <div className='wd88p coltc mgtop5'>
            {
              IsValidV(CashupStoreInstance.missionForHome) ? (<MpMission key={-1} item={CashupStoreInstance.missionForHome} handleMissionClick={this.handleMissionClick}/>) : null
            }
            {/*homeMissions*/}
            <MisItem adt={1} pnt={80} handleMissionClick={this.handleAdsMissionClick} />
            <MisItem adt={21} pnt={80} handleMissionClick={this.handleAdsMissionClick} />
          </div>

          {/*기타영역*/}
          <div className='wd88p coltl mgtop40'>
            <label className='font18 weight400 color31'>더 많은 머니 벌기</label>
          </div>

          <div className='wd88p rowsc mgtop10'>
            <div className='mnbigbtn colevenc bgcolor49'>
              <img src={GetImgUrl("mis1@3x.png")} alt="미션" className='mnbigbtnimg'/>
              <label className='font14 weight400 color31'>다양한 미션을 통해<br/>머니를 지급하는 적립소</label>

              <button className='mnbigbtn2 font15 wt500 color49 btncur' onClick={() => this.handleCenterMenu(6)}>머니 받기</button>
            </div>

            <div className='mnbigbtn colevenc bgcolorff7d'>
              <img src={GetImgUrl("mis2@3x.png")} alt="초대" className='mnbigbtnimg'/>
              <label className='font14 weight400 color31'>친구를 초대하면<br/>최대 40,000머니 지급</label>

              <button className='mnbigbtn2 font15 wt500 colorff7d btncur' onClick={() => this.handleCenterMenu(4)}>머니 받기</button>
            </div>
          </div>

          {/*경품영역*/}
          <div className='wd88p flexcoltl mgbt20 mgtop40'>
            <label className='font18 weight400 color31'>경품이 팡팡! 랜덤박스 개봉하기</label>
            <label className='font14 weight400 colorb3 mgtop5'>꽝 없는 팡팡박스로 행운을 잡아보세요</label>
            
            <div className='mnwinpdarea rowcc'>
              <Slider {...sliderSetting2} >
                {winPds}
              </Slider>
            </div>

          </div>
          <FooterBar handleTerm={this.handleTerm} handlePrivacy={this.handlePrivacy}/>

          <BottomBar selected={0} handleMenu={this.handleBottomMenu}/>

        </div>
      </div>
    );
  }
}

export default observer(withNavigation(MainPage));

