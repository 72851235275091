import React from 'react';
import './Notice.css';
import '../../App.css';
import { GetCpNoticeImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetDataDateAndTime, IsValidS, getShortedPathName, } from '../../Util/Util';
import Loading from '../Loading/Loading';

import TopBar2 from '../Top/TopBar2';


class NoticeView extends React.Component
{
  state = {
  };

  componentDidMount() {
  }

  componentWillUnmount = () =>
  {
  }

  render()
  {
    return (
      <div className='ntcview flexcoltc'>

        <TopBar2 withLine={true} title={getShortedPathName(this.props.item.tt, 10)} handleBack={this.props.handleClose}/>
                
        <div className='ntcview1 flexcoltl'>
          <label className='font14 color31 weight400 margintop30'>{this.props.item.tt}</label>

          <label className='font12 colorb3 weight400 margintop10'>{GetDataDateAndTime(this.props.item.rd)}</label>

          <label className='font12 color31 weight300 margintop15'>{this.props.item.dc}</label>

          {
            IsValidS(this.props.item.img) && this.props.item.img !== "n" ? (
              <img src={GetCpNoticeImgUrl(this.props.item.img)} alt="공지" className='ntcimg'/>
            ) : null
          }
        </div>

        <div className='emptyTag'></div>
      </div>
    );
  }
}

export default NoticeView;

