import './MainPage.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { GetAdsMissionDesc, GetAdsMissionNameLong } from '../../Util/Util';

const getAdsImgName = (adt) =>
{
  switch(adt)
  {
    case 0: //인스타
    case 1: //인스타
    case 2: //인스타
    case 3: //인스타
      return GetImgUrl("hins@3x.png");
    case 10: //페북
    case 11: //페북
    case 12: //페북
    case 13: //페북
    case 14: //페북
      return GetImgUrl("hfb@3x.png");
    case 20: //유튜브
    case 21: //유튜브
    case 22: //유튜브
    case 23: //유튜브
      return GetImgUrl("hytn@3x.png");
    case 30: //틱톡
    case 31: //틱톡
    case 32: //틱톡
    case 33: //틱톡
    default:
      return GetImgUrl("htt@3x.png");
  }
}

const MisItem = (props) =>
{
  return (
    <div className='mnmisbg rowsc btncur' onClick={() => props.handleMissionClick(1, props.adt)}>

      <div className='mnmisimgbg rowcc'>
        <img src={getAdsImgName(props.adt)} alt="미션" className='mnmisimg' />
      </div>

      <div className='mnmisdesc colcl'>
        <label className='font16 wt400 color31 btncur'>{GetAdsMissionNameLong(props.adt)}</label>
        <label className='font14 wt400 colorb3 btncur'>{GetAdsMissionDesc(props.adt, props.pnt)}</label>
      </div>

      <div className='mnmisbtn rowcc btncur' onClick={() => props.handleMissionClick(1, props.adt)}>
        <img src={GetImgUrl("cash3@3x.png")} alt="미션" className='cashimg mgrt5' />
        <label className='font16 wt400 color31 btncur'>{props.pnt}</label>
      </div>
    </div>
  );
}

export default MisItem;