import './PangBox.css';
import '../../App.css';

//팡팡박스 구매 팝업
const PangBoxBuy = (props) =>
{
  return (
    <div className='pbbuybg colec'>
      <div className='pbbuypop colcc'>
        <div className='pbbuypopin rowlc mgtop20'>
          <label className='font14 wt400 colorff'>팡팡박스 구매하기</label>
        </div>

        <div className='pbbuypopin rowls mgtop5'>
          <label className='font12 wt300 color31 pbbuypopintext1'>·</label>
          <label className='font12 wt300 color31'>팡팡박스는 팡으로만 구매 가능하며, 부족한 팡은 자동으로 머니에서 전환됩니다.</label>
        </div>

        <div className='pbbuypopin rowls mgtop5'>
          <label className='font12 wt300 color31 pbbuypopintext1'>·</label>
          <label className='font12 wt300 color31'>팡팡박스 구매 시 보관함에 보관되며, 구매 취소 또는 환불이 불가능합니다.</label>
        </div>

        <div className='pbbuypopin rowls mgtop5'>
          <label className='font12 wt300 color31 pbbuypopintext1'>·</label>
          <label className='font12 wt300 color31'>박스를 개봉하면 무작위로 하나의 상품이 획득됩니다.</label>
        </div>

        <div className='pbbuypopin rowrc mgtop10'>
          <label className='font16 wt400 color31'>1개 1,000팡</label>
        </div>

        <div className='pbbuypopin rowrc mgbt20'>
          <label className='font14 wt400 colorb3 mgtop2'>(1개당 2,000머니)</label>
        </div>

        <div className='wd100p rowcc'>
          <button className='pbbuybtn1 font14 btncur wt400 color31' onClick={() => props.handleBoxBuy(0)}>닫기</button>
          <button className='pbbuybtn2 font14 btncur wt400 colorw' onClick={() => props.handleBoxBuy(1)}>구매하기</button>
        </div>
      </div>
    </div>
  );
}

export default PangBoxBuy;