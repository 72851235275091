import React from 'react';
import './Mission.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import "@egjs/react-flicking/dist/flicking.css";

class MissionTab extends React.Component
{
  getTabIcon = (tabIndex, selectedTab) =>
  {
    switch(tabIndex)
    {
      case 0: //빙고팡팡
        return GetImgUrl(tabIndex !== selectedTab ? "cutab1@3x.png" : "cutab1en@3x.png");
      case 1: //인스타
        return GetImgUrl(tabIndex !== selectedTab ? "cutab2@3x.png" : "cutab2en@3x.png");
      case 2: //페이스북
        return GetImgUrl(tabIndex !== selectedTab ? "cutab3@3x.png" : "cutab3en@3x.png");
      case 3: //유튜브
        return GetImgUrl(tabIndex !== selectedTab ? "cutab4@3x.png" : "cutab4en@3x.png");
      case 4: //틱톡
        return GetImgUrl(tabIndex !== selectedTab ? "cutab5@3x.png" : "cutab5en@3x.png");
      case 5: //적립소
        return GetImgUrl(tabIndex !== selectedTab ? "cutab6@3x.png" : "cutab6en@3x.png");
      case 6: //친구초대
      default:
        return GetImgUrl(tabIndex !== selectedTab ? "cutab7@3x.png" : "cutab7en@3x.png");
    }
  }

  render()
  {
    return (
      <div className='mstabitem coltc btncur' onClick={() => this.props.handleTab(this.props.tabIndex)} ref={this.props.elRef}>
        <img src={this.getTabIcon(this.props.tabIndex, this.props.selectedTab)} alt="메뉴" className='img4040'/>
        <label className='font14 wt400 color31 btncur mgtop5'>{this.props.title}</label>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <MissionTab elRef={ref} {...props} />);