import './Mission.css';
import '../../App.css';
import { GetImgUrl, getAdsImageUrl } from '../../URL/AppUrl';
import { GetAdsTypeSimple } from '../../Util/Util';

const MissionSnsAds = (props) =>
{
  return (
    <div className='mssnsadsimgcont colcc btncur mgbt15' onClick={() => props.handleSnsMission(1, props.snsType, props.item)}>
      <div className='mssnsadsimgbg rowcc'>
        <img src={getAdsImageUrl(props.item.img)} alt="미션" className='mssnsadsimg' />
      </div>

      <div className='wd100p rowsc'>
        <label className='font11 wt300 color31'>{GetAdsTypeSimple(props.item.adt)}</label>

        <div className='rowcc'>
          <img src={GetImgUrl("mscn@3x.png")} alt="보상" className='img1010 mgrt2'/>
          <label className='font14 wt400 color31'>{props.item.pnt * 2}</label>
        </div>
      </div>
    </div>
  );
}

export default MissionSnsAds;