import React from 'react';
import './Mission.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import "@egjs/react-flicking/dist/flicking.css";
import { SnsStoreInstance } from '../../Stores/SnsStore';
import { IsValidS, IsValidV } from '../../Util/Util';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import MissionSnsTab from './MissionSnsTab';
import { CashupStoreInstance } from '../../Stores/CashupStore';
import MissionSnsItem from './MissionSnsItem';

//sns미션 뷰어
class MissionSns extends React.Component
{
  state = {
    loading: false,
    selectedTab: 0,
  }

  selectedTab = 0;
  loading = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setSelectedTab = (v) =>
  {
    this.selectedTab = v;
    this.setState((prevState) => ({...prevState, selectedTab : v}));
  }

  getAdsTypeCodeBase = () =>
  {
    switch(this.props.snsType)
    {
      case 0: //인스타
        return 0;
      case 1: //페이스북
        return 10;
      case 2: //유튜브
        return 20;
      case 3: //틱톡
        return 30;
      default:
        return 0;
    }
  }

  getAdsTypeCode = (ac) =>
  {
    switch(ac)
    {
      case 1: //팔로우
        return this.getAdsTypeCodeBase() + 1;
      case 2: //게시물좋아요
        return this.getAdsTypeCodeBase();
      case 3: //게시물 댓글
        return this.getAdsTypeCodeBase() + 2;
      case 4: //페이스북 페이지 팔로우
        return 14;
      default:
        return -1;
    }
  }

  componentDidMount()
  {
    this.currentAdsType = this.getAdsTypeCode(0);
    this.refreshAds();
  }

  componentWillUnmount()
  {
    CashupStoreInstance.clearAdsList(this.props.snsType);
  }

  onLoadAdsList = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.props.setShowPopup(1, "미션 목록 조회 중 오류가 발생했습니다.", "확인", "", null);
      return;
    }
  }

  refreshAds = () =>
  {
    CashupStoreInstance.clearAdsList(this.props.snsType);
    this.setLoading(true);
    CashupStoreInstance.loadAds(this.props.snsType, this.currentAdsType, this.onLoadAdsList);
  }

  getSnsIcon = () =>
  {
    switch(this.props.snsType)
    {
      case 0:
        return GetImgUrl("clins@3x.png");
      case 1:
        return GetImgUrl("clfb@3x.png");
      case 2:
        return GetImgUrl("clyt@3x.png");
      case 3:
        return GetImgUrl("cltt@3x.png");
      default:
    }
  }

  getSnsAccountName = () =>
  {
    const snsInfo = SnsStoreInstance.getSnsAccount(this.props.snsType);
    
    if (!snsInfo)
    {
      switch(this.props.snsType)
      {
        case 0:
          return "인스타그램 계정을 연결 후 이용해 주세요.";
        case 1:
          return "페이스북 계정을 연결 후 이용해 주세요.";
        case 2:
          return "유튜브 계정을 연결 후 이용해 주세요.";
        case 3:
          return "틱톡 계정을 연결 후 이용해 주세요.";
        default:
          return "";
      }
    }
    else
    {
      if (!IsValidV(snsInfo))
        return "";
    
      let name = null;
    
      if (IsValidS(snsInfo.nm) && snsInfo.nm !== "n")
        name = snsInfo.nm;
      else
        name = snsInfo.un;
    
      if (snsInfo.st === 2)
        name += "(정지됨)";
    
      return name;
    }
  }

  handleTab = (tabIndex) =>
  {
    if (this.state.loading)
      return;

    if (tabIndex === this.state.selectedTab)
      return;

    this.currentAdsType = this.getAdsTypeCode(tabIndex);
    this.setSelectedTab(tabIndex);

    this.refreshAds();
  }

  renderInstaTab = () =>
  {
    return (
      <div className='wd100p rowlc mgtop20'>
        <Flicking
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          align="prev"
          onMove={(e) => {}}
          onWillChange={(e) => {}}
          horizontal={true}
          circular={false}
          interruptable={true}
          preventDefaultOnDrag={true}
          bound={true}
          panelsPerView={-1}
          moveType="strict"
          resizeOnContentsReady={false}
          autoResize={true}
          autoInit={true}
          threshold={100}
          //ref={this.tabRef}
        >
          <MissionSnsTab tabIndex={0} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="전체" />
          <MissionSnsTab tabIndex={1} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="팔로우" />
          <MissionSnsTab tabIndex={2} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="좋아요" />
          <MissionSnsTab tabIndex={3} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="댓글" />
        </Flicking>
      </div>
    );
  }

  renderFbTab = () =>
  {
    return (
      <div className='wd100p rowlc mgtop20'>
        <Flicking
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          align="prev"
          onMove={(e) => {}}
          onWillChange={(e) => {}}
          horizontal={true}
          circular={false}
          interruptable={true}
          preventDefaultOnDrag={true}
          bound={true}
          panelsPerView={-1}
          moveType="strict"
          resizeOnContentsReady={false}
          autoResize={true}
          autoInit={true}
          threshold={100}
          //ref={this.tabRef}
        >
          <MissionSnsTab tabIndex={0} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="전체" />
          <MissionSnsTab tabIndex={1} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="프로필 팔로우" />
          <MissionSnsTab tabIndex={4} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="페이지 팔로우" />
          <MissionSnsTab tabIndex={2} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="좋아요" />
          <MissionSnsTab tabIndex={3} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="댓글" />
          
        </Flicking>
      </div>
    );
  }

  renderYtTab = () =>
  {
    return (
      <div className='wd100p rowlc mgtop20'>
        <Flicking
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          align="prev"
          onMove={(e) => {}}
          onWillChange={(e) => {}}
          horizontal={true}
          circular={false}
          interruptable={true}
          preventDefaultOnDrag={true}
          bound={true}
          panelsPerView={-1}
          moveType="strict"
          resizeOnContentsReady={false}
          autoResize={true}
          autoInit={true}
          threshold={100}
          //ref={this.tabRef}
        >
          <MissionSnsTab tabIndex={0} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="전체" />
          <MissionSnsTab tabIndex={1} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="구독" />
          <MissionSnsTab tabIndex={2} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="좋아요" />
          <MissionSnsTab tabIndex={3} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="댓글" />
          
        </Flicking>
      </div>
    );
  }

  renderSnsTab = () =>
  {
    switch(this.props.snsType)
    {
      case 0:
        return this.renderInstaTab();
      case 1:
        return this.renderFbTab();
      case 2:
        return this.renderYtTab();
      case 3:
        return this.renderInstaTab();
      default:
        return null;
    }
  }

  handleRefresh = () =>
  {
    if (this.state.loading)
      return;

    this.refreshAds();
  }

  render()
  {
    let adsList = null;

    const ads = CashupStoreInstance.getAdsList(this.props.snsType);

    if (IsValidS(ads))
    {
      adsList = ads.map(item => <MissionSnsItem key={item.idx} item={item} snsType={this.props.snsType} handleSnsMission={this.props.handleSnsMission}/>)
    }

    return (
      <div className='mssnsarea coltl mgtop15'>
        <div className='mssnsacc rowlc btncur' onClick={() => this.props.handleSnsMission(0, this.props.snsType, null)}>
          <img src={this.getSnsIcon()} alt="sns" className='img1515 mglt10'/>
          <label className='font15 wt400 color31 mglt5 btncur'>{this.getSnsAccountName()}</label>
        </div>

        {/*this.renderSnsTab()*/}

        <div className='wd100p coltl mgtop10'>
          {adsList}
        </div>

        <div className='wd100p rowrc mgbt100 mgtop5'>
          <div className='msrefbtn rowcc btncur' onClick={this.handleRefresh}>
            <img src={GetImgUrl("ref@3x.png")} alt="새로고침" className='img1110'/>
            <label className='font14 wt400 colorb3 btncur'>&nbsp;새로고침</label>
          </div>
        </div>
      </div>
    );
  }
}

export default MissionSns;