import { GetImgUrl, mobileOn } from "../../URL/AppUrl";
import './Footer.css';
import '../../App.css';
const FooterBar = (props) =>
{
  return (
    <div className="ft_cont flexrowcc">
      <div className="ft_cont2_mb flexcoltl">
      
        <div className="flexrowcc margintop40">
          <label className="font12 colorb3 marginright15 btncur menutext" onClick={props.handleTerm}>이용약관</label>
          <label className="font12 colorb3 btncur menutext" onClick={props.handlePrivacy}>개인정보취급방침</label>
        </div>

        <div className="fullwidth flexrowse">
          <label className="font10 colorb3 lineheight14 margintop20">주식회사 브라보식스<br/>부산광역시 연제구 월드컵대로 160-1, 3층<br/>통신 판매업신고 2022-부산수영-0236<br/>대표 조용완 · 사업자번호 675-86-02368</label>

          <img src={GetImgUrl("logo3.png")} className='logo3' alt="머니팡"/>
        </div>
        
        
      </div>
    </div>
  );
}

export default FooterBar;