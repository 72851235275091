import { GetImgUrl } from "../../URL/AppUrl";


import './TopBar.css';
import '../../App.css';

const TopBar2 = (props) =>
{
  return (
    <div className="top_cont flexcolcc">
      <div className="top_bar_mb flexrowsc">
        
        <div className="tbs flexrowsc btncur" onClick={props.handleBack}>
          <img src={GetImgUrl("back2.png")} alt="뒤로가기" className='topback'/>
        </div>
        
        <div className="tbc flexrowcc">
          <label className="font12 weight400 color31 textcenter">{props.title}</label>
        </div>

        <div className="tbs">

        </div>
      </div>
      {
        props.withLine ? (<div className="topbarline"></div>) : null
      }
    </div>
  );
};

export default TopBar2;