import React, { createRef } from 'react';

import './Mission.css';
import '../../App.css';
import { GetImgUrl, GotoAppStore, GotoLogin, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetFormattedNum, IsValidS, IsValidV, getProfileImage, getShortedPathName, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';

import { LoginStoreInstance } from '../../Stores/LoginStore';

import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import BottomBar from '../Bottom/BottomBar';

import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_ALARM, UI_CASHLOG, UI_HOME, UI_MISSION, UI_MYPANG, UI_PANGBOX, UI_SNSACC } from '../../Stores/NaviStore';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import MissionTab from './MissionTab';
import MissionSns from './MissionSns';
import { SnsStoreInstance } from '../../Stores/SnsStore';
import MissionGetCash from './MissionGetCash';
import MissionInvite from './MissionInvite';
import MissionBingo from './MissionBingo';
import { BingoGameStoreInstance } from '../../Stores/BingoGameStore';
import BingoGameView from './BingoGameView';
import ToastMessage from '../Common/ToastMessage';

//미션페이지
class Mission extends React.Component
{
  state = {
    loading: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    selectedTab: 0,

    showBingoGame: 0,
    bingoUrl: '',

    showToastMsg: false,
    toastMessage: '토스트 메시지',
  };

  intervalId = -1;
  loading = false;
  tabRef = createRef();
  needSnsAcc = false;
  bingoGameNum = 0;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setSelectedTab = (v) =>
  {
    this.setState((prevState) => ({...prevState, selectedTab : v}));
  }

  showBingoGame = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBingoGame : v}));
  }

  setBingoGameUrl = (v) =>
  {
    this.setState((prevState) => ({...prevState, bingoUrl : v}));
  }

  showToastMessage = (msg) =>
  {
    this.setState((prevState) => ({...prevState, showToastMsg : true, toastMessage: msg}));
    setTimeout(this.hideToastMessage, 2000);
  }

  hideToastMessage = () =>
  {
    this.setState((prevState) => ({...prevState, showToastMsg : false}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    GotoLogin();
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }

    if (!this.loading)
    {
      if (this.needSnsAcc)
      {
        this.needSnsAcc = false;
        this.setLoading(true);
        SnsStoreInstance.loadSnsAccount(this.onLoadSnsAcc);
      }
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 20);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      this.needSnsAcc = true;

      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      let load = false;

      if (!SnsStoreInstance.accountReady)
      {
        load = true;
        this.setLoading(true);
        SnsStoreInstance.loadSnsAccount(this.onLoadSnsAcc);
      }
    }

    if (NaviStoreInstance.targetTab >= 0 && NaviStoreInstance.targetTab <= 6)
    {
      this.setSelectedTab(NaviStoreInstance.targetTab);
      NaviStoreInstance.targetTab = -1;
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

  }

  onLoadSnsAcc = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, "SNS계정 목록 조회 중 오류가 발생했습니다.", "확인", "", null);
    }
  }

  handleAlarm = () =>
  {
    //this.setShowPopup(2, "알림은 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_MYPANG);
    this.handleNavi(UI_ALARM);
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }

  handleBottomMenu = (menuIndex) =>
  {
    switch(menuIndex)
    {
      case 0:
        this.handleNavi(UI_HOME);
        return;
      case 2:
        //this.setShowPopup(2, "머니쇼핑은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        this.handleNavi(UI_PANGBOX);
        return;
      case 3:
        this.handleNavi(UI_MYPANG);
        return;
      case 1:
      case 5:
        //this.setShowPopup(2, "미션 적립은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        return;
      default:
        return;
    }
  }

  handleCashLog = () =>
  {
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_MISSION);
    this.handleNavi(UI_CASHLOG);
  }

  handleTab = (tabIndex) =>
  {
    if (this.state.loading || tabIndex === this.state.selectedTab)
      return;

    this.setSelectedTab(tabIndex);
  }

  //mode : sns계정영역 눌림(0), 광고눌림(1)
  handleSnsMission = (mode, snsType, snsItem) =>
  {
    if (this.state.loading)
      return;

    if (mode === 0)
    {
      if (!SnsStoreInstance.isSnsAccountValid(snsType))
      {
        NaviStoreInstance.setUiFrom(UI_MISSION);
        this.handleNavi(UI_SNSACC);
      }
    }
    else if (mode === 1)
    {
      this.setShowPopup(2, "SNS 미션 참여는 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
      return;
    }
  }

  handleGetCash = () =>
  {
    this.setShowPopup(2, "적립소는 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    return;
  }

  //mode : 초대코드복사(0), 공유(1)
  handleInvite = (mode) =>
  {
    if (mode === 0)
    {
      this.setShowPopup(1, "초대코드가 복사되었습니다", "확인", "취소", null);
      return;
    }
    else if (mode === 1)
    {
      this.setShowPopup(2, "카카오톡 공유하기는 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    }
  }

  handleBingoGame = (bingoGameNum) =>
  {
    if (this.state.loading)
      return;

    this.bingoGameNum = bingoGameNum;

    this.setLoading(true);
    BingoGameStoreInstance.loadSampleGame(bingoGameNum, this.onOnBingoUrl);
  }

  onOnBingoUrl = (resultCode, url) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !IsValidS(url))
    {
      this.showToastMessage("준비중인 서비스입니다. 정식 오픈을 기대해 주세요!");
      return;
    }

    this.setBingoGameUrl(url);
    this.showBingoGame(this.bingoGameNum);
  }

  renderTab = () =>
  {
    switch(this.state.selectedTab)
    {
      case 0://팡팡박스
        return <MissionBingo key={0} snsType={0} handleSnsMission={this.handleSnsMission} setShowPopup={this.setShowPopup} handleBingoGame={this.handleBingoGame}/>
      case 1: //인스타
        return <MissionSns key={1} snsType={0} handleSnsMission={this.handleSnsMission} setShowPopup={this.setShowPopup}/>
      case 2: //페이스북
        return <MissionSns key={2} snsType={1} handleSnsMission={this.handleSnsMission} setShowPopup={this.setShowPopup} />
      case 3: //유튜브
        return <MissionSns key={3} snsType={2} handleSnsMission={this.handleSnsMission} setShowPopup={this.setShowPopup} />
      case 4: //틱톡
        return <MissionSns key={4} snsType={3} handleSnsMission={this.handleSnsMission} setShowPopup={this.setShowPopup} />
      case 5: //적립소
        return <MissionGetCash key={5} handleGetCash={this.handleGetCash}/>;
      case 6: //친구초대
        return <MissionInvite key={6} handleInvite={this.handleInvite}/>;
      default:
        return null;
    }
    return null;
  }

  handleToastMsg = (msg) =>
  {
    this.showToastMessage(msg);
  }

  render()
  {
    return (
      <div className='mainbgcont flexcoltc'>
        <div className='mainbg flexcoltc'>
          {
            this.state.showBingoGame > 0 ? (<BingoGameView url={this.state.bingoUrl} gameNumber={this.state.showBingoGame} handleClose={() => this.showBingoGame(0)} handleToastMsg={this.handleToastMsg}/> ) : null
          }
          {
            this.state.showToastMsg ? (<ToastMessage msg={this.state.toastMessage}/>) : null
          }
          {
            this.state.showPop === 1 ? (
              <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
            ) : null
          }

          {
            this.state.showPop === 2 ? (
              <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
            ) : null
          }
          {
            this.state.loading ? (<Loading />) : null
          }
          <div className='main1 flexcoltl'>
            <TopBar withLine={true} handleAlarm={this.handleAlarm} handleHome={() => this.handleBottomMenu(0)}/>
          </div>

          <div className='msmnarea rowcc'>
            <div className='msmnarea2 colel'>
              <div className='rowce btncur' onClick={this.handleCashLog}>
                <img src={GetImgUrl("cash3@3x.png")} alt="머니" className='img1515 mgbt5 mgrt5'/>
                <label className='font24 wt500 color31 mgrt5 btncur'>{GetFormattedNum(LoginStoreInstance.getUserCash)}</label>
                <label className='font14 wt400 color31 mgbt2 btncur'>머니</label>
              </div>
            </div>
          </div>
          
          <div className='mstabarea rowcc'>
            <div className='mstabarea2 rowcc'>
            <Flicking
              viewportTag="div"
              cameraTag="div"
              cameraClass=""
              renderOnSameKey={false}
              align="prev"
              onMove={(e) => {}}
              onWillChange={(e) => {}}
              horizontal={true}
              circular={false}
              interruptable={true}
              //preventDefaultOnDrag={true}
              bound={true}
              panelsPerView={-1}
              moveType="strict"
              resizeOnContentsReady={false}
              autoResize={true}
              autoInit={true}
              threshold={100}
              ref={this.tabRef}
            >
              <MissionTab key={0} tabIndex={0} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="빙고팡팡"/>
              <MissionTab key={1} tabIndex={1} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="인스타"/>
              {/*<MissionTab key={2} tabIndex={2} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="페이스북"/>*/}
              <MissionTab key={3} tabIndex={3} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="유튜브"/>
              <MissionTab key={4} tabIndex={4} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="틱톡"/>
              <MissionTab key={5} tabIndex={5} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="적립소"/>
              <MissionTab key={6} tabIndex={6} selectedTab={this.state.selectedTab} handleTab={this.handleTab} title="친구초대"/>
            </Flicking>
            </div>
          </div>

          <div className='msline mgtop15'></div>

          <div className='mscontarea coltc'>
            {this.renderTab()}
          </div>
          <BottomBar selected={1} handleMenu={this.handleBottomMenu}/>

        </div>
      </div>
    );
  }
}

export default observer(withNavigation(Mission));

