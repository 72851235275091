import './MainPage.css';
import '../../App.css';
import { getProductImageUrl } from '../../URL/AppUrl';
import { GetFormattedNum, getShortedPathName } from '../../Util/Util';

const PdItem = (props) =>
{
  return (
    <div className='mnpditembg coltl btncur'>
      <div className='mnpdimgbg coltc'>
        <img src={getProductImageUrl(props.item.img)} alt="경품" className='mnpdimg'/>
      </div>

      <label className='font14 wt400 color31 mgtop5'>{getShortedPathName(props.item.nm, 10)}</label>
      <label className='font14 wt300 color31 mgtop10'>정가</label>
      <label className='font14 wt300 color31 mgtop10'>{`${GetFormattedNum(props.item.prc)}원`}</label>
    </div>
  );
}

export default PdItem;