import React from 'react';
import './SnsAcc.css';
import '../../App.css';
import { GetImgUrl, GotoAppStore, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, IsValidV, getSnsPlatformName, } from '../../Util/Util';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import TopBar2 from '../Top/TopBar2';

import { observer } from 'mobx-react';

import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';

import Term from '../Term/Term';
import Privacy from '../Term/Privacy';
import { SnsStoreInstance } from '../../Stores/SnsStore';

//SNS계정 등록창
class SnsAcc extends React.Component
{
  state = {
    loading: false,
    
    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,
  };
  intervalId = -1;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      if (!SnsStoreInstance.accountReady)
      {
        this.setLoading(true);
        SnsStoreInstance.loadSnsAccount(this.onLoadAccount);
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    if (!SnsStoreInstance.accountReady)
    {
      this.setLoading(true);
      SnsStoreInstance.loadSnsAccount(this.onLoadAccount);
    }
  }

  onLoadAccount = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`등록한 SNS계정 목록 조회 중 오류가 발생했습니다.\n오류코드 ${resultCode}`);
      return;
    }
  }
  
  getSnsId = (snsInfo) =>
  {
    if (!IsValidV(snsInfo))
      return "등록된 계정이 없습니다.";
  
    let name = null;
  
    if (IsValidS(snsInfo.nm) && snsInfo.nm !== "n")
      name = snsInfo.nm;
    else
      name = snsInfo.un;
  
    if (snsInfo.st === 2)
      name += "(정지됨)";
  
    return name;
  }
  
  handleAddAccount = (platform) =>
  {
    if (this.state.loading)
      return;

    this.setShowPopup(2, "SNS 계정등록은 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }

  handleDelAccount = (platform, accountInfo) =>
  {
    if (this.state.loading)
      return;

    this.setShowPopup(2, `등록하신 ${getSnsPlatformName(platform)} 계정을 삭제 하시겠습니까?`, "삭제하기", "취소", (yes) => {
      this.setShowPopup(0, null, null, null, null);
    
      if (!yes)
        return;
  
      this.setLoading(false);
      SnsStoreInstance.delSnsAccount(accountInfo.idx, this.onDeleteResult);
    });
  }

  onDeleteResult = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `SNS계정 삭제 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, "확인", "", null);
    }
    else
      this.setShowPopup(1, `SNS 계정 삭제가 정상 처리되었습니다.`, "확인", "", null);
  }

  render()
  {
    const insta = SnsStoreInstance.getInstaAccount;
    const facebook = SnsStoreInstance.getFbAccount;
    const youtube = SnsStoreInstance.getYtAccount;
    const tiktok = SnsStoreInstance.getTiktokAccount;

    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showTerm ? (
            <Term handleClose={() => this.showTerm(false)}/>
          ) : null
        }

        {
          this.state.showPrivacy ? (
            <Privacy handleClose={() => this.showPrivacy(false)}/>
          ) : null
        }
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(0, null, null)}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }

        <div className='wd100p max500 colcc mgtop20'>
          <TopBar2 withLine={true} title="SNS계정 관리" handleBack={this.handleBack} />

          <div className='mgtop40'></div>

          <div className='wd88p rowlc'>
            <label className='font14 wt400 color31'>등록된 계정 관리</label>
          </div>

          <div className='snsitem colevenl'>
            <div className='rowcc'>
              <img src={GetImgUrl("snsins.png")} alt="sns" className='img1515'/>
              <label className='font14 wt400 colorb3'>&nbsp;인스타그램</label>
            </div>

            <div className='wd100p rowsc'>
              <label className='font15 wt400 color31 mglt15'>&nbsp;{this.getSnsId(insta)}</label>

              {
                !IsValidV(insta) ? (
                  <button className='snsbtn1 font14 wt400 colorff btncur' onClick={() => this.handleAddAccount(0)}>추가하기</button>
                ) : (
                  <button className='snsbtn2 font14 wt400 colorb3 btncur' onClick={() => this.handleDelAccount(0, insta)}>삭제</button>
                )
              }
            </div>
          </div>
          <div className='snsline'></div>

          {/*<div className='snsitem colevenl'>
            <div className='rowcc'>
              <img src={GetImgUrl("snsfb.png")} alt="sns" className='img1515'/>
              <label className='font14 wt400 colorb3'>&nbsp;페이스북</label>
            </div>

            <div className='wd100p rowsc'>
              <label className='font15 wt400 color31 mglt15'>&nbsp;{this.getSnsId(facebook)}</label>

              {
                !IsValidV(facebook) ? (
                  <button className='snsbtn1 font14 wt400 colorff btncur' onClick={() => this.handleAddAccount(1)}>추가하기</button>
                ) : (
                  <button className='snsbtn2 font14 wt400 colorb3 btncur' onClick={() => this.handleDelAccount(1, facebook)}>삭제</button>
                )
              }
            </div>
          </div>
          <div className='snsline'></div>*/}

          <div className='snsitem colevenl'>
            <div className='rowcc'>
              <img src={GetImgUrl("snsyt.png")} alt="sns" className='img1515'/>
              <label className='font14 wt400 colorb3'>&nbsp;유튜브</label>
            </div>

            <div className='wd100p rowsc'>
              <label className='font15 wt400 color31 mglt15'>&nbsp;{this.getSnsId(youtube)}</label>

              {
                !IsValidV(youtube) ? (
                  <button className='snsbtn1 font14 wt400 colorff btncur' onClick={() => this.handleAddAccount(2)}>추가하기</button>
                ) : (
                  <button className='snsbtn2 font14 wt400 colorb3 btncur' onClick={() => this.handleDelAccount(2, youtube)}>삭제</button>
                )
              }
            </div>
          </div>
          <div className='snsline'></div>

          <div className='snsitem colevenl'>
            <div className='rowcc'>
              <img src={GetImgUrl("snstt.png")} alt="sns" className='img1515'/>
              <label className='font14 wt400 colorb3'>&nbsp;틱톡</label>
            </div>

            <div className='wd100p rowsc'>
              <label className='font15 wt400 color31 mglt15'>&nbsp;{this.getSnsId(tiktok)}</label>

              {
                !IsValidV(tiktok) ? (
                  <button className='snsbtn1 font14 wt400 colorff btncur' onClick={() => this.handleAddAccount(3)}>추가하기</button>
                ) : (
                  <button className='snsbtn2 font14 wt400 colorb3 btncur' onClick={() => this.handleDelAccount(3, tiktok)}>삭제</button>
                )
              }
            </div>
          </div>
          <div className='snsline'></div>
        </div>
        
        
      </div>
    );
  }
}

export default observer(withNavigation(SnsAcc));

