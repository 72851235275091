import React from 'react';
import './Inventory.css';
import '../../App.css';
import { observer } from 'mobx-react';
import { InvenStoreInstance } from '../../Stores/InvenStore';
import { IsValidS } from '../../Util/Util';
import InvenPdItem from './InvenPdItem';

//인벤토리 상품보관함
class InvenPd extends React.Component
{
  state = {
    loading: false,

    noItem: false,
  };
  
  setLoading = (v) =>
  {
    if (this.props.setParentLoading)
      this.props.setParentLoading(v);

    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  componentDidMount() {
    if (!InvenStoreInstance.pdListReady)
    {
      this.loadPdList();
    }
  }

  componentWillUnmount = () =>
  {

  }

  loadPdList = () =>
  {
    this.setLoading(true);
    InvenStoreInstance.loadPdList(this.onLoadPd);
  }

  onLoadPd = (resultCode, readCount) =>
  {
    this.setLoading(false);
    
    if (resultCode !== 0)
    {
      this.props.setShowPopup(1, "보유상품 목록 조회 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.", "확인", '', null);
      return;
    }

    if (readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleMore = () =>
  {
    if (this.state.loading)
      return;

    this.loadPdList();
  }
  
  render()
  {
    let pdList = null;

    if (IsValidS(InvenStoreInstance.pdList))
    {
      pdList = InvenStoreInstance.pdList.map(item => <InvenPdItem key={item.idx} item={item} handleEditItem={this.props.handleEditItem}/>)
    }

    return (
      <div className='ivlist coltc mgbt100'>
        <div className='wd100p rowlc mgbt20'>
          <label className='font12 wt300 colorb3'>※ 기간 내 배송지를 입력하지 않을 경우 자동 환급됩니다.</label>
        </div>
        {pdList}

        {
          !this.state.noItem ? (
            <div className='ivmorebtn rowcc btncur' onClick={this.handleMore}>
              <label className='font14 color31 wt400 btncur'>더보기</label>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default observer(InvenPd);

