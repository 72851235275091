import React from 'react';
import './MainPage.css';
import '../../App.css';
import { observer } from 'mobx-react';
import { UseLoginStore } from '../../Stores/LoginStore';
import { GetImgUrl, GetProfileImgUrl } from '../../URL/AppUrl';
import { GetFormattedNum, IsValidS } from '../../Util/Util';

const Profile = observer((props) =>
{
  const loginStore = UseLoginStore();

  return (
    <div className='prf flexrowse mgtop10'>
      <div className='prfl flexcolel'>
        <div className='flexrowcc margintop10'>
          <div className='flexrowcc marginright30 btncur' onClick={props.handleCashLog}>
            <img src={GetImgUrl("cash3@3x.png")} alt="머니" className='cashimg marginright5'/>
            <label className='font18 color31 weight300 btncur'>{`${GetFormattedNum(loginStore.getUserCash)}`}<span className='font14'>&nbsp;머니</span></label>
          </div>

          <div className='flexrowcc btncur' onClick={props.handlePangLog}>
            <img src={GetImgUrl("pang@3x.png")} alt="팡" className='cashimg marginright5'/>
            <label className='font18 color31 weight300 btncur'>{`${GetFormattedNum(loginStore.getUserPang)}`}<span className='font14'>&nbsp;팡</span></label>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Profile;