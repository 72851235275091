import './MainPage.css';
import '../../App.css';
import { GetImgUrl, getProductImageUrl } from '../../URL/AppUrl';
import { GetFormattedNum, getShortedPathName } from '../../Util/Util';

const PdItemMore = (props) =>
{
  return (
    <div className='mnpditembg2 coltc btncur' onClick={props.handleClick}>
      <label className='font12 wt400 colorff mgtop40 btncur'>더보기</label>
      <img src={GetImgUrl("hpdmr.png")} alt="더보기" className='img1010 mgtop5'/>
    </div>
  );
}

export default PdItemMore;