
import React from 'react';
import './Winners.css';
import '../../App.css';
import { GetDataDateAndTime, IsValidS, checkBatchimEnding } from '../../Util/Util';
import { GetProfileImgUrl, getProductImageUrl } from '../../URL/AppUrl';

const getUserProfileImg = (item) =>
{
  if (!IsValidS(item.uc) || item.uc === "n")
    return GetProfileImgUrl("defprfsm.png");

  return GetProfileImgUrl(item.uc);
}

const WinnersItem = (props) =>
{
  return (
    <div className='wd100p colcc mgbt15'>
      <div className='wd100p rowss'>
        <div className='wnpbox rowcc'>
          <img src={getProductImageUrl(props.item.ic)} alt="상품" className='wnpboximg'/>
        </div>

        <div className='wninfo coltl'>
          <div className='rowcc'>
            <img src={getUserProfileImg(props.item)} alt="상품" className='wnprfimg mgrt5'/>
            <label className='font14 wt400 color31'>{props.item.un}</label>
          </div>

          <label className='font14 wt300 color31 lht18 mgtop5'>{`${props.item.itn}${checkBatchimEnding(props.item.itn) ? "을" : "를"} 획득하였습니다.`}</label>
        </div>
      </div>

      <div className='wnline mgtop15'></div>
    </div>
  );
}

export default WinnersItem;