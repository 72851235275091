import React, { Fragment } from 'react';
import './MyPang.css';
import '../../App.css';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { IsValidS } from '../../Util/Util';

//닉네임 변경창
class ChangeNickname extends React.Component
{
  state = {
    loading: false,
    name: '',
    error: '',

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,
  };

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setNickname = (v) =>
  {
    this.setState((prevState) => ({...prevState, name: v}));
  }

  setError = (v) =>
  {
    this.setState((prevState) => ({...prevState, error: v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  setShowMsgPopup = (msg, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 1, popMsg: msg, popOkBtn: "확인", popCancelBtn: '', popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  componentDidMount() {
    this.setNickname(LoginStoreInstance.getUserName);
  }

  componentWillUnmount = () =>
  {
  }

  handleClose = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  handleChange = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.name))
    {
      this.setError('변경할 닉네임을 입력해 주세요.');
      return;
    }

    if (this.state.name === LoginStoreInstance.getUserName)
    {
      this.setError('기존과 동일한 이름입니다.');
      return;
    }

    if (this.state.name.indexOf("user") >= 0)
    {
      this.setError('사용할 수 없는 닉네임 입니다.');
      return;
    }

    if (this.state.name.length < 2)
    {
      this.setError("이름을 두자 이상 입력해 주세요.");
      return;
    }

    if (this.state.name.length > 10)
    {
      this.setError("이름은 10자를 초과할 수 없습니다.");
      return;
    }

    this.setShowPopup(2, "닉네임을 변경할까요?", "확인", "취소", (yes) => {
      this.closePopup();

      if (yes)
      {
        this.setLoading(true);
        LoginStoreInstance.updateUserNickname(this.state.name, this.onUpdated);
      }
    });
  }

  onUpdated = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setShowMsgPopup('닉네임이 변경되었습니다.', () => {this.handleClose()});
        return;
      case 1:
        this.setShowMsgPopup('잘못된 요청입니다.', null);
        return;
      case 3:
        this.setShowMsgPopup('사용할 수 없는 이름입니다.', null);
        this.setShowMsgPop(true);
        return;
      case 4:
        this.setError('이미 사용중인 이름입니다.', null);
        return;
      default:
        this.setShowMsgPopup(`닉네임 변경에 실패 했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, null);
        return;
    }
  }

  render()
  {
    const isValid = IsValidS(this.state.name);

    return (
      <div className='hpquerypop coltc vscroll'>
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 title="닉네임 변경" withLine={true} handleBack={this.handleClose} />

        <div className='wd100p max500 coltc mgtop60 posrel'>
          <div className='wd88p coltl'>
            <label className='font12 wt500 color31'>변경할 닉네임</label>

            <input type='text' value={this.state.name} onChange={e => this.setNickname(e.target.value)} className='mpnickinp font14 wt300 color31 mgtop5' placeholder='변경할 닉네임을 입력해 주세요.' maxLength={30}/>

            <label className='font12 wt500 colorffc8 mgtop10'>{this.state.error}</label>
          </div>
        </div>

        <div className='mpnickbtnbg rowcc'>
          <button className={isValid?'mpnickbtn font14 colorw wt400 btncur':'mpnickbtn2 font14 colorb3 wt400 btncur'} onClick={this.handleChange}>수정완료</button>
        </div>
      </div>
    );
  }
}

export default ChangeNickname;

