import {isMobile} from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { UI_ADDRESS, UI_ALARM, UI_CASHLOG, UI_GUIDE, UI_HELPCENTER, UI_HOME, UI_INVENTORY, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_NOTICE, UI_PANGBOX, UI_PANGLOG, UI_QUERY, UI_SETTING, UI_SNSACC, UI_WINNER } from '../Stores/NaviStore';

const APP_MODE = 2; //개발(0), 테스트(1), 라이브(2)
const LOG_ENABLE = APP_MODE === 0;
const APP_VERSION = "1.0.0";

const GOOGLE_PLAY = "market://details?id=com.bravo6.cashpang";
const GOOGLE_PLAY_WEB = "https://play.google.com/store/apps/details?id=com.bravo6.cashpang";

//api2호출 경로
const API_URL_DEV = "https://test2.bravo6.kr:5001/"; //개발서버
const API_URL_TEST = "https://test.snsfly.kr:5001/"; //테스트 서버
const API_URL_LIVE = "https://api.snsfly.kr/"; //라이브서버

//const SNS_API_DEV = "http://test2.bravo6.kr:3001/"; //개발
const SNS_API_DEV = "http://test.snsfly.kr:3001/"; //테스트
const SNS_API_TEST = "http://test.snsfly.kr:3001/"; //테스트
const SNS_API_LIVE = "http://api2.snsfly.kr/"; //라이브서버

//머니팡 웹사이트 기본 이미지 경로
const IMG_URL_LIVE = "https://cdn.snsfly.kr/cashp/pang/";
const IMG_URL_TEST = "https://test.snsfly.kr/cashp/pang/";
const IMG_URL_DEV = "https://test2.bravo6.kr/cashp/pang/";


//프로필 이미지 경로
const IMG_PRF_DEV = "https://test2.bravo6.kr/cashp/user/";
const IMG_PRF_TEST = "https://test.snsfly.kr/cashp/user/";
const IMG_PRF_LIVE = "https://cdn.snsfly.kr/cashp/user/";

//상품 이미지 경로
const IMG_PD_DEV = "https://test2.bravo6.kr/cashp/pd/";
const IMG_PD_TEST = "https://test.snsfly.kr/cashp/pd/";
const IMG_PD_LIVE = "https://cdn.snsfly.kr/cashp/pd/";

//캐시업광고 썸네일이미지
const IMG_ADS_DEV = "https://test2.bravo6.kr/cashp/ads/";
const IMG_ADS_TEST = "https://test.snsfly.kr/cashp/ads/";
const IMG_ADS_LIVE = "https://cdn.snsfly.kr/cashp/ads/";

//머니팡 공지 이미지 경로
const IMG_CPNTC_DEV = "https://test2.bravo6.kr/cashp/cntc/";
const IMG_CPNTC_TEST = "https://test.snsfly.kr/cashp/cntc/";
const IMG_CPNTC_LIVE = "https://cdn.snsfly.kr/cashp/cntc/";

//팡팡박스 캐로셀광고 이미지
const IMG_PBADS_DEV = "https://test2.bravo6.kr/cashp/pbads/";
const IMG_PBADS_TEST = "https://test.snsfly.kr/cashp/pbads/";
const IMG_PBADS_LIVE = "https://cdn.snsfly.kr/cashp/pbads/";

//가이드 이미지 경로
const IMG_GUIDE_DEV = "https://test2.bravo6.kr/cashp/guide/";
const IMG_GUIDE_TEST = "https://test.snsfly.kr/cashp/guide/";
const IMG_GUIDE_LIVE = "https://cdn.snsfly.kr/cashp/guide/";

//1:1 문의 이미지 경로
const IMG_HELP_DEV = "https://test2.bravo6.kr/cashp/help/";
const IMG_HELP_TEST = "https://test.snsfly.kr/cashp/help/";
const IMG_HELP_LIVE = "https://cdn.snsfly.kr/cashp/help/";

const FIRST_PAGE_INDEX = 0; //로그인시 처음진입하는 페이지 번호


//카카오 리다이렉트 주소
const KAKAO_REDIR_DEV = "http://localhost:3000/?platform=kakao";
const KAKAO_REDIR_TEST = "http://test.snsfly.kr/cp/?platform=kakao";
const KAKAO_REDIR_LIVE = "https://www.mpang.io/?platform=kakao";

//네이버 리다이렉트 주소
const NAVER_REDIR_DEV = "http://localhost:3000/?platform=naver";
const NAVER_REDIR_TEST = "http://test.snsfly.kr/cp/?platform=naver";
const NAVER_REDIR_LIVE = "https://www.mpang.io/?platform=naver";

const TERM_TEST = "https://test.snsfly.kr/cashp/term/term.html";
const TERM_LIVE = "https://www.mpang.io/cashp/term/term.html";

const PRIVACY_TEST = "https://test.snsfly.kr/cashp/term/privacy.html";
const PRIVACY_LIVE = "https://www.mpang.io/cashp/term/privacy.html";

let mobileMode = false;//isMobile || true;
let mobileOn = isMobile || mobileMode;

function GetSNSApiUrl(url)
{
  switch(APP_MODE)
  {
    case 0:
      return `${SNS_API_DEV}${url}`;
    case 1:
      return `${SNS_API_TEST}${url}`;
    case 2:
    default:
      return `${SNS_API_LIVE}${url}`;
  }
}

function GetApiUrlBase()
{
  switch(APP_MODE)
  {
    case 0:
      return API_URL_DEV;
    case 1:
      return API_URL_TEST;
    case 2:
    default:
      return API_URL_LIVE;
  }
}

function GetApiUrl(url)
{
  return `${GetApiUrlBase()}${url}`;
}

function GetUploadApiUrl(url)
{
  if (APP_MODE === 2)
    return `https://upload.snsfly.kr:5001/${url}`;
  else
    return GetApiUrl(url);
}

//이미지 리소스 파일의 경로를 얻음
function GetImgUrl(imageName)
{
  switch(APP_MODE)
  {
    case 0://개발
      return IMG_URL_DEV + imageName;
    case 1://테스트
      return IMG_URL_TEST + imageName;
    case 2://라이브
      return IMG_URL_LIVE + imageName;
    default:
      return null;
  }
}

function GetProfileImgUrl(imageName)
{
  switch(APP_MODE)
  {
    case 0://개발
      return IMG_PRF_DEV + imageName;
    case 1://테스트
      return IMG_PRF_TEST + imageName;
    case 2://라이브
      return IMG_PRF_LIVE + imageName;
    default:
      return null;
  }
}

//팡팡박스 상품이미지 경로
const getProductImageUrl = (name) =>
{
  switch(APP_MODE)
  {
    case 0:
      return `${IMG_PD_DEV}${name}`;
    case 1:
      return `${IMG_PD_TEST}${name}`;
    case 2:
      return `${IMG_PD_LIVE}${name}`;
    default:
      return name;
  }
}

//캐시업광고 이미지 경로
const getAdsImageUrl = (name) =>
{
  switch(APP_MODE)
  {
    case 0:
      return `${IMG_ADS_DEV}${name}`;
    case 1:
      return `${IMG_ADS_TEST}${name}`;
    case 2:
      return `${IMG_ADS_LIVE}${name}`;
    default:
      return name;
  }
}

//머니팡 공지 이미지 경로 얻기
function GetCpNoticeImgUrl(imageName)
{
  switch(APP_MODE)
  {
    case 0://개발
      return IMG_CPNTC_DEV + imageName;
    case 1://테스트
      return IMG_CPNTC_TEST + imageName;
    case 2://라이브
      return IMG_CPNTC_LIVE + imageName;
    default:
      return null;
  }
}

//팡팡박스 캐로셀 광고 이미지 경로 얻기
const getPbAdsImageUrl = (name) =>
{
  switch(APP_MODE)
  {
    case 0:
      return `${IMG_PBADS_DEV}${name}`;
    case 1:
      return `${IMG_PBADS_TEST}${name}`;
    case 2:
      return `${IMG_PBADS_LIVE}${name}`;
    default:
      return name;
  }
}

//가이드 이미지 경로 얻기
const getGuideImageUrl = (name) =>
{
  switch(APP_MODE)
  {
    case 0:
      return `${IMG_GUIDE_DEV}${name}`;
    case 1:
      return `${IMG_GUIDE_TEST}${name}`;
    case 2:
      return `${IMG_GUIDE_LIVE}${name}`;
    default:
      return name;
  }
}

//1:1문의 이미지 경로
const getHelpImageUrl = (name) =>
{
  switch(APP_MODE)
  {
    case 0:
      return `${IMG_HELP_DEV}${name}`;
    case 1:
      return `${IMG_HELP_TEST}${name}`;
    case 2:
      return `${IMG_HELP_LIVE}${name}`;
    default:
      return name;
  }
}

function getKakaoRedirectUrl()
{
  switch(APP_MODE)
  {
    case 0:
      return KAKAO_REDIR_DEV;
    case 1:
      return KAKAO_REDIR_TEST;
    case 2:
    default:
      return KAKAO_REDIR_LIVE;
  }
}

function getNaverRedirectUrl()
{
  switch(APP_MODE)
  {
    case 0:
      return NAVER_REDIR_DEV;
    case 1:
      return NAVER_REDIR_TEST;
    case 2:
    default:
      return NAVER_REDIR_LIVE;
  }
}

function handleAppNavi(navigation, naviIndex)
{
  switch(naviIndex)
  {
    case UI_LOGIN: //로그인
      navigation('/login');
      return;
    case UI_HOME: //메인
      navigation('/main');
      return;
    case UI_ALARM: //알림
      navigation('/alarm');
      return;
    case UI_NOTICE: //공지
      navigation('/notice');
      return;
    case UI_MYPANG: //전체메뉴
      navigation('/mypang');
      return;
    case UI_SETTING: //설정
      navigation('/setting');
      return;
    case UI_SNSACC: //SNS계정관리
      navigation('/sns');
      return;
    case UI_HELPCENTER: //고객센터
      navigation('/help');
      return;
    case UI_ADDRESS: //배송지관리
      navigation('/address');
      return;
    case UI_QUERY: //제휴및 광고문의
      navigation('/query');
      return;
    case UI_GUIDE: //이용가이드
      navigation('/guide');
      return;
    case UI_PANGBOX: //머니쇼핑
      navigation('/pang');
      return;
    case UI_WINNER: //팡팡박스 당첨자 목록
      navigation('/winner');
      return;
    case UI_CASHLOG: //캐시적립내역
      navigation('/cashlog');
      return;
    case UI_PANGLOG: //팡적립내역
      navigation('/panglog');
      return;
    case UI_MISSION: //미션
      navigation('/mission');
      return;
    case UI_INVENTORY: //인벤토리
      navigation('/inventory');
      return;
    default:
      return;
  }
}

function GotoLogin()
{
  if (APP_MODE === 0)
  {
    window.location.href = "http://localhost:3000/";
  }
  else if (APP_MODE === 1)
  {
    window.location.href = "https://test.snsfly.kr/cp/";
  }
  else
  {
    window.location.href = "https://www.mpang.io/";
  }
}

function GotoAppStore()
{
  window.open(GOOGLE_PLAY_WEB, "_blank");
}

function withNavigation(Component)
{
  return props => <Component {...props} navigate={useNavigate()} />;
}

const getTermUrl = () =>
{
  if (APP_MODE === 2)
    return TERM_LIVE;
  
  return TERM_TEST;
}

const getPrivacyUrl = () =>
{
  if (APP_MODE === 2)
    return PRIVACY_LIVE;
  
  return PRIVACY_TEST;
}

export {
  GetSNSApiUrl,
  GetApiUrlBase,
  GetApiUrl,
  GetUploadApiUrl,
  GetImgUrl,
  GetProfileImgUrl,
  getProductImageUrl,
  getAdsImageUrl,
  GetCpNoticeImgUrl,
  getPbAdsImageUrl,
  getGuideImageUrl,
  getHelpImageUrl,
  getKakaoRedirectUrl,
  getNaverRedirectUrl,
  FIRST_PAGE_INDEX, APP_MODE, LOG_ENABLE, APP_VERSION, mobileMode, mobileOn,
  handleAppNavi,
  GotoLogin,
  GotoAppStore,
  withNavigation,

  getTermUrl,
  getPrivacyUrl,
};