import React from 'react';
import './BottomBar.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const BottomBar = (props) =>
{
  return (
    <div className='btbarbg colcc'>
      <div className='btbarline'></div>
      <div className='btbar flexrowsc'>
        <div className='btbaritem flexcolcc btncur' onClick={() => props.handleMenu(0)}>
          <img src={GetImgUrl(0 === props.selected ? "homeen.png" : "home.png")} alt="홈" className='btimg1 marginbottom5'/>
          <label className={0 === props.selected ? 'font10 colorff weight400 btncur' : 'font10 colorb3 weight400 btncur'}>홈</label>
        </div>

        <div className='btbaritem flexcolcc btncur' onClick={() => props.handleMenu(1)}>
          <img src={GetImgUrl(1 === props.selected ? "misen.png" : "mis.png")} alt="미션" className='btimg2 marginbottom5'/>
          <label className={1 === props.selected ? 'font10 colorff weight400 btncur' : 'font10 colorb3 weight400 btncur'}>미션적립</label>
        </div>

        <div className='btbaritem flexcolcc btncur' onClick={() => props.handleMenu(2)}>
          <img src={GetImgUrl(2 === props.selected ? "pangboxen.png" : "pangbox.png")} alt="홈" className='btimg2 marginbottom5'/>
          <label className={2 === props.selected ? 'font10 colorff weight400 btncur' : 'font10 colorb3 weight400 btncur'}>머니쇼핑</label>
        </div>

        <div className='btbaritem flexcolcc btncur' onClick={() => props.handleMenu(3)}>
          <img src={GetImgUrl(3 === props.selected ? "mypangen.png" : "mypang.png")} alt="홈" className='btimg2 marginbottom5'/>
          <label className={3 === props.selected ? 'font10 colorff weight400 btncur' : 'font10 colorb3 weight400 btncur'}>전체</label>
        </div>
      </div>
    </div>
  );
}

export default BottomBar;