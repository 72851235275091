import React from 'react';

import './PangBox.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, GotoAppStore, GotoLogin, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetFormattedNum, IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';

import { LoginStoreInstance } from '../../Stores/LoginStore';

import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import BottomBar from '../Bottom/BottomBar';

import { observer } from 'mobx-react';

import { NaviStoreInstance,  UI_ALARM, UI_CASHLOG, UI_HOME, UI_INVENTORY, UI_MISSION, UI_MYPANG, UI_PANGBOX, UI_PANGLOG, UI_WINNER  } from '../../Stores/NaviStore';
import PangBoxWinner from './PangBoxWinner';
import { PangStoreInstance } from '../../Stores/PangStore';
import { InvenStoreInstance } from '../../Stores/InvenStore';
import PangBoxItem from './PangBoxItem';
import PangBoxInfo from './PangBoxInfo';
import PangBoxBuy from './PangBoxBuy';
import PangBoxBuySuc from './PangBoxBuySuc';
import PangBoxBuyFail from './PangBoxBuyFail';
import PangBoxOpenView from './PangBoxOpenView';
import SelectMsgPop from '../Common/SelectMsgPop';



//팡팡박스 페이지
class PangBox extends React.Component
{
  state = {
    loading: false,

    showPop: 0,
    popTitle: '',
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    sortMode: 1, //최신(0), 고가(1), 저가(2)
    lastWinner: null, //마지막 고급경품 당첨자중 코멘트 작성한 경품 정보
    noItem: false,

    showDetail: false,

    showBoxBuy: false, //박스 구매팝업
    showBoxBuySuc: false, //박스 구매 성공 팝업
    showBoxBuyFailed: false, //박스 구매 실패 팝업
    showBoxOpen: false, //팡팡박스 오픈 연출창 보이기
    boxOpenMode: 0, //정식오픈(0), 체험오픈(1)
  };

  intervalId = -1;
  loading = false;
  sortMode = 1;
  editItem = null;
  detailInfo = null;
  newBoxIdx = -1;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  setShowSelectPopup = (title, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 3, popTitle: title, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setSortMode = (v) =>
  {
    this.sortMode = v;
    this.setState((prevState) => ({...prevState, sortMode : v}));
  }

  setLastWinner = (v) =>
  {
    this.setState((prevState) => ({...prevState, lastWinner : v}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  setShowDetail = (v) =>
  {
    this.setState((prevState) => ({...prevState, showDetail : v}));
  }

  //박스 구매창
  setShowBoxBuy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxBuy : v}));
  }

  //구매 성공창
  setShowBoxBuySuc = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxBuySuc : v}));
  }

  //구매 실패창
  setShowBoxBuyFail = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxBuyFailed : v}));
  }

  //팡팡박스 오픈 연출창 보이기
  setShowBoxOpen = (v) =>
  {
    this.setState((prevState) => ({...prevState, showBoxOpen : v}));
  }

  //팡팡박스 오픈모드 설정
  setBoxOpenMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, boxOpenMode : v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    GotoLogin();
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }

    if (!this.loading)
    {
      
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 20);

    PangStoreInstance.clearWinPdList();

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      this.setLoading(true);
      this.loadLastWinner();
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }
    else
    {
      this.loadLastWinner();
    }
  }
 
  //마지막 고급 경품 당첨자 조회
  loadLastWinner = () =>
  {
    this.setLoading(true);
    InvenStoreInstance.loadLastHistoryComment(this.onLoadLastWinner);
  }

  onLoadLastWinner = (resultCode, info) =>
  {
    if (resultCode !== 0)
    {
      this.setShowPopup(1, `당첨자 목록 조회중 오류가 발생했습니다.`, "확인", "", null);
    }

    if (info)
      this.setLastWinner(info);

    this.loadWinPd();
  }

  //경품 목록조회
  loadWinPd = () =>
  {
    this.setLoading(true);
    PangStoreInstance.loadWinPdList(this.sortMode, this.onLoadWinPdList);
  }

  onLoadWinPdList = (resultCode, readCount) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `경품 목록 조회중 오류가 발생했습니다.\n잠시 후 이용해 주세요.`, "확인", "", null);
      return;
    }

    if (readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleAlarm = () =>
  {
    //this.setShowPopup(2, "알림은 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_PANGBOX);
    this.handleNavi(UI_ALARM);
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }

  handleBottomMenu = (menuIndex) =>
  {
    switch(menuIndex)
    {
      case 0:
        this.handleNavi(UI_HOME);
        return;
      case 2:
        return;
      case 3:
        this.handleNavi(UI_MYPANG);
        return;
      case 1:
      case 5:
        this.handleNavi(UI_MISSION);
        return;
      default:
        return;
    }
  }

  //mode, 최신(0), 고가(1), 저가(2)
  handleSortMode = (mode) =>
  {
    if (mode === this.sortMode || this.loading)
      return;

    PangStoreInstance.clearWinPdList();
    this.setSortMode(mode);
    this.setNoItem(true);
    this.loadWinPd();
  }

  handleProductClick = (item) =>
  {
    if (this.loading)
      return;

    this.editItem = item;
    this.detailInfo = null;
    this.setLoading(true);
    PangStoreInstance.loadPdDetail(item.iidx, this.onLoadDetail);
  }

  onLoadDetail= (resultCode, info) =>
  {
    this.setLoading(false);

    if (resultCode !== 0 || !info)
    {
      this.setShowPopup(1, "상품 정보 수신 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.", "확인", "취소", null);
      return;
    }

    this.detailInfo = info;
    this.setShowDetail(true);
  }

  //당첨자 더보기
  handleMoreWinner = () =>
  {
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_PANGBOX);
    this.handleNavi(UI_WINNER);
  }

  //상품 더보기
  loadMorePd = () =>
  {
    if (this.loading)
      return;

    this.loadWinPd();
  }

  //구매 가능한 박스 개수 구하기
  checkBoxCount = () =>
  {
    return Math.floor(((LoginStoreInstance.getUserCash * 0.5) + LoginStoreInstance.getUserPang) / 1000);
  }

  //팡팡박스 체험뽑기
  handleOpenTestBox = () =>
  {
    if (this.state.loading)
      return;

    this.setShowSelectPopup("팡팡박스 체험판", "머니(팡)가 없어도 팡팡박스를 직접 체험할 수 있으며, 체험판에서 당첨된 상품은 배송 및 환급이 불가능합니다.\n체험 뽑기를 진행하시겠습니까?", "체험하기", "닫기",
      (yes) => {
        this.closePopup();

        if (!yes)
          return;

        if (!PangStoreInstance.openAniUrlReady) //팡팡박스 당첨 연출 페이지 주소가 준비되지 않았다면
        {
          this.setLoading(true);
          PangStoreInstance.loadOpenPageUrl((resultCode) => {
            this.setLoading(false);

            if (resultCode !== 0)
            {
              this.setShowPopup(1, `네트워크 오류로 인해 팡팡박스 오픈 요청에 실패 했습니다.\n잠시 후 이용해 주세요.`, "확인", '', null);
              return;
            }
          
            this.setBoxOpenMode(1);
            this.setShowBoxOpen(true);
          });
        }
        else
        {
          this.setBoxOpenMode(1);
          this.setShowBoxOpen(true);
        }
        
    });
  }

  //팡팡박스 구매하기
  handleOpenBox = () =>
  {
    if (this.state.loading)
      return;

    this.setShowDetail(false);

    this.setShowBoxBuy(true);
  }

  handleInventory = () =>
  {
    if (this.state.loading)
      return;

    //this.setShowPopup(2, "인벤토리는 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    NaviStoreInstance.setUiFrom(UI_PANGBOX);
    this.handleNavi(UI_INVENTORY);
  }

  handleCashLog = () =>
  {
    NaviStoreInstance.setUiFrom(UI_PANGBOX);
    this.handleNavi(UI_CASHLOG);
  }

  handlePangLog = () =>
  {
    NaviStoreInstance.setUiFrom(UI_PANGBOX);
    this.handleNavi(UI_PANGLOG);
  }

  //박스 구매창에서의 버튼 처리, 
  //mode : 닫기(0), 구매하기(1)
  handleBuyBox = (mode) =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuy(false);

    if (mode === 0)
    {
      return;
    }
    else //박스 구매하기
    {
      //돈이 부족하면
      if ((LoginStoreInstance.getUserCash + LoginStoreInstance.getUserPang) < 1000)
      {
        this.setShowBoxBuyFail(true);
        return;
      }
      
      this.setLoading(true);
      InvenStoreInstance.buyPangBox(2, null, null, this.onBuyBoxResult);
    }
  }

  //박스 구매 요청 결과
  onBuyBoxResult = (resultCode, result) =>
  {
    switch(resultCode)
    {
      case 0: //구매 성공
        break;
      case 1: //잘못된 요청
        this.setLoading(false);
        this.setShowPopup(1, "잘못된 요청입니다.", "확인", "", null);
        return;
      case 2: //포인트 부족
        this.setLoading(false);
        this.setShowBoxBuyFail(true);
        return;
      case 3: //결제 실패
        this.setLoading(false);
        this.setShowPopup(1, "주문 결제가 실패 하였습니다.", "확인", "", null);
        return;
      case 5: //박스 구매 불가능한 계정
        this.setLoading(false);
        this.setShowPopup(1, "이 계정은 현재 팡팡박스 구매가 불가능합니다.", "확인", "", null);
        return;
      case 4: //사용자 정보 조회실패
      case 6: //박스 구매실패
      default:
        this.setLoading(false);
        this.setShowPopup(1, `팡팡박스 구매중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, "확인", "", null);
        return;
    }

    //새로구매한 박스의 고유번호를 저장해둠
    this.newBoxIdx = result.bidx;

    if (result.rt === 0) //일반 박스 구매시
    {
      //이용자의 보유머니와 팡 정보를 갱신해 준다.
      LoginStoreInstance.setUserPoint(result.rc, result.rp);

      //팡팡박스 구매에 성공했다. 서버에서 팡팡박스 목록을 조회해야 한다.
      if (!InvenStoreInstance.boxlistReady)
      {//아직 팡팡박스 목록이 조회되지 않았으면, 전체 목록을 조회한다.
        InvenStoreInstance.clearBoxList();
        InvenStoreInstance.loadBoxList(this.onLoadBoxListForBuy);
      }
      else
      {//새로 구매한 박스의 정보만 조회한다.
        InvenStoreInstance.loadBoxInfo(result.bidx, this.onBoxInfoLoaded);
      }
    }
    else //골드박스구매, 아직구현안됨
    {
      this.setLoading(false);
      return;
    }
  }

  onBoxInfoLoaded = (resultCode, newBoxInfo) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `구매 정보 조회 중 오류가 발생했습니다.\n앱을 다시 실행해 주세요.\n오류코드 ${resultCode}`, "확인", '', null);
      return;
    }

    //새로구매한 박스를 목록에 추가해준다.
    InvenStoreInstance.addBox(newBoxInfo);

    this.setShowBoxBuySuc(true);
  }

  onLoadBoxListForBuy = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `구매 정보 조회 중 오류가 발생했습니다.\n앱을 다시 실행해 주세요.\n오류코드 ${resultCode}`, "확인", '', null);
      return;
    }

    this.setShowBoxBuySuc(true);
  }

  //박스 구매 성공팝업의 버튼 눌림처리
  handleBuyBoxSuc = (mode) =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuySuc(false);

    if (mode === 0) //닫기
    {

    }
    else if (mode === 1) //개봉하기
    {
      if (!PangStoreInstance.openAniUrlReady) //팡팡박스 당첨 연출 페이지 주소가 준비되지 않았다면
      {
        this.setLoading(true);
        PangStoreInstance.loadOpenPageUrl(this.onOpenPageUrlLoaded);
      }
      else //팡팡박스 오픈 연출 페이지 열기
      {
        this.setBoxOpenMode(0);
        this.setShowBoxOpen(true);
      }
    }
  }

  //팡팡박스 오픈 연출 페이지 주소를 획득함
  onOpenPageUrlLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowPopup(1, `네트워크 오류로 인해 팡팡박스 오픈 요청에 실패 했습니다.\n잠시 후 이용해 주세요.`, "확인", '', null);
      return;
    }

    this.setBoxOpenMode(0);
    this.setShowBoxOpen(true);
  }

  //박스구매 실패 팝업 버튼처리
  handleBuyBoxFail= (mode) =>
  {
    if (this.state.loading)
      return;

    this.setShowBoxBuyFail(false);

    if (mode === 0) //닫기
    {
      return;
    }
    else if (mode === 1) //확인, 미션창으로 이동하기
    {
      this.handleBottomMenu(1);
    }
  }

  //박스오픈연출창 닫힘
  handleBoxOpenClosed = (gotoPdBox, needTutorial) =>
  {
    this.setShowBoxOpen(false);
    
    if (gotoPdBox)
    {
      NaviStoreInstance.uiFrom = UI_PANGBOX;
      NaviStoreInstance.targetInvenTab = 1;
      this.handleNavi(UI_INVENTORY);
    }
  }

  render()
  {
    const boxCount = this.checkBoxCount();
    let items = null;

    if (IsValidS(PangStoreInstance.itemList))
    {
      items = PangStoreInstance.itemList.map(item => {
        return <PangBoxItem key={item.idx} item={item} handleClick={this.handleProductClick}/>
      });
    }

    return (
      <div className='mainbgcont flexcoltc'>
        <div className='mainbg flexcoltc'>
          {
            this.state.showDetail ? (<PangBoxInfo item={this.editItem} detailInfo={this.detailInfo} handleClose={() => this.setShowDetail(false)} handleBuyBox={this.handleOpenBox}/>) : null
          }
          {
            this.state.showBoxBuy ? (<PangBoxBuy handleBoxBuy={this.handleBuyBox}/>) : null
          }
          {
            this.state.showBoxBuySuc ? (<PangBoxBuySuc handleBoxBuySuc={this.handleBuyBoxSuc}/>) : null
          }
          {
            this.state.showBoxBuyFailed ? (<PangBoxBuyFail handleBuyBoxFail={this.handleBuyBoxFail}/>) : null
          }
          {
            this.state.showBoxOpen ? (
              <PangBoxOpenView itemIdx={this.newBoxIdx} handleClose={this.handleBoxOpenClosed} handleUserItemUpdated={this.handleBoxOpenClosed}
                mode={this.state.boxOpenMode}
                needTutorial={false}
              />
            ) : null
          }
          {
            this.state.showPop === 1 ? (
              <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
            ) : null
          }

          {
            this.state.showPop === 2 ? (
              <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
            ) : null
          }
          {
            this.state.showPop === 3 ? (
              <SelectMsgPop title={this.state.popTitle} msg={this.state.popMsg}
                okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}
              />
            ) : null
          }
          {
            this.state.loading ? (<Loading />) : null
          }
          <div className='main1 flexcoltl'>
            <TopBar withLine={true} handleAlarm={this.handleAlarm} handleHome={() => this.handleBottomMenu(0)}/>
          </div>

          <div className='wd88p rowss mgtop60'>

            <div className='rowcc'>
              <div className='rowcc mgrt15 btncur' onClick={this.handleCashLog}>
                <img src={GetImgUrl("cash3@3x.png")} alt="머니" className='cashimg' />
                <label className='font18 wt500 color31 btncur'>&nbsp;{`${GetFormattedNum(LoginStoreInstance.getUserCash)}`}&nbsp;<span className='font14 wt400'>머니</span></label>
              </div>

              <div className='rowcc btncur' onClick={this.handlePangLog}>
                <img src={GetImgUrl("pang@3x.png")} alt="팡" className='cashimg' />
                <label className='font18 wt500 color31 btncur'>&nbsp;{`${GetFormattedNum(LoginStoreInstance.getUserPang)}`}&nbsp;<span className='font14 wt400'>팡</span></label>
              </div>
            </div>

            <div className='pbivbtn colcc btncur' onClick={this.handleInventory}>
              <img src={GetImgUrl("iv@3x.png")} alt="머니" className='img2520' />
              <label className='font12 wt400 color31 btncur mgtop10'>보관함</label>
            </div>
          </div>

          {
            IsValidV(this.state.lastWinner) ? (<PangBoxWinner info={this.state.lastWinner} handleMoreWinner={this.handleMoreWinner}/>) : null
          }
          
          <div className='wd88p rowlc mgtop50'>
            <label className='font18 wt400 color31'>랜덤쇼핑 팡팡박스</label>
          </div>

          <div className='wd88p rowsc mgtop10'>
            <label className='font15 wt400 color31'>경품 리스트</label>

            <div className='rowcc'>
              <div className={this.state.sortMode === 1 ?  'pbtab1 rowcc mgrt5 btncur' : 'pbtab2 rowcc mgrt5 btncur'} onClick={() => this.handleSortMode(1)}>
                <label className={this.state.sortMode === 1 ? 'font14 wt400 colorff btncur' : 'font14 wt400 colorb3 btncur'}>고가순</label>
              </div>

              <div className={this.state.sortMode === 2 ?  'pbtab1 rowcc mgrt5 btncur' : 'pbtab2 rowcc mgrt5 btncur'} onClick={() => this.handleSortMode(2)}>
                <label className={this.state.sortMode === 2 ? 'font14 wt400 colorff btncur' : 'font14 wt400 colorb3 btncur'}>저가순</label>
              </div>

              <div className={this.state.sortMode === 0 ?  'pbtab1 rowcc mgrt5 btncur' : 'pbtab2 rowcc mgrt5 btncur'} onClick={() => this.handleSortMode(0)}>
                <label className={this.state.sortMode === 0 ? 'font14 wt400 colorff btncur' : 'font14 wt400 colorb3 btncur'}>최신순</label>
              </div>
            </div>
          </div>

          <div className={IsValidS(items) && !this.state.noItem ? 'wd88p coltc mgtop10' : 'wd88p coltc mgtop10 mgbt150'}>
            {items}
          </div>

          {
            IsValidS(items) && !this.state.noItem ? (
              <div className='pbmore mgbt150 mgtop10 rowcc btncur' onClick={this.loadMorePd}>
                <label className='font15 wt400 color31 btncur'>더보기</label>
              </div>
            ) : null
          }

          <div className='pbtestbox rowcc btncur' onClick={this.handleOpenTestBox}>
            <label className='font15 wt400 colorff btncur'>체험<br/>뽑기</label>
          </div>
          
          <div className='pbopenbtn rowcc btncur' onClick={this.handleOpenBox}>
            <img src={GetImgUrl("bb@3x.png")} alt="팡팡박스" className='img2020 mgrt5'/>
            <label className='font18 wt400 colorw btncur mgrt5'>{`${boxCount}개 팡팡박스 구매 가능`}</label>
            <img src={GetImgUrl("arrowr3@3x.png")} alt="팡팡박스" className='img1717'/>
          </div>
          
          <BottomBar selected={2} handleMenu={this.handleBottomMenu}/>

        </div>
      </div>
    );
  }
}

export default observer(withNavigation(PangBox));

