import React from 'react';
import Calendar from 'react-calendar';
import './DatePick.css';
//import moment from "moment";
import 'react-calendar/dist/Calendar.css';
import { IsValidS, IsValidV } from '../../Util/Util';
import { LOG_ENABLE } from '../../URL/AppUrl';

class DatePick extends React.Component {

  state = {
    value : new Date(),
  };

  componentDidMount() {
    if (LOG_ENABLE)
      console.log("DatePick.componentDidMount");

    if (IsValidV(this.props.initDate))
    {
      this.setState({value : this.props.initDate})
    }
    else
      this.setState({value : new Date(this.props.cyear, this.props.cmonth - 1, this.props.cday, 0, 0, 0)})
  }  

  OnDateChange = (date) =>
  {
    if (LOG_ENABLE)
      console.log("DatePick.OnDateChange : " + date);

    this.setState({value : date});

    if (this.props.handleDate !== null && this.props.handleDate !== undefined)
      this.props.handleDate(true, date);

    return true;
  }

  onClickArea = () =>
  {
    if (this.props.handleDate !== null && this.props.handleDate !== undefined)
    this.props.handleDate(false, null);
  }

  render() {
    return (
      <div className="dpcontainer colcc" onClick={(e) => this.onClickArea()}>
        <div className="dpcal colcc" onClick={(e) => {
          e.stopPropagation();
        }}>
          {
            IsValidS(this.props.title) ? (
              <label className=''>{this.props.title}</label>
            ) : null
          }
          <Calendar value={this.state.value} onChange = {this.OnDateChange} minDate={this.props.minDate?this.props.minDate : undefined}/>
        </div>
      </div>
    );
  }
}

export default DatePick;