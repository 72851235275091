import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';

import axios from 'axios';
import { FromBase64, IsValidS, IsValidV } from '../Util/Util';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

class AlarmStore {
  alarmList = null; //서버에서 조회된 알림목록
  newAlarmCount = 0; //새로 수신된 알림의 개수
  lastAlarm = null; //이용자가 알림중 가장최근알림 한개

  //비옵저버 변수
  lastAlarmLoading = false;
  newACClearing = false; //신규알림개수 초기화 요청진행중
  refIdx = 0;

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      alarmList : observable,
      newAlarmCount : observable,
      lastAlarm : observable,
      
      clearAll: action,
      clearAlarm: action,
      processUserPush: action,



      getItemCount: computed,

      parseAlarmListResult: action,
      parseLastAlarmResult: action,
      parseClearAlarmCountResult: action,

    });
  }

  //로그아웃시 클리어 해야 하는 변수를 클리어함
  clearAll = () =>
  {
    this.alarmList = null; //서버에서 조회된 알림목록
    this.newAlarmCount = 0;
    this.lastAlarm = null;
    this.refIdx = 0;
    this.lastAlarmLoading = false;
    this.newACClearing = false; //신규알림개수 초기화 요청진행중
  }

  clearAlarm = () =>
  {
    this.alarmList = null; //서버에서 조회된 알림목록
    this.newAlarmCount = 0;
    this.lastAlarm = null;
    this.refIdx = 0;
    this.lastAlarmLoading = false;
    this.newACClearing = false; //신규알림개수 초기화 요청진행중
  }

  //로그인후 수신된 푸시알림 관련 정보를 처리함
  processUserPush = (pushInfo) =>
  {
    if (!IsValidV(pushInfo))
      return;

    this.newAlarmCount = pushInfo.ac;
  }

  get getItemCount()
  {
    if (!IsValidS(this.alarmList))
      return 0;

    return this.alarmList.length;
  }

  //알림 목록읽기-------------------------------------------------------------------------------------------------------------
  loadAlarmList = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("pos", this.refIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("notice/alarm"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseAlarmListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseAlarmListResult(null, callback);
    });
  }

  parseAlarmListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseAlarmListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeAlarm(item);

          if (this.refIdx === 0 || this.refIdx > item.idx)
            this.refIdx = item.idx;
        });

        if (IsValidS(this.alarmList))
        {
          this.alarmList = [...this.alarmList, ...result.list];
        }
        else
        {
          this.alarmList = result.list;
          this.lastAlarm = this.alarmList[0];
        }
      }

    }

    if (callback)
      callback(result.ret, readCount);
  }

  decodeAlarm = (item) =>
  {
    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
  //최근알림한개 읽기-------------------------------------------------------------------------------------------------------------
  loadLastAlarm = (callback) => {

    //이미 최근알림을 읽고 있다면
    if (this.lastAlarmLoading)
      return;

    this.lastAlarmLoading = true;

    const params = new URLSearchParams();
    params.append("rt", "1");

    axios({
      method:"POST",
      url: GetApiUrl("notice/alarm"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseLastAlarmResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseLastAlarmResult(null, callback);
    });
  }

  parseLastAlarmResult = (result, callback) =>
  {
    this.lastAlarmLoading = false;

    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseLastAlarmResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidV(result.info))
      {
        if (LOG_ENABLE)
          console.log(`alarm is valid`);

        this.decodeAlarm(result.info);
        this.lastAlarm = result.info;
      }
    }

    if (callback)
      callback(result.ret);
  }

  //이용자 신규 알림개수 초기화-------------------------------------------------------------------------------------------------------------
  clearNewAlarmCount = (callback) => {

    this.newACClearing = true; //신규알림개수 초기화 요청진행중
    const params = new URLSearchParams();
    params.append("pidx", this.pushIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("notice/clalcnt"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseClearAlarmCountResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseClearAlarmCountResult(null, callback);
    });
  }

  parseClearAlarmCountResult = (result, callback) =>
  {
    this.newACClearing = false; //신규알림개수 초기화 요청진행중
    
    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseClearAlarmCountResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.newAlarmCount = 0;
    }

    if (callback)
      callback(result.ret);
  }
}

const AlarmStoreInstance = new AlarmStore();
const AlarmStoreContext = React.createContext(AlarmStoreInstance);

const UseAlarmStore = () => React.useContext(AlarmStoreContext);

export {
  UseAlarmStore, 
  AlarmStoreInstance,
};
