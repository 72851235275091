import './Guide.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const getGuideIcon = (item) =>
{
  switch(item.tp)
  {
    case 0:
      return GetImgUrl("gd1.png");
    case 1:
      return GetImgUrl("gd2.png");
    case 2:
      return GetImgUrl("gd3.png");
    default:
      return GetImgUrl("gd1.png");
  }
}

const GuideItem = (props) =>
{
  return (
    <div className='gditem colel btncur' onClick={() => props.handleGuide(props.item)}>
      <div className='wd100p rowsc'>
        <div className='rowcc'>
          <img src={getGuideIcon(props.item)} alt="가이드" className='img1515'/>
          <label className='font14 wt300 color31 btncur'>&nbsp;{props.item.tt}</label>
        </div>

        <img src={GetImgUrl("arrowr2@3x.png")} alt="가이드" className='img510'/>
      </div>

      <div className='gdline mgtop10'></div>
    </div>
  );
}

export default GuideItem;