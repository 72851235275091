import React from 'react';
import './Alarm.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, } from '../../Util/Util';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import TopBar2 from '../Top/TopBar2';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import { observer } from 'mobx-react';
import AlarmItem from './AlarmItem';
import MoreItem from '../Notice/MoreItem';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';

class Alarm extends React.Component
{
  state = {
    loading: false,

    noItem: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,
  };
  intervalId = -1;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      this.loadAlarm();
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    AlarmStoreInstance.clearAlarm();
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadAlarm();
  }

  //알림 목록읽기
  loadAlarm = () =>
  {
    this.setLoading(true);
    AlarmStoreInstance.loadAlarmList(this.onAlarmLoaded);
  }

  onAlarmLoaded = (resultCode, readCount) =>
  {
    this.setLoading(false);

    if (resultCode === 0 && readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleMore = () =>
  {
    if (!this.state.loading)
      this.loadAlarm();
  }

  render()
  {
    let items = null;

    if (IsValidS(AlarmStoreInstance.alarmList))
    {
      items = AlarmStoreInstance.alarmList.map(item => {
        return <AlarmItem key={item.idx} item={item} />
      });
    }
    
    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 withLine={false} title="알림" handleBack={this.handleBack}/>

        <div className='wd100p max500 flexcoltc'>
          <div className='alinfobg flexrowcc'>
            <div className='almain1 flexrowlc'>
              <label className='font12 weight400 colorb3'>※ 최근 60일간의 알림만 표시됩니다.</label>
            </div>
          </div>

          <div className='alempty'></div>
          
          <div className='almain1 flexcoltc'>
            {items}
          </div>

          {
            !this.state.noItem ? (<MoreItem handleClick={this.handleMore}/>) : (<div className='emptyTag'></div>)
          }  
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(Alarm));

