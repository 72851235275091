import './Setting.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';

const SettingItem = (props) =>
{
  return (
    <div className='setitemcont rowsc btncur' onClick={props.handleMenu}>
      <label className='font14 color31 wt400 btncur'>{props.title}</label>

      <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='img510' />
    </div>
  );
}

export default SettingItem;