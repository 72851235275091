import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { FromBase64, IsValidS, ToBase64, cancelAxiosRequest } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

//고객센터 관련 api처리
class FaqStore {
  faqList = null; //faq 목록
  myList = null; //나의 문의 내역

  faqRefIdx = 0; //읽은 faq중 고유번호가 가장큰것
  myListRefIdx = 0; //읽은 문의 목록중 고유번호가 가장 작은것

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      faqList : observable,
      myList : observable,
      
      getFaqCount: computed,
      getMyListCount: computed,

      clearAll: action,
      clearFaq:  action,
      clearMyList:  action,

      parseFaqListResult:  action,
      parseMyListResult:  action,
    });
  }

  clearAll = () =>
  {
    this.faqList = null; //faq 목록
    this.myList = null; //나의 문의 내역
    this.faqRefIdx = 0; //faq 목록
    this.myListRefIdx = 0; //나의 문의 내역
  }

  clearFaq = () =>
  {
    this.faqList = null; //faq 목록
    this.faqRefIdx = 0;
  }

  clearMyList = () =>
  {
    this.myList = null; //나의 문의 내역
    this.myListRefIdx = 0;
  }

  //faq목록의 개수를 구함
  get getFaqCount()
  {
    if (!IsValidS(this.faqList))
      return 0;

    return this.faqList.length;
  }

  //나의 문의 목록 개수를 구함
  get getMyListCount()
  {
    if (!IsValidS(this.myList))
      return 0;

    return this.myList.length;
  }
  
  //faq 목록읽기-------------------------------------------------------------------------------------------------------------

  //nt : 전체(-1), 캐시업(1), 팡팡박스(2), 서비스(3)
  loadFaqList = (nt, callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("nt", nt.toString());
    params.append("pos", this.faqRefIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("faq/cplist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseFaqListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseFaqListResult(null, callback);
    });
  }

  parseFaqListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseFaqListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeFaq(item);

          if (this.faqRefIdx === 0 || this.faqRefIdx < item.idx)
            this.faqRefIdx = item.idx;
        });

        if (IsValidS(this.faqList))
        {
          this.faqList = [...this.faqList, ...result.list];
        }
        else
          this.faqList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeFaq = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
  //나의 문의 목록읽기-------------------------------------------------------------------------------------------------------------

  loadMyList = (callback) => {

    const params = new URLSearchParams();
    params.append("pos", this.myListRefIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("faq/myqr/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseMyListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseMyListResult(null, callback);
    });
  }

  parseMyListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseMyListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeMyList(item);

          if (this.myListRefIdx === 0 || this.myListRefIdx > item.idx)
            this.myListRefIdx = item.idx;
        });

        if (IsValidS(this.myList))
        {
          this.myList = [...this.myList, ...result.list];
        }
        else
          this.myList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeMyList = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.dc))
      item.dc = FromBase64(item.dc);
  }
  //문의 등록하기-------------------------------------------------------------------------------------------------------------

  registerQuery = (tt, dc, em, img1, img2, img3, callback) => {

    const params = new URLSearchParams();
    params.append("tt", ToBase64(tt));
    params.append("dc", ToBase64(dc));
    params.append("em", em);
    params.append("img1", img1);
    params.append("img2", img2);
    params.append("img3", img3);

    if (LOG_ENABLE)
      console.log(`registerQuery : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("faq/usqr/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseRegisterQueryResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseRegisterQueryResult(null, callback);
    });
  }

  parseRegisterQueryResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseRegisterQueryResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
  //제휴 문의 등록하기-------------------------------------------------------------------------------------------------------------

  registerAffQuery = (cn, ph, un, em, dc, af1, afori, callback) => {

    const params = new URLSearchParams();
    params.append("cn", ToBase64(cn));
    params.append("ph", ph);
    params.append("un", ToBase64(un));
    params.append("em", em);
    params.append("dc", ToBase64(dc));
    params.append("af1", af1);
    params.append("afori", ToBase64(afori));

    axios({
      method:"POST",
      url: GetApiUrl("faq/affqr/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseRegisterAffQueryResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseRegisterAffQueryResult(null, callback);
    });
  }

  parseRegisterAffQueryResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseRegisterAffQueryResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
}

const FaqStoreInstance = new FaqStore();
const FaqStoreContext = React.createContext(FaqStoreInstance);

const UseFaqStore = () => React.useContext(FaqStoreContext);

export {UseFaqStore, FaqStoreInstance};
