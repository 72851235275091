import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV, ToBase64, cancelAxiosRequest } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

//빙고게임관련 처리
class BingoGameStore {
  
  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {

    });
  }

  clearAll = () =>
  {
  }

  //샘플게임 리소스 주소 획득-------------------------------------------------------------------------------------------------------------

  loadSampleGame = (gameCode, callback) => {

    const params = new URLSearchParams();

    params.append("gc", gameCode.toString());

    axios({
      method:"POST",
      url: GetApiUrl("adg/bgsp/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseSampleGameResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseSampleGameResult(null, callback);
    });
  }

  parseSampleGameResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, null);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseSampleGameResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, FromBase64(result.url));
    }
  }
}

const BingoGameStoreInstance = new BingoGameStore();
const BingoGameStoreContext = React.createContext(BingoGameStoreInstance);

const UseBingoGameStore = () => React.useContext(BingoGameStoreContext);

export {UseBingoGameStore, BingoGameStoreInstance};
