import React from 'react';
import './AffPage.css';
import '../../App.css';
import { LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { IsValidS, IsValidV, UploadFile, checkEmail } from '../../Util/Util';
import { FaqStoreInstance } from '../../Stores/FaqStore';


//제휴및 광고문의
class AffPage extends React.Component
{
  state = {
    loading: false,
    
    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    cname: '',
    phone: '',
    uname: '',
    email: '',
    desc: '',

    fileName: '',
    oriName: '',
    file: null,
  };
  intervalId = -1;
  
  isValid = () =>
  {
    if (!IsValidS(this.state.cname) || !IsValidS(this.state.phone) || !IsValidS(this.state.uname) || !IsValidS(this.state.email) || !IsValidS(this.state.desc))
      return false;

    return true;
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setFilename = (v) =>
  {
    this.setState((prevState) => ({...prevState, fileName : v}));
  }

  setOriname = (v) =>
  {
    this.setState((prevState) => ({...prevState, oriName : v}));
  }

  setFile = (v) =>
  {
    this.setState((prevState) => ({...prevState, file : v}));
  }

  setValue = (index, v) =>
  {
    switch(index)
    {
      case 0:
        this.setState((prevState) => ({...prevState, cname : v}));
        return;
      case 1:
        this.setState((prevState) => ({...prevState, phone : v}));
        return;
      case 2:
        this.setState((prevState) => ({...prevState, uname : v}));
        return;
      case 3:
        this.setState((prevState) => ({...prevState, email : v}));
        return;
      case 4:
        this.setState((prevState) => ({...prevState, desc : v}));
        return;
      default:
        return;
    }
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

  }

  handleDone = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.cname))
    {
      this.setShowPopup(1, '업체명을 입력해 주세요.', "확인", '', null) ;
      return;
    }

    if (!IsValidS(this.state.phone))
    {
      this.setShowPopup(1, '담당자 연락처를 입력해 주세요.', "확인", '', null) ;
      return;
    }

    if (!IsValidS(this.state.uname))
    {
      this.setShowPopup(1, '담당자분 성함을 입력해 주세요.', "확인", '', null) ;
      return;
    }

    if (!IsValidS(this.state.email))
    {
      this.setShowPopup(1, '연락받을 이메일 주소를 입력해 주세요.', "확인", '', null) ;
      return;
    }

    if (!checkEmail(this.state.email))
    {
      this.setShowPopup(1, '이메일 주소가 올바르지 않습니다.', "확인", '', null) ;
      return;
    }

    if (!IsValidS(this.state.desc))
    {
      this.setShowPopup(1, '문의 내용을 입력해 주세요.', "확인", '', null) ;
      return;
    }

    this.setShowPopup(2, "문의 내용을 전송할까요?", "확인", "취소", (yes) => {
      this.closePopup();

      if(!yes)
        return;

      this.setLoading(true);

      if (this.state.file)
      {
        UploadFile(5, this.state.file, (success, oldName, newName) => {
          if (!success)
          {
            this.setShowPopup(1, '첨부파일 업로드에 실패 했습니다.', "확인", '', null) ;
            this.setLoading(false);
            return;
          }

          FaqStoreInstance.registerAffQuery(this.state.cname, this.state.phone, this.state.uname, this.state.email, 
            this.state.desc, newName, oldName, this.onRegisterDone);  
        });
      }
      else
      {
        
        FaqStoreInstance.registerAffQuery(this.state.cname, this.state.phone, this.state.uname, this.state.email, 
          this.state.desc, "n", "n", this.onRegisterDone);
      }
      

    });
  }

  onRegisterDone = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setShowPopup(1, "접수가 완료 되었습니다.", "확인", "", () => {
          this.closePopup();
          this.handleBack();
        });
        return;
      case 1:
        this.setShowPopup(1, "잘못된 요청입니다", "확인", "", null);
        return;
      case 3:
        this.setShowPopup(1, "답변 받으실 이메일 주소를 다시 한번 확인해 주세요.", "확인", "", null);
        return;
      default:
        this.setShowPopup(1, `문의 사항 접수 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.\n오류코드 ${resultCode}`, "확인", "", null);
        return;
    }
  }

  handleAddFile = () =>
  {
    const v = document.getElementById("fileInput");

    if (v)
      v.click();
  }

  render()
  {
    const valid = this.isValid();

    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='wd100p max500 flexcoltc'>
          <TopBar2 withLine={true} title="제휴 및 광고문의" handleBack={this.handleBack} />

          <div className='mgtop70'></div>

          <div className='wd88p coltl mgbt100'>
            <label className='font16 wt400 color31 lineheight24'>머니팡과 함께 나아 갈<br/>파트너를 기다리고 있습니다.</label>
            
            <label className='font12 wt400 colorb3 mgtop10'>담당자가 확인 후 빠른 시일 내에 연락 드리겠습니다.</label>

            <label className='font12 wt500 color31 mgtop20'>업체명</label>
            <input type='text' value={this.state.cname} onChange={(e) =>this.setValue(0, e.target.value)} className='afinp font14 color31 wt300 mgtop5' placeholder='업체명을 입력해 주세요.'/>

            <label className='font12 wt500 color31 mgtop20'>담당자 연락처</label>
            <input type='text' value={this.state.phone} onChange={(e) =>this.setValue(1, e.target.value)} className='afinp font14 color31 wt300 mgtop5' placeholder='"-"를 제외하고 입력해 주세요.'/>

            <label className='font12 wt500 color31 mgtop20'>담당자 성함</label>
            <input type='text' value={this.state.uname} onChange={(e) =>this.setValue(2, e.target.value)} className='afinp font14 color31 wt300 mgtop5' placeholder='담당자 성함을 입력해 주세요.'/>

            <label className='font12 wt500 color31 mgtop20'>이메일</label>
            <input type='text' value={this.state.email} onChange={(e) =>this.setValue(3, e.target.value)} className='afinp font14 color31 wt300 mgtop5' placeholder='이메일 주소를 입력해 주세요.'/>

            <label className='font12 wt500 color31 mgtop20'>문의내용</label>
            <textarea value={this.state.desc} onChange={(e) =>this.setValue(4, e.target.value)} className='afinp2 font14 color31 wt300 mgtop5' placeholder='문의할 내용을 자세하게 작성해 주세요.'/>

            <button className='affbtn2 font12 colorb3 wt400 btncur mgtop20' onClick={this.handleAddFile}>+ 첨부파일</button>

            {
              IsValidS(this.state.oriName) ? (
                <div className='afffilename mgtop20'>
                  <label className='font12 wt300 color31'>{this.state.oriName}</label>
                </div>
              ) : null
            }
          </div>
          
          <button className={ valid ? 'affbtn bgcolorff00 font14 colorw wt400 btncur' : 'affbtn bgcolorf2 font14 colorb3 wt400'} onClick={this.handleDone}>작성완료</button>

          <input type="file" id="fileInput" className="afffile" accept="image/*, video/*, audio/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .zip, .tar, .alz, .rar, .7z, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            onChange={(e) => {

              if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
                return;

              if (LOG_ENABLE)
                console.log(e.target.files[0]);

              if (e.target.files[0].size > ( 5 * 1024 * 1024))
              {
                this.setShowPopup(1, "5MB이하의 파일만 첨부 가능합니다.", "확인", "", null);
                return;
              }

              this.setFile(e.target.files[0]);
              this.setOriname(e.target.files[0].name);
          }}/>
        </div>

      </div>
    );
  }
}

export default observer(withNavigation(AffPage));

