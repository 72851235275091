import React, { Fragment } from 'react';
import './HelpPage.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { FaqStoreInstance } from '../../Stores/FaqStore';
import { IsValidS, IsValidV, UploadFile, checkEmail } from '../../Util/Util';
import HelpQueryListItem from './HelpQueryListItem';
import HelpQueryDetail from './HelpQueryDetail';



//고객센터 문의내역
class HelpQueryList extends React.Component
{
  state = {
    loading: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    showDetail: false,
    noItem: false,
  };

  selectedItem = null;

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setShowMsgPop = (msg, callback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 1, popMsg: msg, popOkBtn: "확인", popCancelBtn: '', popupCallback: callback}));
  }
  
  setShowDetail = (v) =>
  {
    this.setState((prevState) => ({...prevState, showDetail : v}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  componentDidMount() {
    this.setLoading(true);
    this.setNoItem(false);
    FaqStoreInstance.loadMyList(this.onLoadList);
  }

  componentWillUnmount = () =>
  {
    FaqStoreInstance.clearMyList();
  }

  onLoadList = (resultCode, readCount) =>
  {
    this.setLoading(false);

    if (resultCode === 0 && readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleClose = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  handleMore = () =>
  {
    if (this.state.loading)
      return;

      this.setLoading(true);
      FaqStoreInstance.loadMyList(this.onLoadList);
  }

  //문의 내역 항목 선택됨
  handleSelect = (item) =>
  {
    if (this.state.loading)
      return;

    this.selectedItem = item;
    this.setShowDetail(true);
  }

  render()
  {
    let queryList = null;

    if (IsValidS(FaqStoreInstance.myList))
    {
      queryList = FaqStoreInstance.myList.map(item => <HelpQueryListItem key={item.idx} item={item} handleSelect={this.handleSelect}/>);
    }

    return (
      <div className='hpquerypop coltc vscroll'>
        {
          this.state.showDetail ? (<HelpQueryDetail item={this.selectedItem} handleClose={() => this.setShowDetail(false)}/>) : null
        }
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 title="문의내역" withLine={true} handleBack={this.handleClose} />

        <div className='wd100p max500 coltc mgtop40'>
          <div className='hqpdescbg rowcc'>
            <div className='hqpdesc rowlc'>
              <label className='font12 wt400 colorb3'>※ 답변 완료된 문의는 이메일 주소로 발송되니 메일함을 확인해 주세요.</label>
            </div>
          </div>

          <div className='wd88p coltc'>
            {
              IsValidS(queryList) ? queryList : (
                <Fragment>
                  <img src={GetImgUrl("help3.png")} alt="문의" className='img2120 mgtop100'/>
                  <label className='font12 color31 wt300 mgtop10'>최근 문의내역이 없습니다.</label>
                </Fragment>
              )
            }
          </div>

          {
            !this.state.noItem && IsValidS(queryList) ? (
              <div className='hpqlbtn1 rowcc font14 wt400 color31 btncur mgtop20 mgbt100' onClick={this.handleMore}>더보기</div>
            ) : (
              <div className='wd100p mgbt100'></div>
            )
          }
          
        </div>

      </div>
    );
  }
}

export default observer(HelpQueryList);

