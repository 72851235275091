import './HelpPage.css';
import '../../App.css';
import { GetDataDateAndTime } from '../../Util/Util';
import { Fragment } from 'react';

const HelpQueryListItem = (props) =>
{
  return (
    <Fragment>
      <div className='wd100p colcl mgtop10 btncur' onClick={() => props.handleSelect(props.item)}>
        <label className='font14 wt300 color31 btncur'>{props.item.tt}</label>
        <label className='font12 wt400 colorb3 btncur mgtop10'>{GetDataDateAndTime(props.item.rd)}</label>
      </div>

      <div className='hpline mgtop10'></div>
    </Fragment>
  );
}

export default HelpQueryListItem;