import './HelpPage.css';
import '../../App.css';
import { Fragment, useState } from 'react';
import { GetImgUrl } from '../../URL/AppUrl';

const HelpPageItem = (props) =>
{
  const [expand, setExpand] = useState(false);

  if (expand)
  {
    return (
      <Fragment>
        <div className='wd100p rowss mgtop15 btncur' onClick={() => setExpand(!expand)}>
          <label className='font12 wt400 colorffc8 btncur'>Q</label>
          
          <div className='hpitemtext coltl'>
            <label className='font12 wt300 color31 btncur'>{props.item.tt}</label>
            <label className='font12 wt300 color31 btncur mgtop5'>{props.item.dc}</label>
          </div>
    
          <img src={GetImgUrl("up.png")} alt="보기" className='img105'/>
        </div>

        <div className='hpline mgtop15'></div>
      </Fragment>
    );
  }
  else
  {
    return (
      <Fragment>
        <div className='wd100p rowsc mgtop15 btncur' onClick={() => setExpand(!expand)}>
          <label className='font12 wt400 colorffc8 btncur'>Q</label>
          
          <div className='hpitemtext coltl'>
            <label className='font12 wt300 color31 btncur'>{props.item.tt}</label>
          </div>
    
          <img src={GetImgUrl("down.png")} alt="보기" className='img105'/>
        </div>

        <div className='hpline mgtop15'></div>
      </Fragment>
    );
  }
  
}

export default HelpPageItem;