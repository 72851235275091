class NaviStore {
  uiFrom = -1;
  targetTab = -1; //대상 미션탭
  targetInvenTab = -1; //인벤토리 대상탭, 박스보관함(0), 상품보관함(1)
  
  setUiFrom = (uiIndex) =>
  {
    this.uiFrom = uiIndex;
  }
}

const UI_LOGIN = 0;
const UI_HOME = 1;
const UI_ALARM = 2;
const UI_NOTICE = 3;
const UI_MYPANG = 4;
const UI_SETTING = 5;
const UI_SNSACC = 6;
const UI_HELPCENTER = 7;
const UI_ADDRESS = 8;
const UI_QUERY = 9;
const UI_GUIDE = 10;
const UI_PANGBOX = 11;
const UI_WINNER = 12;
const UI_INVENTORY = 13;
const UI_CASHLOG = 14;
const UI_PANGLOG = 15;
const UI_MISSION = 16;

const NaviStoreInstance = new NaviStore();

export {

  NaviStoreInstance,

  UI_LOGIN,
  UI_HOME,
  UI_ALARM,
  UI_NOTICE,
  UI_MYPANG,
  UI_SETTING,
  UI_SNSACC,
  UI_HELPCENTER,
  UI_ADDRESS,
  UI_QUERY,
  UI_GUIDE,
  UI_PANGBOX,
  UI_WINNER,
  UI_INVENTORY,
  UI_CASHLOG,
  UI_PANGLOG,
  UI_MISSION,
};
