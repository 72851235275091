import React from 'react';

import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';

import axios from 'axios';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';
import { GetTodayDateSerial, IsValidS, IsValidV } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//캐로셀광고 수신관련
class AdsStore {
  //옵저버 변수
  homeAdsList = null; //홈페이지 캐로셀광고
  homePopup = null; //홈페이지 팝업광고

  //비옵저버변수
  homeAdsReady = false;

  clearAll = () =>
  {
    this.homeAdsList = null; //홈페이지 캐로셀광고
    this.homePopup = null; //홈페이지 팝업광고

    //비옵저버변수
    this.homeAdsReady = false;
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      homeAdsList : observable,
      homePopup : observable,

      clearAll: action,
      parseHomeAdsResult: action,
    });
  }

  //홈페이지 광고 목록조회----------------------------------------------------------------------------------------------------------------------
  loadHomepageAds = (callback) => {
    this.homeAdsReady = false;

    axios({
      method:"POST",
      url: GetApiUrl("ads/homeads"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      this.parseHomeAdsResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseHomeAdsResult(null, callback);
    });
  }

  parseHomeAdsResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseHomeAdsResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.homeAdsReady = true;

      if (IsValidS(result.list))
      {
        const currentDate = GetTodayDateSerial();
        this.homeAdsList = null;

        //시간값을 확인해서 노출 가능한것만 목록에 추가
        for (let i = 0;i < result.list.length; ++i)
        {
          if ((result.list[i].stm === 0 || result.list[i].stm <= currentDate) &&
            (result.list[i].etm === 0 || result.list[i].etm >= currentDate))
          {
            if (!IsValidS(this.homeAdsList))
              this.homeAdsList = [];

            this.homeAdsList.push(result.list[i]);
          }
        }

        if (LOG_ENABLE)
          console.log(`home ads found : ${IsValidS(this.homeAdsList) ? this.homeAdsList.length : 0}`);
      }

      if (IsValidV(result.pop))
        this.homePopup = result.pop;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
}

const AdsStoreInstance = new AdsStore();
const AdsStoreContext = React.createContext(AdsStoreInstance);

const UseAdsStore = () => React.useContext(AdsStoreContext);

export {UseAdsStore, AdsStoreInstance};
