import React from 'react';

import './MyPang.css';
import '../../App.css';
import { GetImgUrl, GotoAppStore, GotoLogin, LOG_ENABLE, getPrivacyUrl, getTermUrl, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  GetFormattedNum, IsValidV, getProfileImage, getShortedPathName, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import TopBar from '../Top/TopBar';

import { LoginStoreInstance } from '../../Stores/LoginStore';

import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import BottomBar from '../Bottom/BottomBar';

import Unregister from '../Unregister/Unregister';

import { observer } from 'mobx-react';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import { NaviStoreInstance, UI_ADDRESS, UI_ALARM, UI_CASHLOG, UI_GUIDE, UI_HELPCENTER, UI_HOME, UI_MISSION, UI_MYPANG, UI_NOTICE, UI_PANGBOX, UI_PANGLOG, UI_QUERY, UI_SETTING, UI_SNSACC } from '../../Stores/NaviStore';
import ChangeNickname from './ChangeNickname';


//전체 페이지
class MyPang extends React.Component
{
  state = {
    loading: false,

    showUnReg: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    showNickname: false, //닉네임 변경창
  };
  intervalId = -1;
  
  loading = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  showUnreg = (v) =>
  {
    this.setState((prevState) => ({...prevState, showUnReg: v}));
  }

  showNickname = (v) =>
  {
    this.setState((prevState) => ({...prevState, showNickname: v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    GotoLogin();
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }

    if (!this.loading)
    {
      
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 20);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      if (!AlarmStoreInstance.lastAlarmLoading)
      {
        this.setLoading(true);
        AlarmStoreInstance.loadLastAlarm(this.onLastAlarmLoaded);
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    if (!AlarmStoreInstance.lastAlarmLoading)
    {
      this.setLoading(true);
      AlarmStoreInstance.loadLastAlarm(this.onLastAlarmLoaded);
    }
  }

  onLastAlarmLoaded = (resultCode) =>
  {
    this.setLoading(false);
  }

  handleTerm = () =>
  {
    //this.showTerm(true);
    window.open(getTermUrl(), "_blank");
  }

  handlePrivacy = () =>
  {
    //this.showPrivacy(true);
    window.open(getPrivacyUrl(), "_blank");
  }
  
  handleAlarm = () =>
  {
    //this.setShowPopup(2, "알림은 머니팡 앱에서 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_MYPANG);
    this.handleNavi(UI_ALARM);
  }

  handleAppInstall = (yes) =>
  {
    this.setShowPopup(0, null, null, null, null);

    if (yes)
    {
      //앱설치 페이지로 이동한다.
      GotoAppStore();
    }
  }

  handleBottomMenu = (menuIndex) =>
  {
    switch(menuIndex)
    {
      case 0:
        this.handleNavi(UI_HOME);
        return;
      case 2:
        //this.setShowPopup(2, "머니쇼핑은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        this.handleNavi(UI_PANGBOX);
        return;
      case 3:
        return;
      case 1:
      case 5:
        //this.setShowPopup(2, "미션 적립은 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        this.handleNavi(UI_MISSION);
        return;
      default:
        return;
    }
  }

  //회원탈퇴신청이 완료됨
  handleUnregisterDone = () =>
  {
    this.processLogOut();
  }

  handleChangeNickname = () =>
  {
    if (this.state.loading)
      return;

    this.showNickname(true);
  }

  handleCenterMenu = (index) =>
  {
    if (this.state.loading)
      return;

    if (LOG_ENABLE)
      console.log(`handleCenterMenu : ${index}`);

    switch(index)
    {
      case 0://계정등록
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_SNSACC);
        return;
      case 1: //환급받기
        this.setShowPopup(2, "환급 받기는 현재 머니팡 앱에서만 이용할 수 있습니다.\n앱을 설치할까요?", "설치하기", "취소", this.handleAppInstall);
        return;
      case 2: //공지사항
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_NOTICE);
        return;
      case 3: //고객센터
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_HELPCENTER);
        return;
      case 4: //배송지관리
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_ADDRESS);
        return;
      case 5: //제휴및 광고문의
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_QUERY);
        return;
      case 6: //이용가이드
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_GUIDE);
        return;
      case 7: //설정
        NaviStoreInstance.setUiFrom(UI_MYPANG);
        this.handleNavi(UI_SETTING);
        return;
      case 8: //로그아웃
        this.setShowPopup(2, "로그아웃 하시겠습니까?", "로그아웃", "취소", (yes) => {
          this.closePopup();

          if (yes)
          {
            this.processLogOut();
          }
        });
        return;
      case 9: //회원탈퇴
        this.showUnreg(true);
        return;
      default:
        return;
    }
  }

  handleCashLog = () =>
  {
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_MYPANG);
    this.handleNavi(UI_CASHLOG);
  }

  handlePangLog = () =>
  {
    if (this.state.loading)
      return;

    NaviStoreInstance.setUiFrom(UI_MYPANG);
    this.handleNavi(UI_PANGLOG);
  }

  render()
  {
    return (
      <div className='mainbgcont flexcoltc'>
        <div className='mainbg flexcoltc'>
          {
            this.state.showUnReg ? (
              <Unregister handleClose={() => this.showUnreg(false)} handleUnregisterDone={this.handleUnregisterDone}/>
            ) : null
          }
          {
            this.state.showNickname ? (<ChangeNickname handleClose={() => this.showNickname(false)}/>) : null
          }
          {
            this.state.showPop === 1 ? (
              <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
            ) : null
          }

          {
            this.state.showPop === 2 ? (
              <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
            ) : null
          }
          {
            this.state.loading ? (<Loading />) : null
          }
          <div className='main1 flexcoltl'>
            <TopBar withLine={true} handleAlarm={this.handleAlarm} handleHome={() => this.handleBottomMenu(0)}/>
          </div>

          <div className='mpprf colcc'>
            <div className='wd88p rowsc'>
              <div className='colcl'>
                <label className='font16 wt400 color31'>{LoginStoreInstance.getUserName}</label>
                <button className='mpnmbtn font14 colorb3 wt400 btncur mgtop5' onClick={this.handleChangeNickname}>닉네임 변경</button>
              </div>

              <div className='mpimgbg rowcc'>
                <img src={getProfileImage(LoginStoreInstance.getProfileImage)} alt="프로필" className='mpimg'/>
              </div>
            </div>

            <div className='wd88p rowsc btncur mgtop20'>
              <div className='mpcoin rowsc' onClick={this.handleCashLog}>
                <div className='rowcc'>
                  <img src={GetImgUrl("cash3@3x.png")} alt="머니" className='cashimg' />
                  <label className='font18 wt500 color31 btncur'>&nbsp;{`${GetFormattedNum(LoginStoreInstance.getUserCash)}`}&nbsp;<span className='font14 wt400'>머니</span></label>
                </div>

                <img src={GetImgUrl("arrowr1@3x.png")} alt="보기" className='mparrimg'/>
              </div>

              <div className='mpcoin rowsc btncur' onClick={this.handlePangLog}>
                <div className='rowcc'>
                  <img src={GetImgUrl("pang@3x.png")} alt="머니" className='cashimg' />
                  <label className='font18 wt500 color31 btncur'>&nbsp;{`${GetFormattedNum(LoginStoreInstance.getUserPang)}`}&nbsp;<span className='font14 wt400'>팡</span></label>
                </div>

                <img src={GetImgUrl("arrowr1@3x.png")} alt="보기" className='mparrimg'/>
              </div>
            </div>

            <div className='wd88p rowsc btncur mgtop20'>
              <div className='mpbtn1 colevenc btncur' onClick={() => this.handleCenterMenu(0)}>
                <img src={GetImgUrl("account.png")} alt="보기" className='mpbtnimg2520'/>
                <label className='font14 wt400 color31 btncur'>계정등록</label>
              </div>

              <div className='mpbtn1 colevenc btncur' onClick={() => this.handleCenterMenu(1)}>
                <img src={GetImgUrl("wd@3x.png")} alt="보기" className='mpbtnimg2320'/>
                <label className='font14 wt400 color31 btncur'>환급받기</label>
              </div>

              <div className='mpbtn1 colevenc btncur' onClick={() => this.handleCenterMenu(2)}>
                <img src={GetImgUrl("notice.png")} alt="보기" className='mpbtnimg2520'/>
                <label className='font14 wt400 color31 btncur'>공지사항</label>
              </div>

              <div className='mpbtn1 colevenc btncur' onClick={() => this.handleCenterMenu(3)}>
                <img src={GetImgUrl("help.png")} alt="보기" className='mpbtnimg2220'/>
                <label className='font14 wt400 color31 btncur'>고객센터</label>
              </div>
            </div>

            {
              IsValidV(AlarmStoreInstance.lastAlarm) ? (
                <div className='mpntc rowlc btncur mgtop15' onClick={this.handleAlarm}>
                  <label className='font14 colorff wt400 btncur'>최근 알림</label>
                  <label className='font14 color31 wt300 btncur'>&nbsp;{getShortedPathName(AlarmStoreInstance.lastAlarm.dc, 24)}</label>
                </div>
              ) : null
            }
          </div>

          <div className='mpmn rowsc mgtop10 btncur' onClick={() => this.handleCenterMenu(4)}>
            <div className='rowcc'>
              <img src={GetImgUrl("mnaddr@3x.png")} alt="메뉴" className='mpbtnimg1515 mgrt5'/>
              <label className='font15 wt300 color1e btncur'>배송지 관리</label>
            </div>

            <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='mpbtnimg510'/>
          </div>

          <div className='mpmn rowsc mgtop10 btncur' onClick={() => this.handleCenterMenu(5)}>
            <div className='rowcc'>
              <img src={GetImgUrl("mnquery@3x.png")} alt="메뉴" className='mpbtnimg1515 mgrt5'/>
              <label className='font15 wt300 color1e btncur'>제휴 및 광고문의</label>
            </div>

            <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='mpbtnimg510'/>
          </div>

          <div className='mpmn rowsc mgtop10 btncur' onClick={() => this.handleCenterMenu(6)}>
            <div className='rowcc'>
              <img src={GetImgUrl("mnquide@3x.png")} alt="메뉴" className='mpbtnimg1515 mgrt5'/>
              <label className='font15 wt300 color1e btncur'>이용가이드</label>
            </div>

            <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='mpbtnimg510'/>
          </div>

          <div className='mpmn rowsc mgtop10 btncur' onClick={() => this.handleCenterMenu(7)}>
            <div className='rowcc'>
              <img src={GetImgUrl("mnset@3x.png")} alt="메뉴" className='mpbtnimg1515 mgrt5'/>
              <label className='font15 wt300 color1e btncur'>설정</label>
            </div>

            <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='mpbtnimg510'/>
          </div>

          <div className='mpmn rowsc mgtop10 btncur' onClick={() => this.handleCenterMenu(8)}>
            <div className='rowcc'>
              <img src={GetImgUrl("mnset@3x.png")} alt="메뉴" className='mpbtnimg1515 mgrt5'/>
              <label className='font15 wt300 color1e btncur'>로그아웃</label>
            </div>

            <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='mpbtnimg510'/>
          </div>

          <div className='mpmn rowsc mgtop50 btncur' onClick={() => this.handleCenterMenu(9)}>
            <div className='rowcc'>
              <img src={GetImgUrl("mnset@3x.png")} alt="메뉴" className='mpbtnimg1515 mgrt5'/>
              <label className='font15 wt300 color1e btncur'>회원탈퇴</label>
            </div>

            <img src={GetImgUrl("arrowr2@3x.png")} alt="메뉴" className='mpbtnimg510'/>
          </div>
          <BottomBar selected={3} handleMenu={this.handleBottomMenu}/>

        </div>
      </div>
    );
  }
}

export default observer(withNavigation(MyPang));

