import axios from "axios";
import { GetImgUrl, GetProfileImgUrl, GetUploadApiUrl, LOG_ENABLE, mobileOn } from "../URL/AppUrl";
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';

const defaultKey_hmac = "dGdrefw32fJd37#97fjedEpsdt9G2GDF";

function NumberFormatString(num)
{
  if (num < 10)
    return "0" + num;

  return num.toString();
}

//세자리 마다 콤마 붙이기
function GetFormattedNum(num)
{
  if (isNaN(num))
    return "";

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function DownloadFile(url, fileName, logPrinter = null)
{
  try
  {
    if (LOG_ENABLE)
      console.log(`download file : ${url} => ${fileName}`);

    /*axios({
      url: url,
      method: 'GET',
      withCredentials: false,
      responseType: 'blob',
      data: ""
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    })
    .catch(error=>{

      if (logPrinter)
        logPrinter(error);
      else
        console.log(error);
        
      alert(fileName + " 파일을 다운로드중 오류가 발생했습니다.");
    });*/

    saveAs(url, fileName);
  }
  catch(e)
  {
    alert(e.toString());
  }
}

//각종 파일을 업로드한다.
//uploadType : SNS플라이사업자등록증(0), 머니팡프로필이미지(1), SMS플라이 웹사이트 이미지(2), 머니팡상품이미지(3), 머니팡문의내역이미지(4)
function UploadFile(uploadType, file, callback)
{
  const formData = new FormData();

  formData.append("file", file);
  formData.append("rt", uploadType.toString());

  axios({
    method:"POST",
    url:  GetUploadApiUrl("upload/up1/"),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
    responseEncoding: 'utf8', // 기본 값
    data: formData
  })
  .then((res)=>{

    if (LOG_ENABLE)
    {
      console.log("upload done");
      console.log(JSON.stringify(res.data));
    }

    //결과값은 결과 코드와, 성공일경우 업로드된 서버사운드파일이름이다.
    if (!IsValidV(res.data) || res.data.ret === undefined || res.data.ret !== 0)
    {
      if (LOG_ENABLE)
        console.log("invalid result");

      callback(false, null, null);
      return;
    }
    else //업로드에 성공했다.
    {
      callback(true, res.data.of, res.data.nf);
    }
  })
  .catch(error=>{

    if (LOG_ENABLE)
      console.log(error);

    callback(false, null, null);
  });
}

function ToBase64(v)
{
  if (v === undefined || v=== null || v.length < 1)
    return v;

  return Buffer.from(v, "utf8").toString("base64");
}

function FromBase64(v)
{
  if (v === undefined || v=== null || v.length < 1)
    return v;

  return Buffer.from(v, "base64").toString("utf-8");
}

function IsValidS(v)
{
  return v !== undefined && v !== null && v.length > 0;
}

function IsValidV(v)
{
  return v !== undefined && v !== null;
}

//사업자 번호를 000-00-00000 형식으로 얻음
function GetFormattedCNum(cnum)
{
  if (!IsValidS(cnum))
    return "";

  if (cnum.length !== 10)
    return cnum;

  return cnum.substring(0, 3) + "-" + cnum.substring(3, 5) + "-" + cnum.substring(5, 10);
}

//사업자 번호를 000-00-00000 형식으로 얻음
function GetFormattedCNum2(cnum)
{
  if (!IsValidS(cnum))
    return "";

  let temp = cnum;

  if (temp.indexOf('-') >= 0)
  {
    temp = temp.replace(/-/gi, '');
  }

  if (isNaN(temp))
  {
    return cnum;
  }
  
  if (temp.length < 3)
  {  
    return temp;
  }

  let result = temp.substring(0, 3) + '-';

  if (temp.length >= 5)
  {
    result += temp.substring(3, 5) + '-';
  }
  else
  {
    return result + temp.substring(3, temp.length);
  }

  return result + temp.substring(5, temp.length);
}

//전화 번호를 000-0000-0000 형식으로
//051-234-1111
function GetFormattedPhone(phone)
{
  if (!IsValidS(phone))
    return "";

  if (phone.length === 8)
  {
    return phone.substring(0, 4) + "-" + phone.substring(4, 8);
  }
  if (phone.length === 9)
  {
    return phone.substring(0, 2) + "-" + phone.substring(2, 5) + "-" + phone.substring(5, 9);
  }
  if (phone.length === 10)
  {
    if (phone.indexOf("02") === 0)
      return phone.substring(0, 2) + "-" + phone.substring(2, 6) + "-" + phone.substring(6, 10);

    return phone.substring(0, 3) + "-" + phone.substring(3, 6) + "-" + phone.substring(6, 10);
  }
  else if (phone.length === 11)
  {
    return phone.substring(0, 3) + "-" + phone.substring(3, 7) + "-" + phone.substring(7, 11);
  }
  else if (phone.length === 12)
  {
    return phone.substring(0, 4) + "-" + phone.substring(4, 8) + "-" + phone.substring(8, 12);
  }

  return phone;
}

function GetFormattedPhone2(phone)
{
  if (!IsValidS(phone))
    return "";

  let temp = phone;

  if (temp.indexOf("-") >= 0)
  {
    temp = temp.replace(/-/gi, "");
  }

  if (temp.length < 3)
  {
    if (temp.indexOf("02") === 0)
    {
      return temp.replace("02", "02-");
    }

    return phone;
  }

  if (temp.length >= 3)
  {
    if (temp.length >= 4)
    {
      if (temp.indexOf("0505") === 0)
      {
        let temp2 = temp.substring(0, 4);
        temp2 += "-";
  
        let lg = temp.length - 4;
  
        if (temp.length > 4)
        {
          if (lg < 4)
          {
            temp2 += temp.substring(4, temp.length);

            return temp2;
          }
          else if (lg <= 7)
          {
            temp2 += temp.substring(4, 7);
            temp2 += "-";
            temp2 += temp.substring(7, temp.length);
  
            return temp2;
          }
          
          temp2 += temp.substring(4, 8);
          temp2 += "-";
          temp2 += temp.substring(8, temp.length);
          return temp2;
        }
  
        if (temp.length === 4)
          return temp2;
  
        temp2 += temp.substring(4, temp.length);
  
        return temp2;
      }
      else if (temp.indexOf("1566") === 0 || temp.indexOf("1588") === 0 || temp.indexOf("1600") === 0 || temp.indexOf("1670") === 0 ||
      temp.indexOf("1577") === 0 || temp.indexOf("1899") === 0 || temp.indexOf("1522") === 0 || temp.indexOf("1544") === 0 || temp.indexOf("1644") === 0 ||
      temp.indexOf("1661") === 0 || temp.indexOf("1599") === 0 || temp.indexOf("1688") === 0 ||  temp.indexOf("1666") === 0 ||  temp.indexOf("1855") === 0 || 
      temp.indexOf("1811") === 0 || temp.indexOf("1877") === 0)
      {
        let temp2 = temp.substring(0, 4);
        temp2 += "-";
  
        if (temp.length === 4)
          return temp2;
  
        temp2 += temp.substring(4, temp.length);
  
        return temp2;
      }
    }

    if (temp.indexOf("010") === 0 || temp.indexOf("011") === 0 || temp.indexOf("012") === 0 || temp.indexOf("016") === 0 || temp.indexOf("017") === 0 || 
      temp.indexOf("018") === 0 || temp.indexOf("019") === 0 || temp.indexOf("020") === 0 || temp.indexOf("030") === 0 || 
      temp.indexOf("040") === 0 || temp.indexOf("050") === 0 || temp.indexOf("060") === 0 || temp.indexOf("070") === 0 || 
      temp.indexOf("080") === 0 || temp.indexOf("090") === 0 || temp.indexOf("051") === 0 || temp.indexOf("053") === 0 || temp.indexOf("032") === 0 ||
      temp.indexOf("062") === 0 || temp.indexOf("042") === 0 || temp.indexOf("052") === 0 || temp.indexOf("044") === 0 || temp.indexOf("031") === 0 ||
      temp.indexOf("033") === 0 || temp.indexOf("043") === 0 || temp.indexOf("041") === 0 || temp.indexOf("063") === 0 || temp.indexOf("061") === 0 ||
      temp.indexOf("054") === 0 || temp.indexOf("055") === 0 || temp.indexOf("064") === 0)
    {
      let temp2 = temp.substring(0, 3);

      temp2 += "-";

      let lg = temp.length - 3;

      if (temp.length > 4)
      {
        if (lg < 4)
        {
          temp2 += temp.substring(3, temp.length);

          return temp2;
        }
        else if (lg <= 7)
        {
          temp2 += temp.substring(3, 6);
          temp2 += "-";
          temp2 += temp.substring(6, temp.length);

          return temp2;
        }
        
        temp2 += temp.substring(3, 7);
        temp2 += "-";
        temp2 += temp.substring(7, temp.length);
        return temp2;
      }

      if (temp.length === 3)
        return temp2;

      temp2 += temp.substring(3, temp.length);

      return temp2;
    }
    else if (temp.indexOf("02") === 0)
    {
      let temp2 = temp.substring(0, 2);

      temp2 += "-";

      let lg = temp.length - 2;

      if (temp.length > 4)
      {
        if (lg < 4)
        {
          temp2 += temp.substring(2, temp.length);

          return temp2;
        }
        else if (lg <= 7)
        {
          temp2 += temp.substring(2, 5);
          temp2 += "-";
          temp2 += temp.substring(5, temp.length);

          return temp2;
        }
        
        temp2 += temp.substring(2, 6);
        temp2 += "-";
        temp2 += temp.substring(6, temp.length);
        return temp2;
      }

      if (temp.length === 2)
        return temp2;

      temp2 += temp.substring(2, temp.length);

      return temp2;
    }
  }
  
  return phone;
}

function GetChatTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  temp = temp[1].split(":");

  if (temp.length < 3)
    return dateInfo;

  if (parseInt(temp[0]) < 12)
  {
    return `오전 ${temp[0]}:${temp[1]}`;
  }
  else
  {
    return `오후 ${temp[0]}:${temp[1]}`;
  }
}

function GetDataTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  temp = temp[1].split(":");

  if (temp.length < 3)
    return dateInfo;

  return `${temp[0]}:${temp[1]}:${temp[2]}`;
}

function GetDataDate(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  return temp[0];
}

//sql 날짜 문자열에서 년월일정보만 취하고, 해당 일자를 통해서 date객체 생성, 실패서 null
function GetDataDate2(dateInfo)
{
  const date = GetDataDate(dateInfo);

  if (!IsValidS(date))
    return null;

  const temp = date.split("-");

  if (!temp || temp.length < 3)
    return null;

  return new Date(parseInt(temp[0]), parseInt(temp[1])-1, parseInt(temp[2]));
}

function GetDataDateAndTime(dateInfo)
{
  if (!IsValidS(dateInfo))
  return "";
  
  let temp = dateInfo.split(" ");

  if (temp.length < 2)
  {
    return dateInfo;
  }

  let temp2 = temp[1].split(":");

  if (temp2.length < 3)
  {
    return dateInfo;
  }

  return `${temp[0]} ${temp2[0]}:${temp2[1]}`;
}

//sqlDate(mysql datetime값 문자열)을 Date객체로 변환함
function ConvertSqlDateToDate(sqlDate)
{
  try
  {
    let temp = sqlDate.split(" ");

    if (!temp || temp.length < 2)
      return null;

    let dateInfo = temp[0].split("-");

    if (!dateInfo || dateInfo.length !== 3)
      return null;

    let timeInfo = temp[1].split(":");

    if (timeInfo.length < 3)
      return null;

    return new Date(parseInt(dateInfo[0]), parseInt(dateInfo[1]) - 1, parseInt(dateInfo[2]), parseInt(timeInfo[0]), parseInt(timeInfo[1]));
  }
  catch(e)
  {
    return null;
  }
}

//yyyymmdd형식의 숫자 일자정보를 YYYY-MM-DD형식으로 변경
function ConvertDateInfo(dateInfo)
{
  if (!IsValidV(dateInfo))
    return "";

  let date = dateInfo.toString();

  if (date.length !== 8)
    return date;

  return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
}

function ConvertDateInfo2(dateInfo, deli)
{
  if (!IsValidV(dateInfo))
    return "";

  let date = dateInfo.toString();

  if (date.length !== 8)
    return date;

  return `${date.substring(0, 4)}${deli}${date.substring(4, 6)}${deli}${date.substring(6, 8)}`;
}

//yyyymm형식의 숫자 일자정보를 YYYY-MM형식으로 변경
function ConvertSerialMonthInfo(dateInfo)
{
  if (!IsValidV(dateInfo))
    return "";

  let date = dateInfo.toString();

  if (date.length < 6)
    return date;

  return `${date.substring(0, 4)}-${date.substring(4, 6)}`;
}

function isImageFile(name)
{
  if (!IsValidS(name))
    return false;

  try
  {
    const t = name.toUpperCase();

    if (t.indexOf(".PNG") >= 0 || t.indexOf(".JPG") >= 0 || t.indexOf(".JPEG") >= 0 || t.indexOf(".BMP") >= 0 || t.indexOf(".GIF") >= 0 || t.indexOf(".TIF") >= 0)
      return true;

    return false;
  }
  catch(e)
  {
    return false;
  }
}

//refDate가 0일경우 오늘자 년도를, 그렇지 않을경우 refDate의 년도를 얻는다. refDate는 YYYYMMDD형식의 정수 값이다.
function GetDateYear(refDate)
{
  if (refDate === 0)
  {
    return (new Date()).getFullYear();
  }

  const temp = refDate.toString();

  if (temp.length !== 8)
  {
    return (new Date()).getFullYear();
  }

  return parseInt(temp.substring(0, 4));
}

//refDate가 0일경우 오늘자 월을, 그렇지 않을경우 refDate의 월 얻는다. refDate는 YYYYMMDD형식의 정수 값이다.
function GetDateMonth(refDate)
{
  if (refDate === 0)
  {
    return (new Date()).getMonth() + 1;
  }

  const temp = refDate.toString();

  if (temp.length !== 8)
  {
    return (new Date()).getMonth() + 1;
  }

  return parseInt(temp.substring(4, 6));
}

//refDate가 0일경우 오늘자 일을, 그렇지 않을경우 refDate의 일을 얻는다. refDate는 YYYYMMDD형식의 정수 값이다.
function GetDateDay(refDate)
{
  if (refDate === 0)
  {
    return (new Date()).getDate();
  }

  const temp = refDate.toString();

  if (temp.length !== 8)
  {
    return (new Date()).getDate();
  }

  return parseInt(temp.substring(6, 8));
}

function GetValueOnly(v, rep)
{
  if (!IsValidS(v) || v === "n")
    return rep;

  return v;
}

//오늘자 년도를 구함
function GetCurrentYear()
{
  const nowDate = new Date();

  return nowDate.getFullYear();
}

//오늘자 월을 구함
function GetCurrentMonth()
{
  const nowDate = new Date();

  return nowDate.getMonth() + 1;
}

//오늘자 일을 구함
function GetCurrentDay()
{
  const nowDate = new Date();

  return nowDate.getDate();
}

//주어진 일자의 이전 월을 구함
function GetPrevMonth(year, month)
{
  if (month > 1)
  {
    return {year : year, month : month - 1};
  }

  return {year : year - 1, month : 12};
}

//주어진 일자의 다음 월을 구함
function GetNextMonth(year, month)
{
  if (month < 12)
  {
    return {year : year, month : month + 1};
  }

  return {year : year + 1, month : 1};
}

//해당 월의 마지막 날짜를 구한다.
function GetLastDate(year, month)
{
  const date = new Date(year, month, 0);

  return date.getDate();
}

//yyyy-mm-01 ~ yyyy-mm-31 같은 형식의 날짜 범위를 구한다.
function GetMonthRage(year, month, isCurrent)
{
  if (isCurrent)
  {
    return `${year}-${NumberFormatString(month)}-01~${year}-${NumberFormatString(month)}-${NumberFormatString(GetCurrentDay())}`;
  }
  
  return `${year}-${NumberFormatString(month)}-01~${year}-${NumberFormatString(month)}-${GetLastDate(year, month)}`;
}

//YYYYMMDD형식의 일자를 Date형식으로 변경함
function GetSerialDateToDate(sd)
{
  try
  {
    const temp = sd.toString();

    if (temp.length !== 8)
      return new Date();
  
    return new Date(parseInt(temp.substring(0, 4)), parseInt(temp.substring(4, 6))-1, parseInt(temp.substring(6, 8)), 0, 0, 0, 0);
  }
  catch(e)
  {
    console.log(e.toString());
    return new Date();
  }
}

//YYYYMMDD형식의 일자를 Date형식으로 변경함
function GetSerialDateToDate2(sd, hour, minute, second)
{
  try
  {
    const temp = sd.toString();

    if (temp.length !== 8)
      return new Date();
  
    return new Date(parseInt(temp.substring(0, 4)), parseInt(temp.substring(4, 6))-1, parseInt(temp.substring(6, 8)), hour, minute, second, 0);
  }
  catch(e)
  {
    console.log(e.toString());
    return new Date();
  }
}

//date 형식을 YYYYMMDD형식의 정수값으로 변환
function GetDateToSerialDate(date)
{
  return parseInt(`${date.getFullYear()}${NumberFormatString(date.getMonth() + 1)}${NumberFormatString(date.getDate())}`);
}

//date 형식을 YYYYMM형식의 정수값으로 변환
function GetDateToSerialMonth(date)
{
  return parseInt(`${date.getFullYear()}${NumberFormatString(date.getMonth() + 1)}`);
}

//오늘일자를 YYYYMMDD형식으로 구함
function GetTodayDateSerial()
{
  return GetDateToSerialDate(new Date());
}

//오늘월자를 YYYYMM형식으로 구함
function GetTodayMonthSerial()
{
  return GetDateToSerialMonth(new Date());
}

//YYYYMMDD형식에서 년정보만 얻음
function GetSerialDateYear(v)
{
  let temp = v.toString();

  if (temp.length < 4)
    return "";

  return parseInt(temp.substring(0, 4));
}

//YYYYMMDD형식에서 년정보만 얻음
function GetSerialDateMonth(v)
{
  let temp = v.toString();

  if (temp.length < 6)
    return "";

  return parseInt(temp.substring(4, 6));
}

//주어진일자에 days만큼 더하거나 뺀 일자의 날을 구함
function GetNextDateSerial(refDateSerial, days)
{
  const refDate = GetSerialDateToDate(refDateSerial);

  refDate.setDate(refDate.getDate() + days);

  return GetDateToSerialDate(refDate);
}

//오늘자를 기준으로, 해당 날짜만큼 더하거나 뺀일자를 구함
function GetNextDateSerial2(days)
{
  const refDate = new Date();

  refDate.setDate(refDate.getDate() + days);

  return GetDateToSerialDate(refDate);
}

function GetBankNameByCode(bankCode)
{
  switch(bankCode)
  {
    case 1:
      return "국민은행";
    case 2:
        return "기업은행";
    case 3:
        return "농협은행";
    case 4:
        return "신한은행";
    case 5:
        return "산업은행";
    case 6:
        return "우리은행";
    case 7:
        return "한국씨티은행";
    case 8:
        return "하나은행";
    case 9:
        return "SC제일은행";
    case 10:
      return "경남은행";
    case 11:
      return "광주은행";
    case 12:
      return "대구은행";
    case 13:
      return "도이치은행";
    case 14:
      return "부산은행";
    case 15:
      return "저축은행";
    case 16:
      return "새마을금고";
    case 17:
      return "수협은행";
    case 18:
      return "신협중앙회";
    case 19:
      return "우체국";
    case 20:
      return "전북은행";
    case 21:
      return "제주은행";
    case 22:
      return "케이뱅크";
    case 23:
      return "카카오뱅크";
    case 24:
      return "토스뱅크";
    default:
      return "은행을 선택하세요.";
  }
}

const getShortedPathName = (v, len) =>
{
  if (!IsValidS(v))
    return "";

  if (v.length <= len)
    return v;

  return `${v.substring(0, len-3)}···`;
}

//문자에서 - 및 좌우 공백을 모두 제거함
const replaceLine = (str) =>
{
  return str.trim().replace(/-/gi, "");
}

const IsValidId = (id) => {
  try {
      const idCheck = new RegExp('^[^0-9][a-zA-Z0-9_-]*$');

      if (!idCheck.test(id)) {
          return false;
      }

      return true;
  }
  catch (e) {
      return false;
  }
}

const IsValidEmail = (email) => {
  if (!email)
      return false;

  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  return regex.test(email);
};

//광고의 단순 타입이름을 구함
function GetAdsTypeSimple(adsType) 
{
  switch(adsType)
  {
    case 0: //인스타게시물좋아요
    case 10: //페이스북 게시물좋아요
    case 20: //유튜브 게시물 좋아요
    case 30: //틱톡 게시물 좋아요
      return "좋아요";

    case 1: //인스타 팔로워 늘리기
    case 31: //틱톡 팔로워
      return "팔로우";

    case 11: //페이스북 프로필 팔로워
      return "프로필 팔로우";
      
    case 14: //페이스북 페이지 좋아요
      return "페이지 팔로우";

    case 21: //유튜브 구독자
      return "구독";

    case 2: //인스타 게시물댓글
    case 12: //페이스북 게시물 댓글
    case 22: //유튜브 게시물 댓글
    case 32: //틱톡 게시물 댓글
      return "댓글";

    case 3: //인스타 커스텀댓글
    case 13: //페이스북 게시물 커스텀 댓글
    case 23: //유튜브 게시물 커스텀 댓글
    case 33: //틱톡 게시물 커스텀 댓글
      return "커스텀댓글";

    default:
      return "";
  }
}

const AdsGenderInfoToText = (gender) =>
{
  switch(gender)
  {
    case 0:
      return "전체";
    case 1:
      return "남성";
    case 2:
      return "여성";
    default:
      return "오류";
  }
}

const AdsAgeInfoToText = (age) =>
{
  switch(age)
  {
    case 1024:
      return "전체";
    case 2:
      return "20대";
    case 4:
      return "30대";
    default:
      return "오류";
  }
}

const AdsRepeatInfoToText = (rc, dy) =>
{
  if (rc < 2)
    return "";

  return `${dy}분 ${rc}회`;
}

const getPlatformCodeByAdsType = (adsType) =>
{
  switch(adsType)
  {
    case 0: //인스타게시물좋아요
    case 1: //인스타프로필팔로우
    case 2: //인스타 댓글
    case 3: //인스타 커스텀 댓글
      return 0;
    case 10: //페이스북 게시물 좋아요
    case 11: //페이스북 프리필팔로우
    case 12: //페이스북 댓글
    case 13: //페이스북 커스텀댓글
    case 14: //페이스북 페이지팔로우
      return 1;
    case 20: //유튜브 좋아요
    case 21: //유튜브 구독자
    case 22: //유튜브 댓글
    case 23: //유튜브 커스텀 댓글
      return 2;
    case 30: //틱톡 좋아요
    case 31: //틱톡 팔로우
    case 32: //틱톡 댓글
    case 33: //틱톡 커스텀 댓글
      return 3;
    default:
      return -1;
  }
}

//포인트 충전 주문 결제 수단
const PurchaseModeToText = (pm) =>
{
  switch(pm)
  {
    case 0:
      return "계좌이체";
    case 1:
      return "카드";
    case 2:
      return "가상계좌";
  }
}

//포인트충전 주문의 상태정보를 얻음
const GetPurchaseOrderStatus = (st) =>
{
  switch(st)
  {
    case 0: //주문이등록됨
      return "주문등록";
    case 1: //결제대기
      return "진행중";
    case 2: //결제완료
      return "완료";
    case 3: //결제취소
      return "실패";
    default:
      return "";
  }
}

const GetSHA256 = (value) => {
  try {
      return CryptoJS.SHA256(value, defaultKey_hmac).toString();
  }
  catch (e) {
      if (LOG_ENABLE)
        console.log(`GetSHA256 failed : ${e.toString()}`);

      return null;
  }
}

//무통장입금 마감기한얻기
//orderDate : YYYYMMDD
const getVirtualBankLimitTime = (orderDateInfo) =>
{
  let orderDate = GetSerialDateToDate2(orderDateInfo, 23, 59, 59); //주문일자

  orderDate.setDate(orderDate.getDate() + 1);

  return `${orderDate.getFullYear()}-${NumberFormatString(orderDate.getMonth() + 1)}-${NumberFormatString(orderDate.getDate())}`;
}

function checkBatchimEnding(word) {
  if (typeof word !== 'string') return null;
 
  var lastLetter = word[word.length - 1];
  var uni = lastLetter.charCodeAt(0);
 
  if (uni < 44032 || uni > 55203) return null;
 
  return (uni - 44032) % 28 != 0;
}

const getMaskedName = (v) =>
{
  if (!IsValidS(v))
    return "";

  let temp = "";

  if (v.length === 2)
  {
    temp += v.charAt(0);
    temp += "*";
    return temp;
  }

  for (let i = 0;i < v.length; ++i)
  {
    if (i === 0 || i === (v.length - 1))
    {
      temp += v.charAt(i);
    }
    else
      temp += "*";
  }

  return temp;
}

function GetAdsMissionNameLong(adsType) 
{
  switch(adsType)
  {
    case 0: //인스타게시물좋아요
      return "인스타그램 게시물 좋아요";
    case 10: //페이스북 게시물좋아요
      return "페이스북 게시물 좋아요";
    case 20: //유튜브 게시물 좋아요
      return "유튜브 동영상 좋아요";
    case 30: //틱톡 게시물 좋아요
      return "틱톡 동영상 좋아요";

    case 1: //인스타 팔로워 늘리기
      return "인스타그램 팔로우";
    case 31: //틱톡 팔로워
      return "틱톡 팔로우";

    case 11: //페이스북 프로필 팔로워
      return "페이스북 프로필 팔로우";
      
    case 14: //페이스북 페이지 좋아요
      return "페이스북 페이지 팔로우";

    case 21: //유튜브 구독자
      return "유튜브 구독";

    case 2: //인스타 게시물댓글
      return "인스타그램 댓글 달고";
    case 12: //페이스북 게시물 댓글
      return "페이스북 댓글 달고";
    case 22: //유튜브 게시물 댓글
      return "유튜브 댓글 달고";
    case 32: //틱톡 게시물 댓글
      return "틱톡 댓글 달고";

    case 3: //인스타 커스텀댓글
    case 13: //페이스북 게시물 커스텀 댓글
    case 23: //유튜브 게시물 커스텀 댓글
    case 33: //틱톡 게시물 커스텀 댓글
      return "커스텀댓글작성";

    default:
      return "";
  }
}

function GetAdsMissionDesc(adsType, point) 
{
  switch(adsType)
  {
    case 0: //인스타게시물좋아요
    case 10: //페이스북 게시물좋아요
      return `한개 게시글당 ${point}머니 받기`;
    case 20: //유튜브 게시물 좋아요
    case 30: //틱톡 게시물 좋아요
      return `한개 영상당 ${point}머니 받기`;

    case 1: //인스타 팔로워 늘리기
    case 11: //페이스북 프로필 팔로워
    case 31: //틱톡 팔로워
      return `한개 프로필당 ${point}머니 받기`;
      
    case 14: //페이스북 페이지 좋아요
      return `한개 페이지당 ${point}머니 받기`;

    case 21: //유튜브 구독자
      return `한개 채널당 ${point}머니 받기`;

    case 2: //인스타 게시물댓글
    case 12: //페이스북 게시물 댓글
      return `한개 게시글당 ${point}머니 받기`;
    case 22: //유튜브 게시물 댓글
    case 32: //틱톡 게시물 댓글
      return `한개 영상당 ${point}머니 받기`;

    case 3: //인스타 커스텀댓글
    case 13: //페이스북 게시물 커스텀 댓글
    case 23: //유튜브 게시물 커스텀 댓글
    case 33: //틱톡 게시물 커스텀 댓글
      return "";

    default:
      return "";
  }
}

const getSnsPlatformName = (platformCode) =>
{
  switch(platformCode)
  {
    case 0:
      return "인스타그램";
    case 1:
      return "페이스북";
    case 2:
      return "유튜브";
    case 3:
      return "틱톡";
    default:
      return "";
  }
}

const getProfileImage = (imageFile) =>
{
  if (!IsValidS(imageFile) || imageFile === "n")
    return GetImgUrl("prf.png");

  return GetProfileImgUrl(imageFile);
}

const checkEmail = (email) => {
  if (!email)
      return false;

  let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  return regex.test(email);
};

const GetAgeStartFromRange = (ageRange) =>
{
  if (!IsValidS(ageRange))
    return 0;

  if (ageRange.startsWith(10))
    return 10;
  else if (ageRange.startsWith(20))
    return 20;
  else if (ageRange.startsWith(30))
    return 30;
  else if (ageRange.startsWith(40))
    return 40;
  else if (ageRange.startsWith(50))
    return 50;
  else if (ageRange.startsWith(60))
    return 60;
  else if (ageRange.startsWith(70))
    return 70;
  else if (ageRange.startsWith(80))
    return 80;
  else if (ageRange.startsWith(90))
    return 90;
  else if (ageRange.startsWith(100))
    return 100;

  return 0;
}

const GetAgeStartFromRangeForKakao = (ageRange) =>
{
  if (!IsValidS(ageRange))
    return 0;

  if (ageRange.startsWith("AGE_10"))
    return 10;
  else if (ageRange.startsWith("AGE_20"))
    return 20;
  else if (ageRange.startsWith("AGE_30"))
    return 30;
  else if (ageRange.startsWith("AGE_40"))
    return 40;
  else if (ageRange.startsWith("AGE_50"))
    return 50;
  else if (ageRange.startsWith("AGE_60"))
    return 60;
  else if (ageRange.startsWith("AGE_70"))
    return 70;
  else if (ageRange.startsWith("AGE_80"))
    return 80;
  else if (ageRange.startsWith("AGE_90"))
    return 90;
  else if (ageRange.startsWith("AGE_100"))
    return 100;
  else if (ageRange.startsWith("10"))
    return 10;
  else if (ageRange.startsWith("20"))
    return 20;
  else if (ageRange.startsWith("30"))
    return 30;
  else if (ageRange.startsWith("40"))
    return 40;
  else if (ageRange.startsWith("50"))
    return 50;
  else if (ageRange.startsWith("60"))
    return 60;
  else if (ageRange.startsWith("70"))
    return 70;
  else if (ageRange.startsWith("80"))
    return 80;
  else if (ageRange.startsWith("90"))
    return 90;
  else if (ageRange.startsWith("100"))
    return 100;
  return 0;
}

const getRandomNumber = (max) =>
{
  let sel = Math.floor(Math.random() * max);

  if (sel >= max)
    sel = max - 1;

  return sel;
}

//배송사 코드를 이름으로 변환
const getDeliveryCompNameByCode = (dc) =>
{
  switch(dc)
  {
    case 1:
      return "CJ대한통운";
    case 2:
      return "한진택배";
    case 3:
      return "롯데택배";
    case 4:
      return "우체국택배";
    case 5:
      return "로젠택배";
    case 6:
      return "일양로지스";
    case 7:
      return "한덱스";
    case 8:
      return "대신택배";
    case 9:
      return "경동택배";
    case 10:
      return "합동택배";
    case 11:
      return "CU 편의점택배";
    case 12:
      return "GS Postbox 택배";
    default:
      return "";
  }
}

export {NumberFormatString, DownloadFile, UploadFile, ToBase64, FromBase64, IsValidS, IsValidV, 
  GetFormattedCNum, GetFormattedPhone, GetFormattedCNum2, GetFormattedPhone2, GetChatTime,GetDataTime,GetDataDate, GetDataDate2, GetDataDateAndTime, isImageFile,
  ConvertSqlDateToDate,
  ConvertDateInfo,
  ConvertDateInfo2,
  ConvertSerialMonthInfo,
  GetFormattedNum, GetValueOnly, GetDateYear, GetDateMonth, GetDateDay,
  GetCurrentYear, GetCurrentMonth, GetCurrentDay, GetPrevMonth, GetNextMonth, GetLastDate, GetMonthRage,
  GetNextDateSerial,
  GetNextDateSerial2,
  GetBankNameByCode,
  GetSerialDateToDate, GetSerialDateToDate2, GetDateToSerialDate, GetTodayDateSerial, GetDateToSerialMonth, GetTodayMonthSerial,
  GetSerialDateYear, GetSerialDateMonth, getShortedPathName, replaceLine, IsValidId, IsValidEmail,
  GetAdsTypeSimple,
  getPlatformCodeByAdsType,
  AdsGenderInfoToText, AdsAgeInfoToText, AdsRepeatInfoToText, PurchaseModeToText, GetPurchaseOrderStatus,
  GetSHA256,
  getVirtualBankLimitTime,
  checkBatchimEnding,
  getMaskedName,

  GetAdsMissionNameLong,
  GetAdsMissionDesc,
  getSnsPlatformName,
  getProfileImage,
  checkEmail,

  GetAgeStartFromRange,
  GetAgeStartFromRangeForKakao,

  getRandomNumber,

  getDeliveryCompNameByCode
};