import {Cookies} from "react-cookie";
import { APP_MODE } from "../URL/AppUrl";

const cookies = new Cookies();

export const setCookie = (name, value) =>
{
  try
  {
    return cookies.set(name, value, {
      path : '',
      maxAge : 604800,
      domain: APP_MODE === 2 ? "www.mpang.io" : ".snsfly.kr"
    });
  }
  catch(e)
  {
    console.log(e.toString());
    return false;
  }
}

export const getCookie = (name) =>
{
  try
  {
    return cookies.get(name);
  }
  catch(e)
  {
    console.log(e.toString());
    return null;
  }
}

export const delCookie = (name) =>
{
  try
  {
    cookies.remove(name, {path: "", domain: APP_MODE === 2 ? "www.mpang.io" : ".snsfly.kr"});
  }
  catch(e)
  {
    console.log(e.toString());
  }
}