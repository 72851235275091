import React from 'react';
import './Mission.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { LoginStoreInstance } from '../../Stores/LoginStore';


//빙고팡팡페이지
class MissionBingo extends React.Component
{
  state = {
    loading: false,
  }

  loading = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  componentDidMount()
  {

  }

  componentWillUnmount()
  {

  }

  render()
  {
    return (
      <div className='mssnsarea2 coltc mgtop15'>
        <div className='mssnsarea coltc'>
          <div className='bingowh coltc'>
            <div className='wd100p rowsc'>
              <div className='rowcc'>
                <label className='font18 wt400 color31 mgrt5'>빙고 저금통</label>
                <img src={GetImgUrl("bingowh.png")} className='img1515' />
              </div>

              <div className='bginfobtn rowsc btncur'>

                <div className='rowcc mglt10'>
                  <img src={GetImgUrl("bingowh2.png")} className='img1515 mgrt10' />
                  <label className='font15 wt300 color31 mgrt5 btncur'>빙고 규칙</label>
                </div>

                <img src={GetImgUrl("bingowh3.png")} className='img1010 mgrt10' />
              </div>
            </div>

            <div className='wd100p rowrc mgtop10'>
              <img src={GetImgUrl("bingowh4.png")} className='img3125' />
            </div>
            
            <div className='bingogagebg rowlc'>
              <div className='bingogage rowrc'>
                <div className='bingotip rowcc' style={{backgroundImage: `url(${GetImgUrl("bingowh5.png")})`}}>
                </div>
              </div>
            </div>

            <div className='wd100p rowsc mgtop5'>
              <label className='font10 wt300 colorb3'>0</label>
              <label className='font10 wt300 colorb3'>500</label>
              <label className='font10 wt300 colorb3'>1,000</label>
            </div>

            <div className='bingocover rowcc'>
              <label className='font14 wt400 colorw textcenter lht20'>준비 중인 서비스입니다.<br/>정식 오픈을 기대해 주세요!</label>
            </div>
          </div>

          <div className='bingoslot rowcc mgtop20 btncur' onClick={() => this.props.handleBingoGame(1)}>
            <img src={GetImgUrl("bphanla.jpg")} className='bingoslotimg' />
          </div>

          <div className='bingoslot rowcc mgtop20 btncur' onClick={() => this.props.handleBingoGame(2)}>
            <img src={GetImgUrl("bpatomu.jpg")} className='bingoslotimg' />
          </div>

          <div className='bingoslot rowcc mgtop20 btncur' onClick={() => this.props.handleBingoGame(3)}>
            <img src={GetImgUrl("bpwatch.jpg")} className='bingoslotimg' />
          </div>

          <div className='bingoslot rowcc mgtop20 mgbt100 btncur' onClick={() => this.props.handleBingoGame(4)}>
            <img src={GetImgUrl("bpshoes.jpg")} className='bingoslotimg' />
          </div>
        </div>
      </div>
    );
  }
}

export default MissionBingo;