import React from 'react';
import './Mission.css';
import '../../App.css';
import { LOG_ENABLE } from '../../URL/AppUrl';

class BingoGameView extends React.Component
{
  state = {
    loading: false,

    showPop: false,
    popupTitle: '',
    popupMsg: '',
    okBtn: '',
  }

  loading = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  showMsgPopup = (title, msg, okbtn) =>
  {
    this.setState((prevState) => ({...prevState, showPop : true, popupTitle: title, popupMsg: msg, okBtn: okbtn}));
  }

  closeMsgPop = () =>
  {
    this.setState((prevState) => ({...prevState, showPop : false}));
  }

  componentDidMount()
  {
      window.addEventListener("message", this.handleChildMessage);
  }

  componentWillUnmount()
  {
    window.removeEventListener("message", this.handleChildMessage);
  }

  handleChildMessage = (e) =>
  {
    if (LOG_ENABLE)
      console.log(`handleChildMessage : ${e.data}`);

    try
    {
      const json = JSON.parse(e.data);

      if (json)
      {
        switch(json.id)
        {
          case 0: //닫기
            if (this.props.handleClose)
              this.props.handleClose();
            return;
          case 1: //토스트 메시지
            if (this.props.handleToastMsg)
              this.props.handleToastMsg(json.msg);
            return;
        }
      }
    }
    catch(e)
    {
      if (LOG_ENABLE)
        console.log(`handleChildMessage failed :${e.toString()}`);
    }
  }

  render()
  {
    return (
      <div className='bingoviewbg rowcc'>
        <iframe className='bingoview' src={this.props.url}>

        </iframe>
      </div>
    );
  }
}

export default BingoGameView;