import React, { Fragment } from 'react';
import './HelpPage.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, getHelpImageUrl, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { FaqStoreInstance } from '../../Stores/FaqStore';
import { GetDataDateAndTime, IsValidS, IsValidV, UploadFile, checkEmail } from '../../Util/Util';
import HelpQueryListItem from './HelpQueryListItem';



//고객센터 문의내역 상세
class HelpQueryDetail extends React.Component
{
  state = {
    loading: false,
  };

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  componentDidMount() {
  }

  componentWillUnmount = () =>
  {
  }

  handleClose = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  isImageValid1 = () =>
  {
    if (IsValidS(this.props.item.img1) && this.props.item.img1 !== "n")
      return true;

    return false;
  }

  isImageValid2 = () =>
  {
    if (IsValidS(this.props.item.img2) && this.props.item.img2 !== "n")
      return true;

    return false;
  }

  isImageValid3 = () =>
  {
    if (IsValidS(this.props.item.img3) && this.props.item.img3 !== "n")
      return true;

    return false;
  }

  render()
  {
    const imageValid1 = this.isImageValid1();
    const imageValid2 = this.isImageValid2();
    const imageValid3 = this.isImageValid3();

    return (
      <div className='hpquerypop coltc vscroll'>
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 title="" withLine={true} handleBack={this.handleClose} />

        <div className='wd100p max500 coltc mgtop60'>

          <div className='wd88p coltl'>
            <label className='font14 wt400 color31'>{this.props.item.tt}</label>
            <label className='font12 wt400 colorb3 mgtop10'>{GetDataDateAndTime(this.props.item.rd)}</label>
            <label className='font12 wt300 color31 mgtop10 lineheight18'>{this.props.item.dc}</label>

            {
              imageValid1 || imageValid2 || imageValid3 ? (
                <div className='wd100p rowlc mgtop20'>

                  {
                    imageValid1 ? (
                      <div className='hpqimgbg rowcc mgrt10'>
                        <img src={getHelpImageUrl(this.props.item.img1)} alt="첨부이미지" className='hpqimg'/>
                      </div>
                    ) : null
                  }

                  {
                    imageValid2 ? (
                      <div className='hpqimgbg rowcc mgrt10'>
                        <img src={getHelpImageUrl(this.props.item.img2)} alt="첨부이미지" className='hpqimg'/>
                      </div>
                    ) : null
                  }

                  {
                    imageValid3 ? (
                      <div className='hpqimgbg rowcc mgrt10'>
                        <img src={getHelpImageUrl(this.props.item.img3)} alt="첨부이미지" className='hpqimg'/>
                      </div>
                    ) : null
                  }
                  
                </div>
              ) : null
            }
            
            <label className='font12 wt500 color31 mgtop20 lineheight18'>답변 받으실 이메일 주소</label>
            <div className='hpqdteb rowlc mgtop5'>
              <label className='font14 wt300 color31'>{this.props.item.em}</label>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default HelpQueryDetail;

