import './PangBox.css';
import '../../App.css';

//팡팡박스 구매 실패 팝업
const PangBoxBuyFail = (props) =>
{
  return (
    <div className='pbbuybg colec'>
      <div className='pbbuypop colcc'>
        <div className='pbbuypopin rowlc mgtop20'>
          <label className='font14 wt400 colorff'>머니가 부족합니다</label>
        </div>

        <div className='pbbuypopin rowls mgtop5'>
          <label className='font12 wt300 color31'>머니가 부족하여 박스 구매에 실패하였습니다.<br/>머니를 적립하시겠습니까?</label>
        </div>

        <div className='wd100p rowcc mgtop30'>
          <button className='pbbuybtn1 font14 btncur wt400 color31' onClick={() => props.handleBuyBoxFail(0)}>닫기</button>
          <button className='pbbuybtn2 font14 btncur wt400 colorw' onClick={() => props.handleBuyBoxFail(1)}>확인</button>
        </div>
      </div>
    </div>
  );
}

export default PangBoxBuyFail;