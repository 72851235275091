import './MainPage.css';
import '../../App.css';
import { GetImgUrl, getAdsImageUrl } from '../../URL/AppUrl';

const MpMission = (props) =>
{
  return (
    <div className='mnmisbg rowsc btncur' onClick={() => props.handleMissionClick(0, props.item)}>

      <div className='mnmisimgbg rowcc'>
        <img src={getAdsImageUrl(props.item.img)} alt="미션" className='mnmisimg2' />
      </div>

      <div className='mnmisdesc colcl'>
        <label className='font16 wt400 color31 btncur'>{props.item.tt}</label>
        <label className='font14 wt400 colorb3 btncur'>{props.item.dc}</label>
      </div>

      <div className='mnmisbtn rowcc btncur' onClick={() => props.handleMissionClick(0, props.item)}>
        <img src={GetImgUrl(props.item.mt === 0 ? "cash3@3x.png" : "pang@3x.png")} alt="미션" className='cashimg mgrt5' />
        <label className='font16 wt400 color31 btncur'>{props.item.pnt}</label>
      </div>
    </div>
  );
}

export default MpMission;