import './Guide.css';
import '../../App.css';
import TopBar2 from '../Top/TopBar2';
import { Fragment, useState } from 'react';
import { getGuideImageUrl } from '../../URL/AppUrl';
import GuideIndi from './GuideIndi';
import { IsValidS, IsValidV } from '../../Util/Util';

const getCurrentGuideImage = (item, step) =>
{
  if (!IsValidV(item.imgList) || !IsValidS(item.imgList.img) ||
    step < 0 ||
    step > item.imgList.img.length)
    return null;

  return getGuideImageUrl(item.imgList.img[step].img);
}

const getGuideCount = (item) =>
{
  if (IsValidV(item.imgList) && IsValidS(item.imgList.img))
    return item.imgList.img.length;
  else
    return 0;
}

const GuidePop = (props) =>
{
  const [guideStep, setStep] = useState(0);
  const guideCount = getGuideCount(props.item);
  

  let indis = null;

  if (guideCount > 0)
  {
    indis = [];

    for (let i = 0;i < guideCount; ++i)
    {
      indis.push(<GuideIndi key={i} index={i} selected={guideStep} />);
    }
  }

  return (
    <div className='gdpop coltc'>
      <TopBar2 title="이용가이드" handleBack={props.handleBack} withLine={false}/>

      <div className='gdpopin coltc mgtop40'>
        <div className='gdimg coltc'>
          {
            guideCount > 0 ? (
              <img src={getCurrentGuideImage(props.item, guideStep)} alt="가이드" className='guideimg'/>
            ) : null
          }
        </div>

        <div className='gdindiarea coltc mgtop40'>
          <div className='wd100p rowcc'>
            {indis}
          </div>
        </div>

        {
          guideCount === 1 ? (
            <button className='gdbtn1 font14 wt400 colorw btncur' onClick={() => {
                props.handleBack();
              }}>시작하기</button>
          ) : null
        }

        <div className='wd100p rowcc'>
          {
            guideStep === 0 ? (
              <button className='gdbtn1 font14 wt400 colorw btncur' onClick={() => {
                if (guideStep < guideCount)
                  setStep(guideStep + 1);
              }}>다음</button>
            ) : (
              <Fragment>
                <button className='gdbtn2 font14 wt400 color31 btncur' onClick={() => {
                  if (guideStep > 0)
                    setStep(guideStep - 1);
                }}>이전</button>

                {
                  guideStep === guideCount - 1 ? (
                    <button className='gdbtn3 font14 wt400 colorw btncur' onClick={() => {
                      props.handleBack();
                    }}>시작하기</button>
                  ) : (
                    <button className='gdbtn3 font14 wt400 colorw btncur' onClick={() => {
                      if (guideStep < guideCount)
                        setStep(guideStep + 1);
                    }}>다음</button>
                  )
                }
                
              </Fragment>
            )
          }
        </div>
      </div>
      
    </div>
  );
}

export default GuidePop;