import './App.css';
import { HashRouter, Route, Routes,  } from 'react-router-dom';
import LoginPage from './Components/Login/LoginPage';
import MainPage from './Components/Main/MainPage';
import Alarm from './Components/Alarm/Alarm';
import Notice from './Components/Notice/Notice';
import MyPang from './Components/MyPang/MyPang';
import Setting from './Components/Setting/Setting';
import SnsAcc from './Components/SnsAcc/SnsAcc';
import Address from './Components/Address/Address';
import AffPage from './Components/AffPage/AffPage';
import Guide from './Components/Guide/Guide';
import HelpPage from './Components/Help/HelpPage';
import PangBox from './Components/PangBox/PangBox';
import Winners from './Components/Winners/Winners';
import CashLog from './Components/CashLog/CashLog';
import PangLog from './Components/PangLog/PangLog';
import Mission from './Components/Mission/Mission';
import Inventory from './Components/Inventory/Inventory';


function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LoginPage/>} />
        <Route path="/main" element={<MainPage/>} />
        <Route path="/pang" element={<PangBox/>} />
        <Route path="/alarm" element={<Alarm/>} />
        <Route path="/notice" element={<Notice/>} />
        <Route path="/mypang" element={<MyPang/>} />
        <Route path="/setting" element={<Setting/>} />
        <Route path="/sns" element={<SnsAcc/>} />
        <Route path="/address" element={<Address/>} />
        <Route path="/query" element={<AffPage/>} />
        <Route path="/guide" element={<Guide/>} />
        <Route path="/help" element={<HelpPage/>} />
        <Route path="/winner" element={<Winners/>} />
        <Route path="/cashlog" element={<CashLog/>} />
        <Route path="/panglog" element={<PangLog/>} />
        <Route path="/mission" element={<Mission/>} />
        <Route path="/inventory" element={<Inventory/>} />
      </Routes>
    </HashRouter>
  );
}

export default App;
