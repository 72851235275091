import React from 'react';
import './CashLog.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  ConvertDateInfo, ConvertDateInfo2, GetDateToSerialDate, GetFormattedNum, GetNextDateSerial, GetSerialDateToDate, IsValidS, NumberFormatString, } from '../../Util/Util';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import MoreItem from '../Notice/MoreItem';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import CashLogItem from './CashLogItem';
import { PointStoreInstance } from '../../Stores/PointStore';
import DatePick from '../DatePick/DatePick';

//머니 적립 내역
class CashLog extends React.Component
{
  state = {
    loading: false,

    noItem: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    startDate: 0,
    endDate: 0,
    datePickMode: 0,
  };

  startDate = 0;
  endDate = 0;
  intervalId = -1;
  
  getCurrentPickDate = () =>
  {
    if (this.state.datePickMode === 1)
    {
      if (this.state.startDate < 1)
        return new Date();

      return GetSerialDateToDate(this.state.startDate);
    }
    else
    {
      if (this.state.endDate < 1)
        return new Date();

      return GetSerialDateToDate(this.state.endDate);
    }
  }

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  setStartDate = (v) =>
  {
    this.startDate = v;
    this.setState((prevState) => ({...prevState, startDate : v}));
  }

  setEndDate = (v) =>
  {
    this.endDate = v;
    this.setState((prevState) => ({...prevState, endDate : v}));
  }

  setDatePickMode = (v) =>
  {
    this.setState((prevState) => ({...prevState, datePickMode : v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    const nowDate = new Date();

    this.setStartDate(parseInt(`${nowDate.getFullYear()}${NumberFormatString(nowDate.getMonth() + 1)}01`));
    this.setEndDate(GetDateToSerialDate(nowDate));

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      this.loadNext();
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    PointStoreInstance.clearCashLog();
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadNext();
  }

  loadNext = () =>
  {
    this.setLoading(true);
    PointStoreInstance.loadCashLogs(this.startDate, this.endDate, this.onLogLoaded);
  }

  onLogLoaded = (resultCode, readCount) =>
  {
    if (LOG_ENABLE)
      console.log(`current load1 : ${this.state.loading}`);

    this.setLoading(false);

    if (LOG_ENABLE)
      console.log(`current load2 : ${this.state.loading}`);
    

    //더이상 읽을게 없으면
    if (resultCode === 0 && readCount < 1)
    {
      this.setNoItem(true);
      return;
    }
  }

  handleMore = () =>
  {
    if (!this.state.loading)
      this.loadNext();
  }

  handleDate = (selected, date) =>
  {
    const pickMode = this.state.datePickMode;

    this.setDatePickMode(0);

    if (!selected)
      return;

    if (pickMode === 1)
    {
      let sd = GetDateToSerialDate(date);
      let ed = this.state.endDate;

      if (sd > ed)
      {
        ed = sd;

        this.setStartDate(sd);
        this.setEndDate(ed);
        return;
      }

      //조회범위가 넘어가지 않는지 확인
      const limit = GetNextDateSerial(ed, -90);

      if (limit > sd)
      {
        this.setShowPopup(1, "최대 3개월까지만 조회 가능합니다.", "확인", "", null);
        return;
      }

      this.setStartDate(sd);
    }
    else
    {
      let sd = this.state.startDate;
      let ed = GetDateToSerialDate(date);
      
      if (sd > ed)
      {
        sd = ed;

        this.setStartDate(sd);
        this.setEndDate(ed);
        return;
      }

      //조회범위가 넘어가지 않는지 확인
      const limit = GetNextDateSerial(ed, -90);

      if (limit > sd)
      {
        this.setShowPopup(1, "최대 3개월까지만 조회 가능합니다.", "확인", "", null);
        return;
      }

      this.setEndDate(ed);
    }
  }

  handleRefresh = () =>
  {
    if (this.state.loading)
      return;

    this.setNoItem(false);
    PointStoreInstance.clearCashLog();
    this.loadNext();
  }

  render()
  {
    let items = null;

    if (IsValidS(PointStoreInstance.cashLogs))
    {
      items = PointStoreInstance.cashLogs.map(item => {
        return <CashLogItem key={item.idx} item={item} />
      });
    }
    
    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.datePickMode > 0 ? (
            <DatePick initDate={this.getCurrentPickDate()} handleDate={this.handleDate}/>
          ) : null
        }
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => this.setShowPopup(false, null, null)}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 withLine={true} title="머니 내역" handleBack={this.handleBack}/>

        <div className='wd100p max500 flexcoltc'>
          
          <div className='wd88p colcl mgtop80'>
            <label className='font14 wt300 color31'>보유 머니</label>
            <label className='font24 wt500 color31 mgtop5'>{GetFormattedNum(LoginStoreInstance.getUserCash)}</label>

            <div className='rowcc mgtop20'>
              <label className='font12 wt400 color31 mgrt10'>기간 조회하기</label>
              <label className='font12 wt300 colorb3'>(최대 3개월까지 조회가 가능합니다.)</label>
            </div>

            <div className='wd100p rowsc mgtop5'>
              <div className='rowcc'>
                <div className='cpldatebox rowcc mgrt10 btncur' onClick={() => this.setDatePickMode(1)}>
                  <img src={GetImgUrl("date@3x.png")} alt="시작일자" className='img1515 mgrt5'/>
                  <label className='font12 wt400 color31 btncur'>{ConvertDateInfo2(this.state.startDate, ".")}</label>
                </div>

                <label className='font14 wt300 color31 mgrt10'>~</label>

                <div className='cpldatebox rowcc btncur' onClick={() => this.setDatePickMode(2)}>
                  <img src={GetImgUrl("date@3x.png")} alt="종료일자" className='img1515 mgrt5'/>
                  <label className='font12 wt400 color31 btncur'>{ConvertDateInfo2(this.state.endDate, ".")}</label>
                </div>
              </div>

              <button className='cpldatebtn font14 color31 wt400 btncur' onClick={this.handleRefresh}>적용</button>
            </div>
          </div>

          <div className='wd88p flexcoltc mgtop20'>
            {items}
          </div>

          {
            !this.state.noItem ? (<MoreItem handleClick={this.handleMore}/>) : (<div className='emptyTag'></div>)
          }  
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(CashLog));

