import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';
import { FromBase64, IsValidS, IsValidV } from '../Util/Util';

//캐시업 미션관련
class CashupStore {
  homeMissions = null; //캐시업홈페이지 표시용 미션목록
  missionForHome = null; //전체 유저가 참여 가능한 머니팡 자체 미션

  adsListInsta = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 인스타
  adsListFacebook = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 페이스북
  adsListYoutube = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 유튜브
  adsListTiktok = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 틱톡

  //비옵저버 변수
  adsSourceInsta = null; //서버에서 수신된 각광고의 목록, 인스타
  adsSourceFacebook = null; //서버에서 수신된 각광고의 목록, 페이스북
  adsSourceYoutube = null; //서버에서 수신된 각광고의 목록, 유튜브
  adsSourceTiktok = null; //서버에서 수신된 각광고의 목록, 틱톡
  adsCountInRow = 3;

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      homeMissions : observable,
      missionForHome : observable,
      adsListInsta : observable,
      adsListFacebook : observable,
      adsListYoutube : observable,
      adsListTiktok : observable,

      
      clearAll: action,
      clearAdsList: action,
      setMissionForHome: action,
      parseAdsListResult: action,
      parseAdsForMissionResult: action,
    });
  }

  clearAll = () =>
  {
    this.homeMissions = null; //상품 히스토리, 대박당첨 히스토리
    this.missionForHome = null;

    this.adsListInsta = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 인스타
    this.adsListFacebook = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 페이스북
    this.adsListYoutube = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 유튜브
    this.adsListTiktok = null; //수신된 광고 목록, 행에 맞게 적절히 조절된 데이터, 틱톡

    this.adsSourceInsta = null; //서버에서 수신된 각광고의 목록, 인스타
    this.adsSourceFacebook = null; //서버에서 수신된 각광고의 목록, 페이스북
    this.adsSourceYoutube = null; //서버에서 수신된 각광고의 목록, 유튜브
    this.adsSourceTiktok = null; //서버에서 수신된 각광고의 목록, 틱톡
  }

  clearAdsList = (platform) =>
  {
    switch(platform)
    {
      case 0:
        this.adsListInsta = null;
        this.adsSourceInsta = null;
        break;
      case 1:
        this.adsListFacebook = null;
        this.adsSourceFacebook = null;
        break;
      case 2:
        this.adsListYoutube = null;
        this.adsSourceYoutube = null;
        break;
      case 3:
        this.adsListTiktok = null;
        this.adsSourceTiktok = null;
        break;
    }
  }

  setMissionForHome = (mission) =>
  {
    this.missionForHome = mission; //신규유저일때 신규유저용 미션, 원래는 신규유저미션인데 전체 유저미션으로 바뀜
  }
  //광고 목록 조회(미션페이지용5개조회)-------------------------------------------------------------------------------------------------------------------------------
  loadAdsForMission = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "1");
    params.append("ag", LoginStoreInstance.getAgeRange.toString());

    axios({
      method:"POST",
      url: GetApiUrl("ssad/rlist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseAdsForMissionResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseAdsForMissionResult(null, callback);
    });
  }

  parseAdsForMissionResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseAdsForMissionResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.homeMissions = result.list;

      if (IsValidV(result.nu))
      {
        this.missionForHome = result.nu;
        this.decodeMission(this.missionForHome);
      }
      else
        this.missionForHome = null;
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }

  decodeMission = (mission) =>
  {
    if (IsValidS(mission.lk))
      mission.lk = FromBase64(mission.lk);

    if (IsValidS(mission.tt))
      mission.tt = FromBase64(mission.tt);

    if (IsValidS(mission.dc))
      mission.dc = FromBase64(mission.dc);
  }
  //광고 목록 조회-------------------------------------------------------------------------------------------------------------------------------

  loadAds = (platform, adsType, callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("ag", LoginStoreInstance.getAgeRange.toString());
    params.append("pf", platform.toString());
    params.append("at", adsType.toString());

    axios({
      method:"POST",
      url: GetApiUrl("ssad/rlist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseAdsListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseAdsListResult(null, callback);
    });
  }

  getAdsSourceList = (adsList) =>
  {
    if (!IsValidS(adsList))
      return null;

    let newList = [];

    for (let i = 0;i < adsList.length; ++i)
    {
      newList.push({
        ...adsList[i],
        mst: 0,
        contType: 0, //광고아이템(0), 더미(1)
      });
    }

    return newList;
  }

  parseAdsListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseAdsListResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      switch(result.pf)
      {
        case 0:
          this.adsSourceInsta = this.getAdsSourceList(result.list);
          break;
        case 1:
          this.adsSourceFacebook = this.getAdsSourceList(result.list);
          break;
        case 2:
          this.adsSourceYoutube = this.getAdsSourceList(result.list);
          break;
        case 3:
          this.adsSourceTiktok = this.getAdsSourceList(result.list);
          break;
      }
      
      this.refreshAdsList(result.pf);
    }
    else if (result.ret === 100)
    {
      if (callback)
        LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }

  getAdsList = (platformCode) =>
  {
    switch(platformCode)
    {
      case 0: //인스타
        return this.adsListInsta;
      case 1: //페이스북
        return this.adsListFacebook;
      case 2: //유튜브
        return this.adsListYoutube;
      case 3: //틱톡
        return this.adsListTiktok;
      default:
        return null;
    }
  }

  setAdsList = (platformCode, adsList) =>
  {
    switch(platformCode)
    {
      case 0: //인스타
        this.adsListInsta = adsList;

        if (LOG_ENABLE)
          console.log(`set ads list to insta : ${adsList ? adsList.length : 'noitem'}`);
        break;
      case 1: //페이스북
        this.adsListFacebook = adsList;

        if (LOG_ENABLE)
          console.log(`set ads list to fb : ${adsList ? adsList.length : 'noitem'}`);
        break;
      case 2: //유튜브
        this.adsListYoutube = adsList;

        if (LOG_ENABLE)
          console.log(`set ads list to yt : ${adsList ? adsList.length : 'noitem'}`);
        break;
      case 3: //틱톡
        this.adsListTiktok = adsList;

        if (LOG_ENABLE)
          console.log(`set ads list to tt : ${adsList ? adsList.length : 'noitem'}`);
        break;
    }
  }

  //광고목록 소스를 기반으로 광고리스트를 갱신한다.
  refreshAdsList = (platformCode) =>
  {
    if (LOG_ENABLE)
      console.log(`refreshAdsList : ${platformCode}`);

    let adsSrc = null;

    switch(platformCode)
    {
      case 0: //인스타
        adsSrc = this.adsSourceInsta;
        break;
      case 1: //페이스북
        adsSrc = this.adsSourceFacebook;
        break;
      case 2: //유튜브
        adsSrc = this.adsSourceYoutube;
        break;
      case 3: //틱톡
        adsSrc = this.adsSourceTiktok;
        break;
    }

    if (!IsValidS(adsSrc))
    {
      this.setAdsList(platformCode, null);
      return;
    }

    let newList = [];

    let index = 0;
    let srcCount = adsSrc.length;
    let adsIdx = 1;

    while(true)
    {
      const adsInfo = {
        idx: adsIdx,
        ads: []
      };

      ++adsIdx;

      let done = false;

      for (let i = 0;i < this.adsCountInRow; ++i)
      {
        if (index < srcCount)
        {
          adsInfo.ads.push(adsSrc[index]);
          ++index;
        }
        else
        {
          done = true;
          break;
        }
      }

      if (adsInfo.ads.length > 0)
      {
        if (adsInfo.ads.length < this.adsCountInRow)
        {
          let aidx = -1;

          for (let i = adsInfo.ads.length; i < this.adsCountInRow; ++i)
          {
            adsInfo.ads.push({
              contType: 1,
              idx: aidx--
            });
          }
        }
        newList.push(adsInfo);
      }

      if (done)
        break;
    }

    this.setAdsList(platformCode, newList);
  }
}

const CashupStoreInstance = new CashupStore();
const CashupStoreContext = React.createContext(CashupStoreInstance);

const UseCashupStore = () => React.useContext(CashupStoreContext);

export {UseCashupStore, CashupStoreInstance};
