import './Inventory.css';
import '../../App.css';
import { APP_MODE, GetImgUrl, getProductImageUrl } from '../../URL/AppUrl';
import { ConvertDateInfo2 } from '../../Util/Util';

const getPdStatus = (item) =>
{
  if (item.st === 0) //상품 대기상태면
  {
    if (item.ldt <= 7)
    {
      return (<label className='font12 wt400 colorff mgtop10'>{`D-${item.ldt}`}</label>)
    }
    else
    {
      return null;
    }
  }
  else //배송요청을 했다면
  {
    switch(item.rct.st)
    {
      case 0:
      case 1:
        return (<label className='font14 wt400 colorffc8 mgtop10'>결제대기</label>)
      case 2:
        return (<label className='font14 wt400 colorffc8 mgtop10'>결제완료</label>)
      case 3:
        return (<label className='font14 wt400 colorffc8 mgtop10'>배송준비</label>)
      case 4:
        return (<label className='font14 wt400 colorffc8 mgtop10'>배송중</label>)
      case 5:
        return (<label className='font14 wt400 colorffc8 mgtop10'>배송완료</label>)
      case 100:
        return (<label className='font14 wt400 colorffc8 mgtop10'>배송취소</label>)
      case 200:
        return (<label className='font14 wt400 colorffc8 mgtop10'>주문취소</label>)
      default:
        return null;
    }
  }
}

//상품의 상태에 적합한 버튼 정보를 얻음
const getPdButton = (props, item) =>
{
  if (item.st === 0) //대기상태의 상품, 배송신청 및 팡환급가능
  {
    return (
      <div className='wd100p rowcc btncur'>
        <div className='ivpdbtn1 rowcc' onClick={() => props.handleEditItem(0, item)}>
          <img src={GetImgUrl("ivbt1.png")} alt="배송신청" className='img1515'/>
          <label className='font14 wt400 color31 btncur'>&nbsp;배송신청</label>
        </div>

        <div className='ivpdbtn2 rowcc' onClick={() => props.handleEditItem(1, item)}>
          <img src={GetImgUrl("ivbt2.png")} alt="팡 환급" className='img1515'/>
          <label className='font14 wt400 color31 btncur'>&nbsp;팡 환급</label>
        </div>
      </div>
    );
  }
  else //배송요청된 상품, 상세정보 조회가능
  {
    return (
      <div className='ivpdbtn3 rowcc btncur' onClick={() => props.handleEditItem(2, item)}>
        <label className='font14 wt400 colorb3 btncur'>상세정보</label>
      </div>
    );
  }
}

//코멘트 작성가능한지 확인
const checkNeedComment = (item) =>
{
  return (item.ctidx < 1 && ((item.fm & 0x1) !== 0)); //코멘트 작성되지 않았고, 자랑하기 가능한 상품이면
}

const InvenPdItem = (props) =>
{
  const needComment = checkNeedComment(props.item);

  return (
    <div className='invenpditem colcc mgbt20'>
      <div className='invenpditeminfo colcc'>
        <div className='invenpditeminfodesc rowlc mgtop10'>
          <div className='invenpdimgbg rowcc'>
            <img src={getProductImageUrl(props.item.ic)} alt="상품" className='invenpdimg'/>
          </div>

          <div className='invenpdinfoarea coltl mglt10'>
            <label className='font15 wt400 color31 mgtop2'>{APP_MODE === 0 ? `${props.item.nm}(${props.item.idx})` : props.item.nm}</label>
            {
              props.item.st === 0 ? (<label className='font14 wt400 colorb3 mgtop5'>{`${ConvertDateInfo2(props.item.ld, ".")} 23시 59분까지`}</label>) : null
            }

            {getPdStatus(props.item)}
          </div>
        </div>

        <div className='ivpdboxin colsc mgtop10'>
          <div className='invenline1'></div>

          {getPdButton(props, props.item)}
        </div>
      </div>

      {
        needComment ? (
          <div className='ivpdcmtbox mgtop5 rowlc btncur' onClick={() => props.handleEditItem(3, props.item)}>
            <img src={GetImgUrl("ivcmt.png")} alt="소감" className='img1515 mgrt5'/>
            <label className='font12 wt400 colorff mgrt5 btncur'>자랑하기</label>
            <label className='font12 wt400 colorb3 btncur'>소감을 작성해 주시면 100팡을 드려요</label>
          </div>
        ) : null
      }

    </div>
  );
}

export default InvenPdItem;