import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { IsValidS, ToBase64, cancelAxiosRequest } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

//머니, 팡, 환급 내역등의 내역조회
class PointStore {
  cashLogs = null; //머니 적립/사용로그
  pangLogs = null; //팡 적립/사용로그
  wdLogs = null; //출금로그

  //비옵저버 변수
  cashRefIdx = 0;
  pangRefIdx = 0;
  wdRefIdx = 0;

  clearAll = () =>
  {
    this.cashLogs = null; //머니 적립/사용로그
    this.pangLogs = null; //팡 적립/사용로그
    this.wdLogs = null; //출금로그
    this.cashRefIdx = 0;
    this.pangRefIdx = 0;
    this.wdRefIdx = 0;
  }

  clearCashLog = () =>
  {
    this.cashLogs = null; //머니 적립/사용로그
    this.cashRefIdx = 0;
  }

  clearPangLog = () =>
  {
    this.pangLogs = null; //팡 적립/사용로그
    this.pangRefIdx = 0;
  }

  clearWdLog = () =>
  {
    this.wdLogs = null; //출금로그
    this.wdRefIdx = 0;
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      cashLogs : observable,
      pangLogs : observable,
      wdLogs : observable,
      
      getCashLogCount: computed,
      getPangLogCount: computed,
      getWdLogCount: computed,

      clearAll: action,
      clearCashLog: action,
      clearPangLog: action,
      clearWdLog: action,

      parseCashLogListResult: action,
      parsePangLogListResult: action,
      parseWdLogListResult: action,
      parseWdReqResult: action,
    });
  }
  
  //머니로그개수
  get getCashLogCount()
  {
    if (!IsValidS(this.cashLogs))
      return 0;

    return this.cashLogs.length;
  }

  //팡로그개수
  get getPangLogCount()
  {
    if (!IsValidS(this.pangLogs))
      return 0;

    return this.pangLogs.length;
  }

  //환급로그개수
  get getWdLogCount()
  {
    if (!IsValidS(this.wdLogs))
      return 0;

    return this.wdLogs.length;
  }
  //-----------------------------------------------------------------------------------------------------------------------
  //머니 로그를 조회함
  loadCashLogs = (sd, ed, callback) => {

    const params = new URLSearchParams();
    params.append("sd", sd.toString());
    params.append("ed", ed.toString());
    params.append("pos", this.cashRefIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("cashp/clist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseCashLogListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseCashLogListResult(null, callback);
    });
  }

  parseCashLogListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseCashLogListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {

          if (this.cashRefIdx === 0 || this.cashRefIdx > item.idx)
            this.cashRefIdx = item.idx;
        });

        if (IsValidS(this.cashLogs))
        {
          this.cashLogs = [...this.cashLogs, ...result.list];
        }
        else
          this.cashLogs = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------
  //팡 로그를 조회함
  loadPangLogs = (sd, ed, callback) => {

    const params = new URLSearchParams();
    params.append("sd", sd.toString());
    params.append("ed", ed.toString());
    params.append("pos", this.pangRefIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("cashp/plist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parsePangLogListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parsePangLogListResult(null, callback);
    });
  }

  parsePangLogListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parsePangLogListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {

          if (this.pangRefIdx === 0 || this.pangRefIdx > item.idx)
            this.pangRefIdx = item.idx;
        });

        if (IsValidS(this.pangLogs))
        {
          this.pangLogs = [...this.pangLogs, ...result.list];
        }
        else
          this.pangLogs = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------
  //환급신청 내역 조회
  loadWdLogs = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("pos", this.wdRefIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("cashp/wdlist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseWdLogListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseWdLogListResult(null, callback);
    });
  }

  parseWdLogListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseWdLogListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {

          if (this.wdRefIdx === 0 || this.wdRefIdx > item.idx)
            this.wdRefIdx = item.idx;
        });

        if (IsValidS(this.wdLogs))
        {
          this.wdLogs = [...this.wdLogs, ...result.list];
        }
        else
          this.wdLogs = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }
  //-----------------------------------------------------------------------------------------------------------------------
  /*머니 환급신청
  un : 계좌주
  sn1, sn2 : 주민번호
  bc : 은행코드
  ban : 계좌번호
  pnt : 환급신청액
  */
  requestWd = (un, sn1, sn2, bc, ban, pnt, callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("un", ToBase64(un));
    params.append("sn1", sn1);
    params.append("sn2", sn2);
    params.append("bc", bc.toString());
    params.append("ban", ban);
    params.append("pnt", pnt.toString());

    axios({
      method:"POST",
      url: GetApiUrl("cashp/wdreq/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseWdReqResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseWdReqResult(null, callback);
    });
  }

  parseWdReqResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseWdReqResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      //이용자 포인트에서 환급 신청액 만큼 차감한다.
      LoginStoreInstance.subUserPoint(result.pnt);
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
}

const PointStoreInstance = new PointStore();
const PointStoreContext = React.createContext(PointStoreInstance);

const UsePointStore = () => React.useContext(PointStoreContext);

export {UsePointStore, PointStoreInstance};


