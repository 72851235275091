import './Inventory.css';
import '../../App.css';
import TopBar2 from '../Top/TopBar2';
import { getProductImageUrl } from '../../URL/AppUrl';
import { ConvertDateInfo2, GetDataDateAndTime, GetFormattedNum, GetFormattedPhone, IsValidV, getDeliveryCompNameByCode } from '../../Util/Util';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

//상품의 상태 정보를 얻음
const getPdStatus = (item) =>
{
  if (item.st === 0) //상품 대기상태면
  {
    if (item.ldt <= 7)
    {
      return (<label className='font12 wt400 colorff mgtop5'>{`D-${item.ldt}`}</label>)
    }
    else
    {
      return null;
    }
  }
  else
  {
    switch(item.rct.st)
    {
      case 0:
      case 1:
        return (<label className='font14 wt400 colorffc8 mgtop5'>결제대기</label>)
      case 2:
        return (<label className='font14 wt400 colorffc8 mgtop5'>결제완료</label>)
      case 3:
        return (<label className='font14 wt400 colorffc8 mgtop5'>배송준비</label>)
      case 4:
        return (<label className='font14 wt400 colorffc8 mgtop5'>배송중</label>)
      case 5:
        return (<label className='font14 wt400 colorffc8 mgtop5'>배송완료</label>)
      case 100:
        return (<label className='font14 wt400 colorffc8 mgtop5'>배송취소</label>)
      default:
        return null;
    }
  }
}

const getPdButtonTitle = (item) =>
{
  if (item.rct.st < 4)
  {
    if (item.rct.pt !== 0)
      return "배송준비중";
    else
      return "입금계좌 정보";
  }
  else
  {
    return "송장번호";
  }
}

//결제수단
const getPayModeText = (item) =>
{
  if (item.rct.am < 1 && item.rct.pnt < 1)
    return "무료배송";
  if (item.rct.pt === 0)
    return "무통장입금";
  else if (item.rct.pt === 1)
    return "신용카드";
  else if (item.rct.pt === 2)
    return "머니결제";
  else
    return "";
}

//결제에 사용한 머니양
const getCashUsedValue = (item) =>
{
  if (item.rct.pnt > 0)
  {
    return GetFormattedNum(item.rct.pnt);
  }
  else
    return "-";
}

const getPayAmount = (item) =>
{
  if (item.rct.am < 1)
    return "-";

  return `${GetFormattedNum(item.rct.am - item.rct.pnt)}원`;
}

//입금 계좌정보
const InvenOrderBank = (props) =>
{
  return (
    <div className='ivpddtpopinfobg colcc'>
      <div className='ivpddtpopinfobgpop colcc'>
        <div className='ivpddtpopinfobgpopin rowlc mgtop20'>
          <label className='font14 wt300 colorb3'>예금주명</label>
          <label className='font14 wt300 color31'>{props.item.un}</label>
        </div>

        <div className='ivpddtpopinfobgpopin rowlc mgtop10'>
          <label className='font14 wt300 colorb3'>거래은행</label>
          <label className='font14 wt300 color31'>{props.item.bn}</label>
        </div>

        <div className='ivpddtpopinfobgpopin rowlc mgtop10'>
          <label className='font14 wt300 colorb3'>계좌번호</label>
          <label className='font14 wt300 color31'>{props.item.ba}</label>
        </div>

        <div className='wd100p rowcc mgtop20'>
          <button className='ivpddtpopinfobgpopbt1 font14 wt400 color31 btncur' onClick={props.handleClose}>닫기</button>
          <CopyToClipboard text={props.item.ba} onCopy={() => props.handleCopyInfo()}>
            <button className='ivpddtpopinfobgpopbt2 font14 wt400 colorw btncur' onClick={props.handleCopyInfo}>계좌복사</button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

//배송정보(송장번호)
const InvenOrderDeli = (props) =>
{
  return (
    <div className='ivpddtpopinfobg colcc'>
      <div className='ivpddtpopinfobgpop colcc'>
        <div className='ivpddtpopinfobgpopin rowsc mgtop20'>
          <label className='font14 wt400 color31'>{getDeliveryCompNameByCode(props.item.dc)}</label>
          <label className='font14 wt400 color31'>{props.item.dn}</label>
        </div>

        <div className='ivpddtpopinfobgpopin rowcc lht18'>
          <label className='font12 wt300 color31'>송장을 복사하여 배송조회를 검색하신 후 운송장 번호에 붙여넣기 하시면 택배 위치를 알 수 있습니다.</label>
        </div>

        <div className='wd100p rowcc mgtop30'>
          <button className='ivpddtpopinfobgpopbt1 font14 wt400 color31 btncur' onClick={props.handleClose}>닫기</button>
          <CopyToClipboard text={props.item.dn} onCopy={() => props.handleCopyInfo()}>
            <button className='ivpddtpopinfobgpopbt2 font14 wt400 colorw btncur' onClick={props.handleCopyInfo}>송장복사</button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}

//인벤토리 상품 상세 보기창
const InvenPdDetail = (props) =>
{
  const [showDeliInfo, setShowDeliInfo] = useState(false);
  const [showBankInfo, setShowBankInfo] = useState(false);

  return (
    <div className='ivpddetpop coltc'>
      {showDeliInfo ? <InvenOrderDeli item={props.item.rct.di} handleClose={() => setShowDeliInfo(false)} handleCopyInfo={() => setShowDeliInfo(false)}/> : null}
      {showBankInfo ? <InvenOrderBank item={props.item.rct.bk} handleClose={() => setShowBankInfo(false)} handleCopyInfo={() => setShowBankInfo(false)}/> : null}
      
      <TopBar2 title="상세정보" handleBack={props.handleClose} withLine={true}/>

      <div className='ivpddetpopin coltl'>
        <label className='font12 wt400 colorb3 mgtop20'>{`주문번호 ${props.item.rct.idx}`}</label>

        <div className='ivpddtinfobg colcc mgtop10'>
          <div className='ivpddtinfobg2 rowss mgtop10'>
            <div className='ivpddtinfoimg rowcc'>
              <img src={getProductImageUrl(props.item.ic)} alt={`${props.item.nm}`} className='ivpddtinfoimg2'/>
            </div>

            <div className='ivpddtinfobg2r coltl'>
              <label className='font14 wt400 color31'>{props.item.nm}</label>
              {
                props.item.st === 0 ? (
                  <label className='font12 wt400 colorffc8 mgtop5'>{`${ConvertDateInfo2(props.item.ld, ".")} 23시 59분까지`}</label>
                ) : null
              }
              {getPdStatus(props.item)}
            </div>
          </div>

          <div className='ivpddtline mgtop10'></div>

          <div className='ivpddt1 rowcc btncur' onClick={() => {
            if (props.item.rct.st < 4)//아직 배송시작전
            {
              if (props.item.rct.pt === 0) //결제수단이 무통장 입금이면, 계좌정보를 보여준다.
              {
                if (IsValidV(props.item.rct.bk))
                {
                  setShowBankInfo(true);
                }
              }
            }
            else //배송중일떄 버튼이 눌렸다면, 송장번호를 보여주면된다.
            {
              if (IsValidV(props.item.rct.di))
                setShowDeliInfo(true);
            }
          }}>
            <label className='font12 wt400 colorb3 btncur'>{getPdButtonTitle(props.item)}</label>
          </div>
        </div>

        <label className='font12 wt400 color31 mgtop25'>주문자 정보</label>
        <div className='ivpddtline2 mgtop5'></div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>주문자</label>
          <label className='font14 wt300 color31'>{props.item.rct.di.un}</label>
        </div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>배송지</label>
          <label className='font14 wt300 color31'>{props.item.rct.di.adr}</label>
        </div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>연락처</label>
          <label className='font14 wt300 color31'>{GetFormattedPhone(props.item.rct.di.ph)}</label>
        </div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>배송메모</label>
          <label className='font14 wt300 color31'>{props.item.rct.mm}</label>
        </div>

        <label className='font12 wt400 color31 mgtop25'>결제 정보</label>
        <div className='ivpddtline2 mgtop5'></div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>결제 날짜</label>
          <label className='font14 wt300 color31'>{props.item.rct.st < 2 ? "-" : GetDataDateAndTime(props.item.rct.pd)}</label>
        </div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>결제 방법</label>
          <label className='font14 wt300 color31'>{getPayModeText(props.item)}</label>
        </div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>머니 사용</label>
          <label className='font14 wt300 color31'>{getCashUsedValue(props.item)}</label>
        </div>

        <div className='wd100p rowlc mgtop15'>
          <label className='font14 wt300 colorb3 ivpddttt'>결제 금액</label>
          <label className='font14 wt300 color31'>{getPayAmount(props.item)}</label>
        </div>
      </div>
    </div>
  );
}

export default InvenPdDetail;