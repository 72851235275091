import React from 'react';

import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';

import axios from 'axios';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';
import { FromBase64, IsValidS } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';

//팡팡박스 관련
class PangStore {
  //옵저버 변수
  itemList = null; //수신된 상품 목록, 행에 맞게 적절히 조절된 데이터
  homeItems = null; //홈페이지에 출력할 상품 5개

  //비옵저버변수
  itemSource = null; //서버에서 수신된 각상품의 목록
  refPos = 0; //상품목록읽기 기준위치
  winPdListReady = false; //당첨가능 상품 목록을 한번이라도 수신했나?

  openAniUrl = null; //팡팡박스 오픈 연출 페이지 주소
  openAniUrlReady = false; //오픈 연출 준비되었나?

  clearAll = () =>
  {
    this.itemList = null; //수신된 상품 목록, 행에 맞게 적절히 조절된 데이터
    this.homeItems = null; //홈페이지 캐로셀광고

    //비옵저버변수
    this.itemSource = null; //서버에서 수신된 각상품의 목록

    this.openAniUrl = null; //팡팡박스 오픈 연출 페이지 주소
    this.openAniUrlReady = false; //오픈 연출 준비되었나?
  }

  //팡팡박스 출력용 당첨가능상품 목록
  clearWinPdList = () =>
  {
    this.itemList = null; //수신된 상품 목록, 행에 맞게 적절히 조절된 데이터
    this.itemSource = null; //서버에서 수신된 각상품의 목록
    this.refPos = 0; //상품목록읽기 기준위치  
    this.winPdListReady = false; //당첨가능 상품 목록을 한번이라도 수신했나?
  }

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      itemList : observable,
      homeItems : observable,

      clearAll: action,
      clearWinPdList: action,
      parseWinPdListResult: action,
      parseWinPdListForHomeResult: action,
    });
  }

  //---------------------------------------------------------------------------------------------------------------------------------
  //상품 소스를 기반으로 상품리스트를 갱신한다.
  refreshItemList = () =>
  {
    if (LOG_ENABLE)
      console.log(`refreshItemList`);

    if (!IsValidS(this.itemSource))
    {
      return;
    }

    let newList = [];

    let index = 0;
    let srcCount = this.itemSource.length;
    let itemIdx = 1;

    while(true)
    {
      const itemInfo = {
        idx: itemIdx,
        items: []
      };

      ++itemIdx;

      let done = false;

      for (let i = 0;i < 2; ++i)
      {
        if (index < srcCount)
        {
          itemInfo.items.push(this.itemSource[index]);
          ++index;
        }
        else
        {
          done = true;
          break;
        }
      }

      if (itemInfo.items.length > 0)
        newList.push(itemInfo);

      if (done)
        break;
    }

    this.itemList = newList;
  }
  //당첨가능 상품 목록읽기-------------------------------------------------------------------------------------------------------------

  //rt : 최신순(0), 가격높은(1), 가격낮은(2)
  loadWinPdList = (rt, callback) => {

    const params = new URLSearchParams();
    params.append("rt", rt.toString());
    params.append("pos", this.refPos.toString());

    if (LOG_ENABLE)
      console.log(`loadWinPdList : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/gpd/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseWinPdListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseWinPdListResult(null, callback);
    });
  }

  parseWinPdListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseWinPdListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      this.winPdListReady = true;
      this.refPos += 12;

      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeWinPd(item);
        });

        if (IsValidS(this.itemSource))
        {
          this.itemSource = [...this.itemSource, ...result.list];
        }
        else
          this.itemSource = result.list;
      }

      this.refreshItemList();
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeWinPd = (item) =>
  {
    if (IsValidS(item.nm))
      item.nm = FromBase64(item.nm);

    if (IsValidS(item.lk))
      item.lk = FromBase64(item.lk);
  }
  //당첨가능 상품 목록읽기-------------------------------------------------------------------------------------------------------------
  //홈페이지에 출력할 가격 높은순 정렬된 상품목록 조회
  loadWinPdListForHome = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "3");

    if (LOG_ENABLE)
      console.log(`loadWinPdListForHome : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/gpd/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parseWinPdListForHomeResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      this.parseWinPdListForHomeResult(null, callback);
    });
  }

  parseWinPdListForHomeResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseWinPdListForHomeResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidS(result.list))
      {
        result.list.forEach(item => {
          this.decodeWinPd(item);
        });

        this.homeItems = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }

  decodeWinPd = (item) =>
  {
    if (IsValidS(item.nm))
      item.nm = FromBase64(item.nm);

    if (IsValidS(item.lk))
      item.lk = FromBase64(item.lk);
  }
  //상품 상세정보 읽기-------------------------------------------------------------------------------------------------------------

  //특정상품의 상세 정보를 조회함, 현재는 상품상세정보 이미지 밖에 조회되는게 없음
  loadPdDetail = (iidx, callback) => {

    const params = new URLSearchParams();
    params.append("rt", "4");
    params.append("iidx", iidx.toString());

    if (LOG_ENABLE)
      console.log(`loadPdDetail : ${params.toString()}`);

    axios({
      method:"POST",
      url: GetApiUrl("pang/gpd/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{

      this.parsePdDetailResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parsePdDetailResult(null, callback);
    });
  }

  parsePdDetailResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parsePdDetailResult : ${JSON.stringify(result)}`);

    if (result.ret === 100)
    {
      if (this.pdDetailCallback)
        LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret, result.info);
  }
  //팡팡박스 오픈연출 페이지 주소 획득----------------------------------------------------------------------------------------------------------------------

  loadOpenPageUrl = (callback) => {

    const params = new URLSearchParams();
    params.append("rt", "0");

    axios({
      method:"POST",
      url: GetApiUrl("pang/bxcfg/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseBoxOpenUrlResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseBoxOpenUrlResult(null, callback);
    });
  }

  parseBoxOpenUrlResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseBoxOpenUrlResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.openAniUrlReady = true;
      this.openAniUrl = FromBase64(result.data);

      if (LOG_ENABLE)
        console.log(`parseBoxOpenUrlResult : url => ${this.openAniUrl}`);
    }
    else
    {
      this.openAniUrlReady = false;

      if (result.ret === 100)
      {
        LoginStoreInstance.sessionError = true;
      }
    }

    if (callback)
      callback(result.ret);
  }
}

const PangStoreInstance = new PangStore();
const PangStoreContext = React.createContext(PangStoreInstance);

const UsePangStore = () => React.useContext(PangStoreContext);

export {UsePangStore, PangStoreInstance};
