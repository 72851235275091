import './Mission.css';
import '../../App.css';

const MissionSnsAdsDummy = (props) =>
{
  return (
    <div className='mssnsadsimgbg'>
    </div>
  );
}

export default MissionSnsAdsDummy;