import './PangBox.css';
import '../../App.css';

//팡팡박스 구매 성공 팝업
const PangBoxBuySuc = (props) =>
{
  return (
    <div className='pbbuybg colec'>
      <div className='pbbuypop colcc'>
        <div className='pbbuypopin rowlc mgtop20'>
          <label className='font14 wt400 colorff'>구매 성공!</label>
        </div>

        <div className='pbbuypopin rowls mgtop5'>
          <label className='font12 wt300 color31'>지금 박스를 개봉하시겠습니까?</label>
        </div>

        <div className='wd100p rowcc mgtop30'>
          <button className='pbbuybtn1 font14 btncur wt400 color31' onClick={() => props.handleBoxBuySuc(0)}>닫기</button>
          <button className='pbbuybtn2 font14 btncur wt400 colorw' onClick={() => props.handleBoxBuySuc(1)}>개봉하기</button>
        </div>
      </div>
    </div>
  );
}

export default PangBoxBuySuc;