import React from 'react';
import './Unregister.css';
import '../../App.css';

const UnregisterRes = (props) =>
{
  return (
    <div className='unregrespopbg flexcolec'>
      <div className='unregrespop flexcolcc'>
        <div className='unregrespopin flexcolcc'>
          
          <div className='unregrespopinitem flexrowlc btncur' onClick={() => props.handleReason(1)}>
            <label className='font14 weight300 color31 btncur'>더 이상 앱을 사용하고 싶지 않습니다.</label>
          </div>

          <div className='unregline'></div>

          <div className='unregrespopinitem flexrowlc btncur' onClick={() => props.handleReason(2)}>
            <label className='font14 weight300 color31 btncur'>포인트 적립 수단이 적어 이용에 불편합니다.</label>
          </div>

          <div className='unregline'></div>

          <div className='unregrespopinitem flexrowlc btncur' onClick={() => props.handleReason(3)}>
            <label className='font14 weight300 color31 btncur'>사용하기 어렵습니다.</label>
          </div>

          <div className='unregline'></div>

          <div className='unregrespopinitem flexrowlc btncur' onClick={() => props.handleReason(4)}>
            <label className='font14 weight300 color31 btncur'>기타 이유</label>
          </div>
        </div>

        <div className='unregrespopinbtn flexrowcc btncur' onClick={() => props.handleClose()}>
          <label className='font14 weight400 color31 btncur'>닫기</label>
        </div>
      </div>
    </div>
  );
}

export default UnregisterRes;