
import {getKakaoRedirectUrl, LOG_ENABLE,  getNaverRedirectUrl, GetApiUrl} from '../URL/AppUrl';
import axios from 'axios';
import { IsValidV } from '../Util/Util';


class SNSQuery {
  //observable변수
  stVal = 0;

  constructor() {
  }

  clearAll = () =>
  {
    
  }

  kakaoLogin = () =>
  {
    const redirectUrl = getKakaoRedirectUrl();
    const apiKey = 'd7ce66d39400b07b40d65fa8692be82e';

    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${apiKey}&redirect_uri=${redirectUrl}&response_type=code`;
    window.location.href = kakaoURL;
  }

  naverLogin = () =>
  {
    this.stVal = Math.floor(Math.random() * 100000000000).toString().padStart(12, "0");

    const redirectUrl = getNaverRedirectUrl();
    const apiUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=OwjCHnNj9_xrgosDU61C&redirect_uri=${redirectUrl}&state=${this.stVal}`;
    window.location.href = apiUrl;
  }

  //-------------------------------------------------------------------------------------------------------
  //카카오톡 인증진행
  kakaoAuth = (code, callback) => {

    const params = new URLSearchParams();

    params.append("code", code);

    axios({
      method:"POST",
      url: GetApiUrl("social/kakao/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseKakaoAuthResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseKakaoAuthResult(null, callback);
    });
  }

  parseKakaoAuthResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`kakao auth result : ${JSON.stringify(result)}`);

    if (callback)
    {
      callback(result.ret, result);
    }
  }
  //-------------------------------------------------------------------------------------------------------
  //네이버 인증진행
  naverAuth = (code, st, callback) => {

    const params = new URLSearchParams();

    params.append("code", code);
    params.append("st", st);

    axios({
      method:"POST",
      url: GetApiUrl("social/naver/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값, [ 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream' ]
      responseEncoding: 'utf8', // 기본 값
      data: params
    }).then((res)=>{
        this.parseNaverAuthResult(res.data, callback);
    }).catch(error=>{
        console.log(error);
        this.parseNaverAuthResult(null, callback);
    });
  }

  parseNaverAuthResult = (result, callback) => 
  {
    if (!IsValidV(result) || !IsValidV(result.ret))
    {
      if (LOG_ENABLE)
        console.log("request failed.");

      if (callback)
      {
        callback(-1, null);
      }

      return;
    }
    
    if (LOG_ENABLE)
      console.log(`naver auth result : ${JSON.stringify(result)}`);

    if (callback)
    {
      callback(result.ret, result);
    }
  }
}

const SNSQueryInstance = new SNSQuery();

export {SNSQueryInstance};
