import './Guide.css';
import '../../App.css';

const GuideIndi = (props) =>
{
  return (
    <div className={props.index===props.selected ? "gdindi1" : "gdindi2"}></div>
  );
}

export default GuideIndi;