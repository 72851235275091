import React from 'react';
import './HelpPage.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { FaqStoreInstance } from '../../Stores/FaqStore';
import { IsValidS } from '../../Util/Util';
import HelpPageItem from './HelpPageItem';
import HelpQuery from './HelpQuery';
import HelpQueryList from './HelpQueryList';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";

//고객센터
class HelpPage extends React.Component
{
  state = {
    loading: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    currentTab: 0, //전체(0), SNS(1), 팡팡박스(2), 서비스(3)

    noItem: false,

    showQuery: false,
    showQueryList: false,
  };

  currentTab = 0; //전체(0), SNS(1), 팡팡박스(2), 서비스(3)
  intervalId = -1;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setShowMsgPop = (msg, callback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 1, popMsg: msg, popOkBtn: "확인", popCancelBtn: '', popupCallback: callback}));
  }

  setCurrentTab = (v) =>
  {
    if (this.currentTab === v || this.state.loading)
      return;

    this.currentTab = v;
    this.setState((prevState) => ({...prevState, currentTab : v}));

    FaqStoreInstance.clearFaq();
    this.loadFaqList();
  }

  setNoItem = (v) =>
  {
    this.setState((prevState) => ({...prevState, noItem : v}));
  }

  setShowQuery = (v) =>
  {
    this.setState((prevState) => ({...prevState, showQuery : v}));
  }

  setShowQueryList = (v) =>
  {
    this.setState((prevState) => ({...prevState, showQueryList : v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      this.loadFaqList();
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    this.loadFaqList();
  }

  //서버에서 faq리스트 읽기
  loadFaqList = () =>
  {
    if (this.state.loading)
      return;

    this.setLoading(true);
    FaqStoreInstance.loadFaqList(this.currentTab === 0 ? -1 : this.currentTab, this.onFaqLoaded);
  }

  onFaqLoaded = (resultCode, readCount) =>
  {
    this.setLoading(false);

    if (resultCode === 0 && readCount < 1)
    {
      this.setNoItem(true);
    }
  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0:
        this.showTerm(true);
        return;
      case 1:
        this.showPrivacy(true);
        return;
      default:
        return;
    }
  }

  //1:1문의하기
  handleQuery = () =>
  {
    if (this.state.loading)
      return;

    this.setShowQuery(true);
  }

  //1:1문의 목록
  handleQueryList = () =>
  {
    if (this.state.loading)
      return;

    this.setShowQueryList(true);
  }

  render()
  {
    let faqList = null;

    if (IsValidS(FaqStoreInstance.faqList))
    {
      faqList = FaqStoreInstance.faqList.map(item => <HelpPageItem key={item.idx} item={item} />);
    }

    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showQuery ? (
            <HelpQuery handleClose={() => this.setShowQuery(false)}/>
          ) : null
        }
        {
          this.state.showQueryList ? (
            <HelpQueryList handleClose={() => this.setShowQueryList(false)}/>
          ) : null
        }
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }

        <div className='wd100p max500 colcc'>
          <TopBar2 withLine={true} title="고객센터" handleBack={this.handleBack} />

          <div className='wd88p coltl mgtop70'>
            <label className='font16 wt400 color31'>무엇을 도와드릴까요?</label>

            <div className='wd100p rowcc mgtop20'>
              <div className='hpbtn1 colevenc mgrt20 btncur' onClick={this.handleQuery}>
                <img src={GetImgUrl("help1.png")} className='img1820' alt="이미지" />
                <label className='font14 wt400 color31 btncur'>1:1 문의</label>
              </div>

              <div className='hpbtn1 colevenc btncur' onClick={this.handleQueryList}>
                <img src={GetImgUrl("help2.png")} className='img2120' alt="이미지" />
                <label className='font14 wt400 color31 btncur'>문의내역</label>
              </div>
            </div>

            <label className='font14 wt400 color31 mgtop30'>자주 찾는 질문</label>

            <div className='wd100p rowlc mgtop10'>
              <Flicking
                viewportTag="div"
                cameraTag="div"
                cameraClass=""
                renderOnSameKey={false}
                align="prev"
                onMove={(e) => {}}
                onWillChange={(e) => {}}
                horizontal={true}
                circular={false}
                interruptable={true}
                //preventDefaultOnDrag={true}
                bound={true}
                panelsPerView={-1}
                moveType="strict"
                resizeOnContentsReady={false}
                autoResize={true}
                autoInit={true}
                threshold={100}
                //ref={this.tabRef}
              >
                <div className={this.state.currentTab === 0 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(0)}>
                  <label className={this.state.currentTab === 0 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>전체</label>
                </div>

                <div className={this.state.currentTab === 5 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(5)}>
                  <label className={this.state.currentTab === 5 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>소개</label>
                </div>

                <div className={this.state.currentTab === 6 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(6)}>
                  <label className={this.state.currentTab === 6 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>머니/팡</label>
                </div>

                <div className={this.state.currentTab === 7 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(7)}>
                  <label className={this.state.currentTab === 7 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>적립소</label>
                </div>

                <div className={this.state.currentTab === 4 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(4)}>
                  <label className={this.state.currentTab === 4 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>빙고팡팡</label>
                </div>

                <div className={this.state.currentTab === 2 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(2)}>
                  <label className={this.state.currentTab === 2 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>팡팡박스</label>
                </div>

                <div className={this.state.currentTab === 1 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(1)}>
                  <label className={this.state.currentTab === 1 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>SNS</label>
                </div>

                <div className={this.state.currentTab === 8 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(8)}>
                  <label className={this.state.currentTab === 8 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>친구초대</label>
                </div>
                
                <div className={this.state.currentTab === 9 ? 'hptab1 rowcc mgrt10 btncur' : 'hptab2 rowcc mgrt10 btncur'} onClick={() => this.setCurrentTab(9)}>
                  <label className={this.state.currentTab === 9 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>환급</label>
                </div>

                <div className={this.state.currentTab === 3 ? 'hptab1 rowcc btncur' : 'hptab2 rowcc btncur'} onClick={() => this.setCurrentTab(3)}>
                  <label className={this.state.currentTab === 3 ? 'font12 wt400 colorff btncur' : 'font12 wt400 colorb3 btncur'}>서비스</label>
                </div>
              </Flicking>
            </div>
            
            <div className='wd100p coltl'>
              {faqList}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(HelpPage));

