import React from 'react';
import './HelpPage.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MYPANG } from '../../Stores/NaviStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { FaqStoreInstance } from '../../Stores/FaqStore';
import { IsValidS, IsValidV, UploadFile, checkEmail } from '../../Util/Util';



//고객센터 문의하기
class HelpQuery extends React.Component
{
  state = {
    loading: false,

    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    title: '',
    desc: '',
    email: ''    ,

    file1: null,
    file2: null,
    file3: null,
  };

  currentUploadIndex = 0;
  filename1 = "n";
  filename2 = "n";
  filename3 = "n";

  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setShowMsgPop = (msg, callback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 1, popMsg: msg, popOkBtn: "확인", popCancelBtn: '', popupCallback: callback}));
  }

  setValue = (index, v) =>
  {
    switch(index)
    {
      case 0:
        this.setState((prevState) => ({...prevState, title: v}));
        return;
      case 1:
        this.setState((prevState) => ({...prevState, desc: v}));
        return;
      case 2:
        this.setState((prevState) => ({...prevState, email: v}));
        return;
      default:
        return;
    }
  }

  setImage = (index, file) =>
  {
    if (!file)
      return;

    switch(index)
    {
      case 0:
        this.setState((prevState) => ({...prevState, file1: file}));
        break;
      case 1:
        this.setState((prevState) => ({...prevState, file2: file}));
        break;
      case 2:
        this.setState((prevState) => ({...prevState, file3: file}));
        break;
    }
  }

  setFileName = (index, file) =>
  {
    if (!file)
      return;

    switch(index)
    {
      case 0:
        this.filename1 = file;
        break;
      case 1:
        this.filename2 = file;
        break;
      case 2:
        this.filename3 = file;
        break;
    }
  }
  
  handleBack = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  componentDidMount() {
    
  }

  componentWillUnmount = () =>
  {
    
  }

  handleClose = () =>
  {
    if (this.state.loading)
      return;

    this.props.handleClose();
  }

  handleAddFile = () =>
  {
    if (this.state.loading)
      return;

    let v = null;

    if (!IsValidV(this.state.file1))
      v = document.getElementById("fileInput1");
    else if (!IsValidV(this.state.file2))
      v = document.getElementById("fileInput2");
    else if (!IsValidV(this.state.file3))
      v = document.getElementById("fileInput3");

    if (v)
      v.click();
  }

  isValid = () =>
  {
    return IsValidS(this.state.title) && IsValidS(this.state.desc) && IsValidS(this.state.email);
  }

  handleSubmit = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.title))
    {
      this.setShowMsgPop("문의 제목을 입력해 주세요.", null);
      return;
    }

    if (!IsValidS(this.state.desc))
    {
      this.setShowMsgPop("문의 내용을 입력해 주세요.", null);
      return;
    }

    if (!IsValidS(this.state.email))
    {
      this.setShowMsgPop("답변 받으실 이메일 주소를 입력해 주세요.", null);
      return;
    }

    if (!checkEmail(this.state.email))
    {
      this.setShowMsgPop("이메일 주소를 올바르게 입력해 주세요.", null);
      return;
    }

    this.setShowPopup(2, "문의사항을 접수할까요?", "확인", "취소", (yes) => {
      this.closePopup();

      if (!yes)
        return;

      this.setLoading(true);

      if (IsValidV(this.state.file1) || IsValidV(this.state.file2) || IsValidV(this.state.file3))
      {
        this.currentUploadIndex = 0;
        this.filename1 = "n";
        this.filename2 = "n";
        this.filename3 = "n";
        this.selectUploadIndex();
        this.uploadFiles();
      }
      else
      {
        FaqStoreInstance.registerQuery(this.state.title, this.state.desc, this.state.email, 
          "n", 
          "n", 
          "n", this.onRegisterDone);
      }
    });
  }

  selectUploadIndex = () =>
  {
    while(true)
    {
      switch(this.currentUploadIndex)
      {
        case 0:
          if (IsValidV(this.state.file1))
            return true;
          ++this.currentUploadIndex;
          break;
        case 1:
          if (IsValidV(this.state.file2))
            return true;
          ++this.currentUploadIndex;
          break;
        case 2:
          if (IsValidV(this.state.file3))
            return true;
          ++this.currentUploadIndex;
          break;
        default:
          return false;
      }
    }
  }

  getCurrentUploadFile = () =>
  {
    switch(this.currentUploadIndex)
    {
      case 0:
        return this.state.file1;
      case 1:
        return this.state.file2;
      case 2:
        return this.state.file3;
      default:
        return null;
    }
  }

  uploadFiles = () =>
  {

    UploadFile(4, this.getCurrentUploadFile(), (success, oldName, newName) => {
      
      if (!success)
      {
        this.setShowMsgPop("파일 첨부 중 오류가 발생했습니다.", null);
        this.setLoading(false);
        return;
      }

      switch(this.currentUploadIndex)
      {
        case 0:
          this.setFileName(0, newName);
          break;
        case 1:
          this.setFileName(1, newName);
          break;
        case 2:
          this.setFileName(2, newName);
          break;
      }

      ++this.currentUploadIndex;

      if (!this.selectUploadIndex())
      {
        FaqStoreInstance.registerQuery(this.state.title, this.state.desc, this.state.email, 
          this.filename1, 
          this.filename2, 
          this.filename3, this.onRegisterDone);
      }
      else
      {
        this.uploadFiles();
      }
    });
  }

  onRegisterDone = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.setShowMsgPop("접수가 완료되었습니다.", () => {this.props.handleClose()});
        return;
      case 1:
        this.setShowMsgPop("잘못된 요청입니다.");
        return;
      case 4:
        this.setShowMsgPop("답변 받으실 이메일 주소를 다시 한번 확인해 주세요.");
        return;
      default:
        this.setShowMsgPop("문의사항 접수 중 오류가 발생했습니다.\n잠시 후 이용해 주세요.");
        return;
    }
  }

  render()
  {
    const isValid = this.isValid();

    return (
      <div className='hpquerypop coltc'>
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='wd100p max500 colcc'>
          <TopBar2 title="1:1 문의" withLine={true} handleBack={this.handleClose} />

          <div className='wd88p coltl mgtop60'>
            <label className='font16 wt400 color31'>어떤 문의가 필요하세요?</label>
            <label className='font12 wt400 colorb3 mgtop10'>확인 후 빠른 시일 내에 답변드리겠습니다.</label>

            <label className='font12 wt500 color31 mgtop20'>제목</label>
            <input type="text" value={this.state.title} onChange={(e) => this.setValue(0, e.target.value)} maxLength={28} className='hpinp1 font14 wt300 color31 mgtop5' placeholder='제목을 입력해 주세요.'/>

            <label className='font12 wt500 color31 mgtop20'>문의내용</label>
            <textarea type="text" value={this.state.desc} onChange={(e) => this.setValue(1, e.target.value)} maxLength={290} className='hpinp2 font14 wt300 color31 mgtop5' placeholder='문의 할 내용을 자세하게 알려주세요.'/>

            <button className='hpqbtn1 font12 wt400 colorb3 btncur mgtop10' onClick={this.handleAddFile}>+ 첨부파일</button>
            <label className='font12 wt300 color31 mgtop10'>※ 이미지는 JPEG, PNG 파일만 가능하며, 최대 3개까지 첨부 가능합니다.</label>

            <div className='wd100p rowlc mgtop10'>
              <div className={IsValidV(this.state.file1) ? 'hpqimgbg rowcc mgrt10' : 'hpqimgbg2 rowcc mgrt10'}>
                <img alt="첨부이미지" id="img1" className={IsValidV(this.state.file1) ? 'hpqimg' : 'hpqimg2'}/>
              </div>

              <div className={IsValidV(this.state.file2) ? 'hpqimgbg rowcc mgrt10' : 'hpqimgbg2 rowcc mgrt10'}>
                <img alt="첨부이미지" id="img2" className={IsValidV(this.state.file2) ? 'hpqimg' : 'hpqimg2'}/>
              </div>

              <div className={IsValidV(this.state.file3) ? 'hpqimgbg rowcc mgrt10' : 'hpqimgbg2 rowcc mgrt10'}>
                <img alt="첨부이미지" id="img3" className={IsValidV(this.state.file3) ? 'hpqimg' : 'hpqimg2'}/>
              </div>
            </div>
            
            <label className='font12 wt500 color31 mgtop20'>답변 받으실 이메일 주소</label>
            <input type="text" value={this.state.email} onChange={(e) => this.setValue(2, e.target.value)} maxLength={48} className='hpinp1 font14 wt300 color31 mgtop5' placeholder='이메일 주소를 입력해 주세요.'/>
            <label className='font12 wt300 color31 mgtop10'>답변은 평균 7일 정도 소요되며, 문의하신 답변은 기재된 이메일 주 소로 알려드리니 메일함을 확인해 주세요.</label>

          </div>

          <button className={isValid ? 'hpqbtn2 font14 colorw wt400 btncur' : 'hpqbtn3 font14 colorb3 wt400 btncur'} onClick={this.handleSubmit}>작성완료</button>
        </div>
        

        <input type="file" id="fileInput1" className="afffile" accept="image/*"
          onChange={(e) => {

            if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

            if (LOG_ENABLE)
              console.log(e.target.files[0]);

            if (e.target.files[0].size > ( 10 * 1024 * 1024))
            {
              this.setShowPopup(1, "10MB이하의 파일만 첨부 가능합니다.", "확인", "", null);
              return;
            }

            const preview = document.getElementById("img1");
                  
            if (preview)
            {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                preview.src = reader.result;
  
                this.setImage(0, e.target.files[0]);
              };
  
              reader.readAsDataURL(e.target.files[0]);
            }
            
        }}/>

        <input type="file" id="fileInput2" className="afffile" accept="image/*"
          onChange={(e) => {

            if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

            if (LOG_ENABLE)
              console.log(e.target.files[0]);

            if (e.target.files[0].size > ( 10 * 1024 * 1024))
            {
              this.setShowPopup(1, "10MB이하의 파일만 첨부 가능합니다.", "확인", "", null);
              return;
            }

            const preview = document.getElementById("img2");
                  
            if (preview)
            {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                preview.src = reader.result;
  
                this.setImage(1, e.target.files[0]);
              };
  
              reader.readAsDataURL(e.target.files[0]);
            }
            
        }}/>

        <input type="file" id="fileInput3" className="afffile" accept="image/*"
          onChange={(e) => {

            if (!IsValidV(e) || !IsValidV(e.target) || !IsValidS(e.target.files))
              return;

            if (LOG_ENABLE)
              console.log(e.target.files[0]);

            if (e.target.files[0].size > ( 10 * 1024 * 1024))
            {
              this.setShowPopup(1, "10MB이하의 파일만 첨부 가능합니다.", "확인", "", null);
              return;
            }

            const preview = document.getElementById("img3");
                  
            if (preview)
            {
              const reader = new FileReader();
              reader.onloadend = (evt) => {
                preview.src = reader.result;
  
                this.setImage(2, e.target.files[0]);
              };
  
              reader.readAsDataURL(e.target.files[0]);
            }
            
        }}/>
      </div>
    );
  }
}

export default observer(HelpQuery);

