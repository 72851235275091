import React from 'react';
import './Guide.css';
import '../../App.css';
import { LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import MsgPop from '../Common/MsgPop';
import MsgPop2 from '../Common/MsgPop2';
import { GuideStoreInstance } from '../../Stores/GuideStore';
import { IsValidS } from '../../Util/Util';
import GuideItem from './GuideItem';
import GuidePop from './GuidePop';

//이용가이드
class Guide extends React.Component
{
  state = {
    loading: false,
    
    showPop: 0,
    popMsg: '',
    popOkBtn: '',
    popCancelBtn: '',
    popupCallback: null,

    showGuide: false,
  };
  intervalId = -1;
  guideItem = null;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setShowPopup = (v, msg, okBtn, cancelBtn, popupCallback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: v, popMsg: msg, popOkBtn: okBtn, popCancelBtn: cancelBtn, popupCallback: popupCallback}));
  }

  closePopup = () =>
  {
    this.setState((prevState) => ({...prevState, showPop: 0, popMsg: '', popOkBtn: '', popCancelBtn: '', popupCallback: null}));
  }

  setShowMsgPop = (msg, callback) =>
  {
    this.setState((prevState) => ({...prevState, showPop: 1, popMsg: msg, popOkBtn: "확인", popCancelBtn: '', popupCallback: callback}));
  }

  setShowGuide = (v) =>
  {
    this.setState((prevState) => ({...prevState, showGuide: v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
    else
    {
      if (!GuideStoreInstance.guideReady)
      {
        this.setLoading(true);
        GuideStoreInstance.loadGuideList(this.onLoadGuide);
      }
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }

    GuideStoreInstance.clearAll();
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

    if (!GuideStoreInstance.guideReady)
    {
      this.setLoading(true);
      GuideStoreInstance.loadGuideList(this.onLoadGuide);
    }
  }
 
  onLoadGuide = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      this.setShowMsgPop("이용 가이드 조회중 오류가 발생했습니다.", null);
      return;
    }
  }

  handleGuide = (guide) =>
  {
    if (this.state.loading)
      return;

    this.guideItem = guide;
    this.setShowGuide(true);
  }

  render()
  {
    let guides = null;

    if (IsValidS(GuideStoreInstance.guideList))
    {
      guides = GuideStoreInstance.guideList.map(item=> <GuideItem key={item.idx} item={item} handleGuide={this.handleGuide} />);
    }

    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showGuide ? (
            <GuidePop item={this.guideItem} handleBack={() => this.setShowGuide(false)} />
          ) : null
        }
        {
          this.state.showPop === 1 ? (
            <MsgPop msg={this.state.popMsg} okBtn={this.state.popOkBtn} handleConfirm={() => {

              if (this.state.popupCallback)
                this.state.popupCallback();
              else
                this.setShowPopup(0, null, null)
            }}/>
          ) : null
        }

        {
          this.state.showPop === 2 ? (
            <MsgPop2 msg={this.state.popMsg} okBtn={this.state.popOkBtn} cancelBtn={this.state.popCancelBtn} handleConfirm={this.state.popupCallback}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <div className='wd100p max500 flexcoltc'>
          <TopBar2 withLine={true} title="이용 가이드" handleBack={this.handleBack} />
          
          <div className='mgtop40'></div>

          <div className='wd88p colcc'>
            {guides}
          </div>
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(Guide));

