import React from 'react';
import './Mission.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IsValidS, NumberFormatString } from '../../Util/Util';
import MsgPop from '../Common/MsgPop';


//친구초대 페이지
class MissionInvite extends React.Component
{
  state = {
    loading: false,
    recmCode: '',
    limitDate: '',
    recmEnabled: true,

    showPop: false,
    popupTitle: '',
    popupMsg: '',
    okBtn: '',
  }

  loading = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  setRecmCode = (v) =>
  {
    this.setState((prevState) => ({...prevState, recmCode : v}));
  }

  setLimitDate = (v) =>
  {
    this.setState((prevState) => ({...prevState, limitDate : v}));
  }

  setRecmEnabled = (v) =>
  {
    this.setState((prevState) => ({...prevState, recmEnabled : v}));
  }

  showMsgPopup = (title, msg, okbtn) =>
  {
    this.setState((prevState) => ({...prevState, showPop : true, popupTitle: title, popupMsg: msg, okBtn: okbtn}));
  }

  closeMsgPop = () =>
  {
    this.setState((prevState) => ({...prevState, showPop : false}));
  }

  componentDidMount()
  {
    this.checkKakaoInit();

    if (LoginStoreInstance.recmLimitDateInfoReady)
    {
      this.setRecmInfo();
    }
    else
    {
      this.setLoading(true);
      LoginStoreInstance.checkUserRecmInputDate(this.onRecmLimitDateLoaded);
    }
  }

  onRecmLimitDateLoaded = (resultCode) =>
  {
    this.setLoading(false);

    this.setRecmInfo();
  }

  setRecmInfo = () =>
  {
    const recmLimitDate = LoginStoreInstance.recmInpLimitDate;

    this.setRecmCode('');

    if (LoginStoreInstance.recmInputEnabled)
    {
      this.setRecmEnabled(true);
      this.setLimitDate(`${recmLimitDate.getFullYear()}년 ${recmLimitDate.getMonth() + 1}월 ${recmLimitDate.getDate()}일 ${NumberFormatString(recmLimitDate.getHours())}:${NumberFormatString(recmLimitDate.getMinutes())}까지 입력 가능합니다.`);
    }
    else
    {
      this.setRecmEnabled(false);
    }
  }

  componentWillUnmount()
  {

  }

  checkKakaoInit = () =>
  {
    if (window.Kakao)
    {
      if (!window.Kakao.isInitialized())
      {
        window.Kakao.init('198ca6bd4fa4a7957384e3f059d89e0b');
      }
    }
  }

  kakaoShare = (userInviteCode) => {
    if (!window.Kakao)
    {
      console.log(`kakao sdk not initialized`);
      return;
    }

    window.Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: '머니팡',
        description: '친구가 무료 선물 상자를 보냈어요. 100% 당첨되는 선물상자 지금 바로 개봉해 보세요.',
        imageUrl: 'https://cdn.snsfly.kr/cashp/mpshare.jpg',
        link: {
          mobileWebUrl: `https://play.google.com/store/apps/details?id=com.bravo6.cashpang&referrer=cp=${userInviteCode}`,
          webUrl: `https://play.google.com/store/apps/details?id=com.bravo6.cashpang&referrer=cp=${userInviteCode}`,
        },
      },
      buttons: [
        {
          title: '선물상자 받기',
          link: {
            mobileWebUrl: `https://play.google.com/store/apps/details?id=com.bravo6.cashpang&referrer=cp=${userInviteCode}`,
            webUrl: `https://play.google.com/store/apps/details?id=com.bravo6.cashpang&referrer=cp=${userInviteCode}`,
          },
        },
      ],
      // 카카오톡 미설치 시 카카오톡 설치 경로이동
      installTalk: true,
    })
  }

  handleRecmCode = () =>
  {
    if (this.state.loading)
      return;

    if (!IsValidS(this.state.recmCode))
    {
      this.showMsgPopup("", "추천인 코드를 입력해 주세요.", "확인");
      return;
    }

    //입력한코드가 나의 코드라면
    if (this.state.recmCode === LoginStoreInstance.getInviteCode)
    {
      this.showMsgPopup("", "입력하신 추천인 코드는 존재하지 않거나 더이상 사용이 불가능한 코드입니다.\n다시 확인해 주세요.", "확인");
      return;
    }
  
    this.setLoading(true);
    LoginStoreInstance.enterEvent(0, this.state.recmCode, this.onEnterRecmResult);
  }

  onEnterRecmResult = (resultCode) =>
  {
    this.setLoading(false);

    switch(resultCode)
    {
      case 0:
        this.showMsgPopup("", "추천인 코드 입력이 완료 되었습니다.", "확인");
        LoginStoreInstance.recmInpLimitDate = null;
        LoginStoreInstance.recmInputEnabled = false;
        this.setRecmInfo();
        return;
      default:
        this.showMsgPopup("", "입력하신 추천인 코드는 존재하지 않거나 더이상 사용이 불가능한 코드입니다.\n다시 확인해 주세요.", "확인");
        return;
    }
  }

  render()
  {
    return (
      <div className='mssnsarea2 coltc mgtop15'>
        {
          this.state.showPop ? (
            <MsgPop msg={this.state.popupMsg} okBtn={this.state.okBtn} handleConfirm={() => this.closeMsgPop()}/>
          ) : null
        }

        <div className='msivtimgbg rowcc'>
          <img src={GetImgUrl("invitepg.png")} alt="초대하기" className='msivtimg'/>
        </div>

        <label className='font12 color31 wt400 lht18 mgtop20 textcenter'>초대받은 친구가 가입 후<br/>초대 코드를 입력하면 머니가 지급됩니다.</label>

        <div className='msivtcode rowcc mgtop10'>
          <label className='font14 color31 wt400'>{`나의 초대 코드 ${LoginStoreInstance.getInviteCode}`}</label>
        </div>

        <div className={this.state.recmEnabled ? 'wd88p rowsc mgtop10' : 'wd88p rowsc mgtop10 mgbt100'}>

          <CopyToClipboard text={LoginStoreInstance.getInviteCode} onCopy={() => this.props.handleInvite(0)}>
            <button className='msivtbtn font14 wt400 colorw btncur'>초대코드 복사하기</button>
          </CopyToClipboard>
          <button className='msivtbtn font14 wt400 colorw btncur' onClick={() => this.kakaoShare(LoginStoreInstance.getInviteCode)}>카카오톡 공유하기</button>
        </div>

        {
          this.state.recmEnabled ? (
            <div className='wd88p coltl mgtop30 mgbt100'>
              <label className='font14 color31 wt500'>추천인 코드 입력</label>

              <div className='wd100p rowsc mgtop5'>
                <input type='text' value={this.state.recmCode} onChange={(e) => this.setRecmCode(e.target.value)} className='msinprc font15 wt300 color31' placeholder='추천인 코드를 입력해 주세요.'/>
                <button className='msinprcbtn font15 wt400 colorff btncur' onClick={this.handleRecmCode}>확인</button>
                <label className=''>{}</label>
              </div>

              <label className='font14 colorffc8 wt300 mgtop5'>{this.state.limitDate}</label>
              <label className='font14 colorffc8 wt300 mgtop5'>'확인' 버튼을 클릭해야 보상이 지급됩니다.</label>
            </div>
          ) : null
        }
        
      </div>
    );
  }
}

export default MissionInvite;