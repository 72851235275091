import './Address.css';
import '../../App.css';
import { GetFormattedPhone2, IsValidS } from '../../Util/Util';

const getAddress = (item) =>
{
  if (IsValidS(item.ad2) && item.ad2 !== "n")
    return `${item.ad1} ${item.ad2}`;

  return item.ad1;
}

const AddressItem = (props) =>
{
  return (
    <div className='additem coltl'>

      <div className='rowcc'>
        <label className='font14 wt300 color31'>{props.item.un}</label>
        {
          props.item.df !== 0 ? (<label className='font12 wt400 colorff'>&nbsp;&nbsp;기본배송지</label>) : null
        }
      </div>

      <label className='font14 wt300 color31 mgtop5'>{getAddress(props.item)}</label>
      
      <label className='font14 wt300 color31 mgtop5'>{GetFormattedPhone2(props.item.ph)}</label>

      <div className='wd100p rowrc mgtop10'>
        <button className='addbtn font14 wt400 colorb3 btncur' onClick={() => props.handleAddress(0, props.item)}>수정</button>
      </div>
    </div>
  );
}

export default AddressItem;