import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { FromBase64, IsValidS, IsValidV } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

class SnsStore {
  snsAccountList = null; //사용자가 등록한 sns계정 목록
  
  //비옵저버 변수
  accountReady = false;

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      snsAccountList : observable,
      
      clearAll : action,
      parseSnsAccountListResult : action,
      parseSnsDelResult : action,

      getInstaAccount: computed,
      getFbAccount: computed,
      getYtAccount: computed,
      getTiktokAccount: computed,
      
    });
  }

  //로그아웃시 클리어 해야 하는 변수를 클리어함
  clearAll = () =>
  {
    this.snsAccountList = null;
    this.accountReady = false;
  }

  //인스타 계정
  get getInstaAccount()
  {
    if (!IsValidS(this.snsAccountList))
      return null;

    for (let i = 0;i < this.snsAccountList.length; ++i)
    {
      if (this.snsAccountList[i].pf === 0)
        return this.snsAccountList[i];
    }

    return null;
  }

  //페이스북 계정
  get getFbAccount()
  {
    if (!IsValidS(this.snsAccountList))
      return null;

    for (let i = 0;i < this.snsAccountList.length; ++i)
    {
      if (this.snsAccountList[i].pf === 1)
        return this.snsAccountList[i];
    }

    return null;
  }

  //유튜브 계정
  get getYtAccount()
  {
    if (!IsValidS(this.snsAccountList))
      return null;

    for (let i = 0;i < this.snsAccountList.length; ++i)
    {
      if (this.snsAccountList[i].pf === 2)
        return this.snsAccountList[i];
    }

    return null;
  }

  //틱톡 계정
  get getTiktokAccount()
  {
    if (!IsValidS(this.snsAccountList))
      return null;

    for (let i = 0;i < this.snsAccountList.length; ++i)
    {
      if (this.snsAccountList[i].pf === 3)
        return this.snsAccountList[i];
    }

    return null;
  }

  getSnsAccount = (platform) =>
  {
    switch(platform)
    {
      case 0: //인스타
        return this.getInstaAccount;
      case 1: //페이스북
        return this.getFbAccount;
      case 2: //유튜브
        return this.getYtAccount;
      case 3: //틱톡
        return this.getTiktokAccount;
      default:
        return null;
    }
  }

  isSnsAccountValid = (platform) =>
  {
    switch(platform)
    {
      case 0: //인스타
        return IsValidV(this.getInstaAccount);
      case 1: //페이스북
        return IsValidV(this.getFbAccount);
      case 2: //유튜브
        return IsValidV(this.getYtAccount);
      case 3: //틱톡
        return IsValidV(this.getTiktokAccount);
      default:
        return false;
    }
  }
  //이용자의 sns계정 목록읽기-------------------------------------------------------------------------------------------------------------

  loadSnsAccount = (callback) => {

    this.accountReady = false;
    
    axios({
      method:"POST",
      url: GetApiUrl("social/snslist/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
    }).then((res)=>{

      this.parseSnsAccountListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseSnsAccountListResult(null, callback);
    });
  }

  parseSnsAccountListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);
      return;
    }

    if (LOG_ENABLE)
      console.log(`parseSnsAccountListResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      this.accountReady = true;

      this.snsAccountList = result.list;

      if (IsValidS(this.snsAccountList))
      {
        this.snsAccountList.forEach(item => this.decodeSnsAccount(item));
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }

  decodeSnsAccount = (item) =>
  {
    if (IsValidS(item.un))
      item.un = FromBase64(item.un);

    if (IsValidS(item.nm))
      item.nm = FromBase64(item.nm);
  }
  
  //sns계정 삭제-------------------------------------------------------------------------------------------------------------

  //sidx : 삭제할 sns 고유번호
  delSnsAccount = (sidx, callback) => {

    const params = new URLSearchParams();
    params.append("sidx", sidx.toString());
    
    axios({
      method:"POST",
      url: GetApiUrl("social/snsdel/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseSnsDelResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;
      
      this.parseSnsDelResult(null, callback);
    });
  }

  parseSnsDelResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseSnsDelResult : ${JSON.stringify(result)}`);

    if (result.ret === 0)
    {
      if (IsValidS(this.snsAccountList))
      {
        if (this.snsAccountList.length === 1)
          this.snsAccountList = null;
        else
        {
          this.snsAccountList = this.snsAccountList.filter(item => item.idx !== result.sidx);
        }
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
      callback(result.ret);
  }
}

const SnsStoreInstance = new SnsStore();
const SnsStoreContext = React.createContext(SnsStoreInstance);

const UseSnsStore = () => React.useContext(SnsStoreContext);

export {
  UseSnsStore, 
  SnsStoreInstance,
};
