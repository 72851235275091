import React from 'react';
import './Login.css';
import '../../App.css';
import { GetImgUrl, LOG_ENABLE, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import {  IsValidS, IsValidV, } from '../../Util/Util';
import Loading from '../Loading/Loading';
import { LoginStoreInstance } from '../../Stores/LoginStore';
import MsgPop from '../Common/MsgPop';

import TopBar2 from '../Top/TopBar2';

//이메일로 로그인 페이지
class LoginEmail extends React.Component
{
  state = {
    userId: '',
    userPw: '',
  };

  setUserId = (v) =>
  {
    this.setState((prevState) => ({...prevState, userId : v}));
  }

  setUserPw = (v) =>
  {
    this.setState((prevState) => ({...prevState, userPw : v}));
  }

  componentDidMount() {

  }

  componentWillUnmount = () =>
  {
  }

  handleBack = () =>
  {
    this.props.handleClose();
  }

  handleLogin = () =>
  {
    LoginStoreInstance.setUserId(this.state.userId);
    LoginStoreInstance.setUserPw(this.state.userPw);

    this.props.handleEmailLogin();
  }

  render()
  {
    return (
      <div className='loginemail flexcoltc'>
        <TopBar2 title="" handleBack={this.handleBack} withLine={true}/>

        <div className='loginin flexcoltl'>
          <img src={GetImgUrl("logo.png")} className='lgnlogo' alt="로고"/>

          <input type='text' className='lgeinp font14 weight300 color31 margintop140' value={this.state.userId} onChange={e => this.setUserId(e.target.value)} maxLength={45} placeholder='이메일을 입력해 주세요.'/>
          <input type='password' className='lgeinp font14 weight300 color31 margintop10' value={this.state.userPw} onChange={e => this.setUserPw(e.target.value)} maxLength={45} placeholder='비밀번호를 입력해 주세요.'/>

          <button className='lgebtn1 font14 weight400 colorw btncur' onClick={this.handleLogin}>머니팡 시작하기</button>

          <div className='fullwidth flexrowcc margintop20'>
            <label className='font12 color31 weight400 btncur' onClick={this.props.handleFindId}>이메일 찾기</label>

            <div className='lgelinev'></div>

            <label className='font12 color31 weight400 btncur' onClick={this.props.handleFindPw}>비밀번호 찾기</label>
          </div>
        </div>
        
      </div>
    );
  }
}

export default LoginEmail;

