import React from 'react';
import './Mission.css';
import '../../App.css';
import { GetImgUrl } from '../../URL/AppUrl';


//겟캐시관련 페이지
class MissionGetCash extends React.Component
{
  state = {
    loading: false,
  }

  loading = false;

  setLoading = (v) =>
  {
    this.loading = v;
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  componentDidMount()
  {

  }

  componentWillUnmount()
  {

  }

  render()
  {
    return (
      <div className='mssnsarea coltc mgtop15'>
        <label className='font18 wt400 color31 lht26 textcenter'>다양한 미션을 통해<br/>머니를 지급하는 적립소</label>
        <label className='font16 wt400 colorb3 mgtop5'>원하는 미션을 통해 머니를 적립 받아보세요!</label>

        <div className='msgcbtn rowcc mgtop10'>
          <img src={GetImgUrl("msgc1.png")} alt="적립소" className='img1515 mgrt5'/>
          <label className='font15 wt400 colorff'>참여 적립</label>
        </div>

        <div className='msgcbtn rowcc mgtop5'>
          <img src={GetImgUrl("msgc2.png")} alt="적립소" className='img1515 mgrt5'/>
          <label className='font15 wt400 colorff'>쇼핑 적립</label>
        </div>

        <div className='msgcbtn rowcc mgtop5'>
          <img src={GetImgUrl("msgc3.png")} alt="적립소" className='img1515 mgrt5'/>
          <label className='font15 wt400 colorff'>구독하기</label>
        </div>

        <div className='msgcbtn rowcc mgtop5'>
          <img src={GetImgUrl("msgc4.png")} alt="적립소" className='img1515 mgrt5'/>
          <label className='font15 wt400 colorff'>이벤트 참여</label>
        </div>

        <div className='msgcbtn rowcc mgtop5'>
          <img src={GetImgUrl("msgc5.png")} alt="적립소" className='img1515 mgrt5'/>
          <label className='font15 wt400 colorff'>가입하기</label>
        </div>

        <div className='msgcbtn rowcc mgtop5 mgbt100'>
          <img src={GetImgUrl("msgc6.png")} alt="적립소" className='img1515 mgrt5'/>
          <label className='font15 wt400 colorff'>앱 내려받기</label>
        </div>

        <div className='msgcbtn2 rowcc btncur' onClick={this.props.handleGetCash}>
          <label className='font18 wt400 colorw mgrt10 btncur'>머니 더 벌기</label>
          <img src={GetImgUrl("arrowr3@3x.png")} alt="적립소" className='img1717'/>
        </div>
      </div>
    );
  }
}

export default MissionGetCash;