import './PangBox.css';
import '../../App.css';
import { GetImgUrl, getProductImageUrl } from '../../URL/AppUrl';
import { GetFormattedNum, IsValidS } from '../../Util/Util';
import { useState } from 'react';

const getRankImage = (item) =>
{
  switch(item.lv)
  {
    case 0: //브론즈
      return GetImgUrl("pbronze.png");
    case 1: //실버
      return GetImgUrl("psilver.png");
    case 2: //골드
      return GetImgUrl("pgold.png");
    case 3: //루비
      return GetImgUrl("pruby.png");
    case 4: //다이아
      return GetImgUrl("pdia.png");
    default:
      return GetImgUrl("pbronze.png");
  }
}

const PangBoxInfo = (props) =>
{
  const [expand1, setExpand1] = useState(true);
  const [expand2, setExpand2] = useState(false);
  const [expand3, setExpand3] = useState(false);

  return (
    <div className='pbdetpopbg colec'>
      <div className='pbdetpop coltc'>
        <div className='pbdetimgbg rowcc'>
          <img src={getProductImageUrl(props.item.img)} className='pbdetimg'/>
        </div>

        <div className='rowcc mgtop10'>
          <img src={getRankImage(props.detailInfo)} className='img1215 mgrt5'/>
          <label className='font15 wt400 color31'>{props.item.nm}</label>
        </div>

        <label className='font15 wt300 color31 mgtop5'>정가 {GetFormattedNum(props.item.prc)}원</label>

        {
          IsValidS(props.item.lk) && props.item.lk !== "n" ? (
            <div className='pbdetinfobox mgtop10 rowcc'>
              <label className='wd95p font14 wt400 colorff mgtop5 mgbt5'>{props.item.lk}</label>
            </div>
          ) : null
        }
        
        <div className='wd88p rowlc btncur mgtop20' onClick={() => setExpand1(!expand1)}>
          <label className='font14 wt500 color31 btncur mgrt5'>상품 정보 제공 고시</label>
          <img src={GetImgUrl(!expand1 ? "da@3x.png" : "uparr@3x.png")} alt="확장" className='img105'/>
        </div>

        {
          expand1 && props.detailInfo && IsValidS(props.detailInfo.ic2) && props.detailInfo.ic2 !== "n" ? (
            <div className='wd88p rowcc mgtop10'>
              <img src={getProductImageUrl(props.detailInfo.ic2)} alt="상세설명" className='pbdetimg2'/>
            </div>
          ) : null
        }

        <div className='wd88p rowlc btncur mgtop20' onClick={() => setExpand2(!expand2)}>
          <label className='font14 wt500 color31 btncur mgrt5'>상품 안내</label>
          <img src={GetImgUrl(!expand2 ? "da@3x.png" : "uparr@3x.png")} alt="확장" className='img105'/>
        </div>

        {
          expand2 ? (
            <div className='pbdetdcar rowcc mgtop10'>
              <label className='font14 wt300 color31 lineheight20'>- 팡팡박스를 구매하여 무작위로 상품을 획득할 수있습니다.<br/>- 배송신청 완료 후 영업일 기준 1~3일 이내 발송이 됩니다. (주말, 공휴일 제외)<br/>- 기프티콘 유효기간은 30일로 유효기간 연장이 불가능하며, 유효기간 당일까지 사용하지 않을 경우 만료되어 사용할 수 없습니다.<br/>- 배송 준비 중 상태부터 출고 요청이 완료되어 주문 취소가 불가능합니다.<br/>- 상품 종류에 따라 배송이 다소 지연될 수 있습니다.</label>
            </div>
          ) : null
        }

        <div className={expand3 ? 'wd88p rowlc btncur mgtop20' : 'wd88p rowlc btncur mgtop20 mgbt100'} onClick={() => setExpand3(!expand3)}>
          <label className='font14 wt500 color31 btncur mgrt5'>교환 안내</label>
          <img src={GetImgUrl(!expand3 ? "da@3x.png" : "uparr@3x.png")} alt="확장" className='img105'/>
        </div>

        {
          expand3 ? (
            <div className='pbdetdcar rowcc mgtop10 mgbt100'>
              <label className='font14 wt300 color31 lineheight20'>- 기프티콘은 수령 완료 후 취소가 불가능합니다.<br/>- 배송받은 상품에 하자가 있을 경우에만 교환이 가능합니다 (단, 상품과 해당 택배상자,택 등 그대로 회수가 된 상태여야만 교환이 가능합니다.)<br/>- 구매자의 부주의로 상품이 훼손 또는 파손된 경우 교환이 불가능합니다.<br/>- 교환 요청 시 상품의 상태가 다르거나 사용감이 있을 경우 교환이 거부될 수 있으며, 이때 발생하는 추가 비용(왕복 배송비)은 고객님 부담이오니 주의해 주세요.</label>
            </div>
          ) : null
        }

        <div className='pbdetbtnarea rowcc'>
          <button className='pbdetbtn1 font14 wt400 color31 btncur' onClick={props.handleClose}>닫기</button>
          <button className='pbdetbtn2 font14 wt400 colorw btncur' onClick={props.handleBuyBox}>팡팡박스 구매하기</button>
        </div>
      </div>
    </div>
  );

}

export default PangBoxInfo;