import React from 'react';
import './Setting.css';
import '../../App.css';
import { LOG_ENABLE, getPrivacyUrl, getTermUrl, handleAppNavi, withNavigation } from '../../URL/AppUrl';
import Loading from '../Loading/Loading';

import { LoginStoreInstance } from '../../Stores/LoginStore';
import TopBar2 from '../Top/TopBar2';
import { AlarmStoreInstance } from '../../Stores/AlarmStore';
import { observer } from 'mobx-react';
import { NaviStoreInstance, UI_HOME, UI_LOGIN, UI_MISSION, UI_MYPANG, UI_PANGBOX } from '../../Stores/NaviStore';
import SettingItem from './SettingItem';
import Term from '../Term/Term';
import Privacy from '../Term/Privacy';

//설정창
class Setting extends React.Component
{
  state = {
    loading: false,
    showTerm: false,
    showPrivacy: false,
  };
  intervalId = -1;
  
  setLoading = (v) =>
  {
    this.setState((prevState) => ({...prevState, loading : v}));
  }

  showTerm = (v) =>
  {
    this.setState((prevState) => ({...prevState, showTerm: v}));
  }

  showPrivacy = (v) =>
  {
    this.setState((prevState) => ({...prevState, showPrivacy: v}));
  }

  handleNavi = (naviIndex) =>
  {
    if (LOG_ENABLE)
      console.log(`handleNavi : ${naviIndex}`);

    handleAppNavi(this.props.navigate, naviIndex);
  }

  handleBack = () =>
  {
    if (this.state.loading)
      return;

    switch(NaviStoreInstance.uiFrom)
    {
      case UI_MYPANG:
        this.handleNavi(UI_MYPANG);
        return;
      case UI_MISSION:
        this.handleNavi(UI_MISSION);
        return;
      case UI_PANGBOX:
        this.handleNavi(UI_PANGBOX);
        return;
      case UI_HOME:
      default:
        this.handleNavi(UI_HOME);
        return;
    }
  }

  processLogOut = () =>
  {
    LoginStoreInstance.processLogOut();
    this.handleNavi(UI_LOGIN);
  }

  update = () =>
  {
    if (LoginStoreInstance.sessionError && LoginStoreInstance.loggedIn)
    {
      LoginStoreInstance.sessionError = false;
      alert(`인증 세션이 만료되었습니다.`);
      this.processLogOut();
    }
  }

  componentDidMount() {
    this.intervalId = setInterval(this.update, 1000);

    if (!LoginStoreInstance.isProfileReady) //프로필이 준비되지 않았다면
    {
      if (LOG_ENABLE)
        console.log(`load profile`);
      
      this.setLoading(true);
      LoginStoreInstance.loadProfile(this.onProfileLoaded);
    }
  }

  componentWillUnmount = () =>
  {
    if (this.intervalId !== -1)
    {
      clearInterval(this.intervalId);
      this.intervalId = -1;
    }
  }

  onProfileLoaded = (resultCode) =>
  {
    this.setLoading(false);

    if (resultCode !== 0)
    {
      alert(`프로필 읽기 중 오류가 발생했습니다.\n다시 로그인해 주세요.`);
      this.processLogOut();
      return;
    }

  }

  handleMenu = (menuIndex) =>
  {
    if (this.state.loading)
      return;

    switch(menuIndex)
    {
      case 0:
        //this.showTerm(true);
        window.open(getTermUrl(), "_blank");
        return;
      case 1:
        //this.showPrivacy(true);
        window.open(getPrivacyUrl(), "_blank");
        return;
      default:
        return;
    }
  }

  render()
  {
    
    return (
      <div className='alarmbg flexcoltc'>
        {
          this.state.showTerm ? (
            <Term handleClose={() => this.showTerm(false)}/>
          ) : null
        }

        {
          this.state.showPrivacy ? (
            <Privacy handleClose={() => this.showPrivacy(false)}/>
          ) : null
        }
        {
          this.state.loading ? (<Loading/>): null
        }
        <TopBar2 withLine={true} title="설정" handleBack={this.handleBack} />

        <div className='wd100p max500 flexcoltc mgtop50'>
          <SettingItem title="이용약관" handleMenu={() => this.handleMenu(0)}/>
          <SettingItem title="개인정보 처리방침" handleMenu={() => this.handleMenu(1)}/>  
        </div>
      </div>
    );
  }
}

export default observer(withNavigation(Setting));

