import {
  action,
  makeObservable,
  /*makeAutoObservable,*/ observable,
  computed,
} from 'mobx';
import React from 'react';
import axios from 'axios';
import { FromBase64, IsValidS, cancelAxiosRequest } from '../Util/Util';
import { LoginStoreInstance } from './LoginStore';
import { GetApiUrl, LOG_ENABLE } from '../URL/AppUrl';

class GuideStore {
  guideList = null; //서버에서 조회된 가이드 목록

  //비옵저버
  refIdx = 0;

  guideReady = false;

  constructor() {
    //makeAutoObservable(this, {
    makeObservable(this, {
      guideList : observable,
      
      getItemCount: computed,

      clearAll: action,
      parseGuideListResult: action,
    });
  }

  clearAll = () =>
  {
    this.guideList = null; //서버에서 조회된 가이드 목록
    this.refIdx = 0;
    this.guideReady = false;
  }

  get getItemCount()
  {
    if (!IsValidS(this.guideList))
      return 0;

    return this.guideList.length;
  }
  //이용가이드 목록읽기-------------------------------------------------------------------------------------------------------------

  loadGuideList = (callback) => {
    this.guideReady = false;

    const params = new URLSearchParams();
    params.append("rt", "0");
    params.append("pos", this.refIdx.toString());

    axios({
      method:"POST",
      url: GetApiUrl("faq/guide/"),
      headers: {
        //Accept: 'application/json',
        Accept: 'application/text',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      responseType: 'json', // 기본 값
      responseEncoding: 'utf8', // 기본 값
      data: params,
    }).then((res)=>{

      this.parseGuideListResult(res.data, callback);
    }).catch(error=>{
      console.log(error);
      
      if (axios.isCancel(error))
        return;

      this.parseGuideListResult(null, callback);
    });
  }

  parseGuideListResult = (result, callback) =>
  {
    if (!result)
    {
      if (callback)
        callback(-1, 0);

      return;
    }

    if (LOG_ENABLE)
      console.log(`parseGuideListResult : ${JSON.stringify(result)}`);

    let readCount = 0;

    if (result.ret === 0)
    {
      this.guideReady = true;

      if (IsValidS(result.list))
      {
        readCount = result.list.length;

        result.list.forEach(item => {
          this.decodeGuide(item);

          if (this.refIdx === 0 || this.refIdx < item.idx)
            this.refIdx = item.idx;
        });

        if (IsValidS(this.guideList))
        {
          this.guideList = [...this.guideList, ...result.list];
        }
        else
          this.guideList = result.list;
      }
    }
    else if (result.ret === 100)
    {
      LoginStoreInstance.sessionError = true;
    }

    if (callback)
    {
      callback(result.ret, readCount);
    }
  }

  decodeGuide = (item) =>
  {
    if (IsValidS(item.tt))
      item.tt = FromBase64(item.tt);

    if (IsValidS(item.img) && item.img !== "n")
    {
      item.imgList = JSON.parse(FromBase64(item.img));

      if (LOG_ENABLE)
        console.log(`guide images : ${JSON.stringify(item.imgList)}, imgtype=${typeof item.imgList}, ${typeof item}`);
    }
  }
}

const GuideStoreInstance = new GuideStore();
const GuideStoreContext = React.createContext(GuideStoreInstance);

const UseGuideStore = () => React.useContext(GuideStoreContext);

export {UseGuideStore, GuideStoreInstance};
